import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import { BannerInformationModel } from '@shared/models';
import { Subject } from 'rxjs';
import { FinancialFileTypes } from '../../../shared/enums';
import { getContainerFinancialFileTypes } from '../../../shared/utils/utils';

@Component({
  selector: 'app-inconsistencies-banner',
  templateUrl: './incosistencies-banner.component.html',
  styleUrls: ['./incosistencies-banner.component.scss']
})
export class InconsistenciesBannerComponent implements OnChanges, OnDestroy, AfterContentChecked {
  @Input() dateRef = new Date();

  @Input() validationFileId = '';

  @Input() fileType = FinancialFileTypes.Unknown;

  @Input() checkFilter = false;

  @Input() bannerInformation?: BannerInformationModel;

  @Output() checkFilterChange = new EventEmitter<boolean>();

  private readonly _destroying$ = new Subject<void>();

  public FinancialFileTypes = FinancialFileTypes;

  public fileTypeName = '';

  public quantInconsistencies = 0;

  public resumeInconsistencies = false;

  public toggleInconsistencies(): void {
    this.resumeInconsistencies = !this.resumeInconsistencies;
  }

  public countInconsistencies(): void {
    this.quantInconsistencies = 0;
    if (this?.bannerInformation) {
      Object.values(this.bannerInformation).forEach(value => {
        this.quantInconsistencies += value;
      });
    }
  }

  ngAfterContentChecked(): void {
    this.countInconsistencies();
  }

  ngOnChanges(): void {
    this.fileTypeName = getContainerFinancialFileTypes(this.fileType);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
