import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { DmiService } from '@core/services/dmi/dmi.service';
import { FileTypeService } from '@core/services/fileTypes/fileTypes.service';
import { FinancialFileService } from '@core/services/financial-file/financial-file.service';
import { MessageService } from '@core/services/message/message.service';
import { SquadService } from '@core/services/squad/squad.service';
import { TranslateService } from '@ngx-translate/core';
import { FinancialFileTypes } from '@shared/enums';
import { DmiErrorModel, FileExceptionModel, FileTypeModel, SquadModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { compareAsc } from 'date-fns';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../core/services/modal/modal.service';
import { ColumnLines } from '../../shared/models/file-exception/file-exception.model';

@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.component.html',
  styleUrls: ['./modal-upload-file.component.scss']
})
export class ModalUploadFileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isVisible = false;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _destroying$ = new Subject<void>();

  public FinancialFileTypes = FinancialFileTypes;

  public validationForm!: FormGroup;

  public isSpinning = false;

  public showSuccessMessage = false;

  public showErrorUploadMessage = false;

  public squads!: SquadModel[];

  public fileTypes!: FileTypeModel[];

  public fileList: NzUploadFile[] = [];

  public dmiError: DmiErrorModel = {};

  public plannedOrExecutedError: FileExceptionModel = {};

  public inconsistencyId = '';

  private confirmModal?: NzModalRef;

  private validateSquad = true;

  constructor(
    public translate: TranslateService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private squadService: SquadService,
    private fileTypeService: FileTypeService,
    public dmiService: DmiService,
    private financialFileService: FinancialFileService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private message: MessageService
  ) {
    this.validationForm = this.formBuilder.group({
      referenceDate: [null, [Validators.required]],
      fileType: [null, [Validators.required]],
      squad: [null],
      fileData: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getSquads();
    this.getFileTypes();
  }

  getSquads(): void {
    this.isSpinning = true;
    this.squadService
      .get()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: squads => {
          this.squads = squads;
          this.isSpinning = false;
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  getFileTypes(): void {
    this.isSpinning = true;

    this.fileTypeService
      .get()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: fileTypes => {
          this.fileTypes = fileTypes;
          this.isSpinning = false;
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  private handleOk(): void {
    this.validateSquad = false;
    this.isSpinning = true;
    this.uploadCommomFile();
    this.confirmModal?.destroy();
  }

  private handleCancel(): void {
    this.validateSquad = true;
    this.confirmModal?.destroy();
  }

  private showErrorMessages(detail: string): void {
    if (detail?.includes('products were not belong to the selected squad')) {
      const title = this.translate.instant('Attention');

      const content = this.translate.instant('File_incompatible_with_squad');

      this.confirmModal = this.modalService.confirm({
        title: title,
        content: content,
        iconType: 'exclamation-circle',
        className: 'confirm-modal',
        onOk: this.handleOk.bind(this),
        onCancel: this.handleCancel.bind(this)
      });
    } else if (detail?.includes('Missing columns')) {
      const [messageString, arrayString] = detail.split(': ');
      const columns = arrayString.split(', ');

      const fileErrorObj = {
        requiredColumnsMessage: messageString,
        requiredColumns: columns
      };

      this.plannedOrExecutedError = fileErrorObj;
    } else if (detail?.includes('Duplicated columns')) {
      const [messageString, arrayString] = detail.split(': ');
      const columns = arrayString.split(', ');

      const fileErrorObj = {
        requiredColumnsMessage: messageString,
        requiredColumns: columns
      };

      this.plannedOrExecutedError = fileErrorObj;
    } else if (detail?.includes('Duplicated lines in column')) {
      const columnLines = detail.split('Duplicated lines in column ');

      columnLines.shift();

      const errorArray = columnLines.map((columnLine: string) => {
        const [column, lines] = columnLine.split(' - Lines: ');
        return {
          column,
          lines: lines.replace(/\r\n/gim, '').split(', ')
        } as ColumnLines;
      });

      const fileErrorObj: FileExceptionModel = {
        duplicatedLinesMessage: 'Duplicated lines in column',
        duplicatedLines: errorArray
      };

      this.plannedOrExecutedError = fileErrorObj;
    } else if (
      detail?.includes('Missing cells in column') ||
      detail?.includes('Invalid cells in column')
    ) {
      const invalidKeyword = 'Invalid cells in column ';
      const requiredKeyword = 'Missing cells in column ';
      const lineSeparator = '\r\n\r\n';

      let invalidLines = detail?.split(invalidKeyword);
      let requiredLines = detail?.split(requiredKeyword);

      const [invalidCellsMessage, requiredCellsMessage] = detail.split(lineSeparator);

      if (invalidCellsMessage && requiredCellsMessage) {
        invalidLines = invalidCellsMessage.split(invalidKeyword);
        requiredLines = requiredCellsMessage.split(requiredKeyword);
      }

      invalidLines?.shift();
      requiredLines?.shift();

      const parseColumnLines = (
        columnLine: string
      ): {
        column: string;
        lines: string[];
      } => {
        const [column, lines] = columnLine.split(' - Lines: ');
        return {
          column,
          lines: lines.replace(/\r\n/gim, '').split(', ')
        };
      };

      const invalidCells = invalidLines?.map(parseColumnLines);
      const requiredCells = requiredLines?.map(parseColumnLines);

      const fileErrorObj: FileExceptionModel = {};

      if (requiredCells?.length) {
        fileErrorObj.requiredCellsMessage = requiredKeyword.trim();
        fileErrorObj.requiredCells = requiredCells;
      }

      if (invalidCells?.length) {
        fileErrorObj.invalidCellsMessage = invalidKeyword.trim();
        fileErrorObj.invalidCells = invalidCells;
      }

      this.plannedOrExecutedError = fileErrorObj;
    } else {
      this.messageService.showError(detail);
    }
  }

  private uploadCommomFile(): void {
    this.financialFileService
      .upload(
        this.fileList[0],
        this.validationForm.value.fileType,
        this.validationForm.value.squad,
        this.validationForm.value.referenceDate,
        this.validateSquad
      )
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: result => {
          this.isSpinning = false;
          this.showSuccessMessage = true;
          if (!result.status && result.data?.description) {
            this.inconsistencyId = result.data.description;
          }
          this.financialFileService.updateFilesSubject.next();
        },
        error: resultError => {
          this.isSpinning = false;

          if (
            resultError?.error?.detail?.includes(
              'products were not belong to the selected squad'
            ) === false
          ) {
            this.showErrorUploadMessage = true;
          }

          if (typeof resultError === 'object' && resultError?.error?.detail) {
            this.showErrorMessages(resultError.error.detail);
          }
        }
      });
  }

  private uploadDmi(): void {
    this.dmiService
      .upload(this.fileList[0], this.validationForm.value.referenceDate)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.isSpinning = false;
          this.showSuccessMessage = true;
          this.financialFileService.updateFilesSubject.next();
        },
        error: resultError => {
          this.dmiError = resultError?.error?.data;

          this.isSpinning = false;
          this.showErrorUploadMessage = true;

          if (typeof resultError === 'object' && resultError?.error?.detail) {
            this.showErrorMessages(resultError.error.detail);
          }
        }
      });
  }

  public onUploadData(): void {
    this.inconsistencyId = '';
    this.plannedOrExecutedError = {};
    this.dmiError = {};
    this.showSuccessMessage = false;
    this.showErrorUploadMessage = false;
    this.isSpinning = true;

    switch (this.validationForm.value.fileType) {
      case FinancialFileTypes.Planned:
        this.validateSquad = true;
        this.uploadCommomFile();
        break;
      case FinancialFileTypes.Executed:
        this.validateSquad = true;
        this.uploadCommomFile();
        break;
      case FinancialFileTypes.MediaHub:
        this.validateSquad = false;
        this.uploadCommomFile();
        break;
      case FinancialFileTypes.Dmi:
        this.uploadDmi();
        break;
    }
  }

  public onFileTypeChange = (value: number): void => {
    if (value === FinancialFileTypes.Planned || value === FinancialFileTypes.Executed) {
      this.validationForm.controls['squad'].setValidators([Validators.required]);
      this.validationForm.controls['squad'].updateValueAndValidity();
    } else if (value === FinancialFileTypes.Dmi || value === FinancialFileTypes.MediaHub) {
      this.validationForm.controls['squad'].clearValidators();
      this.validationForm.controls['squad'].updateValueAndValidity();
      this.validationForm.controls['squad'].reset();
    }
  };

  public onFileBeforeUpload = (file: NzUploadFile): boolean => {
    if (file?.type && CONSTANTS.ALLOWED_FILE_TYPES.includes(file.type)) {
      this.fileList = [file];
      this.validationForm.controls['fileData'].setValue(file);
      this.validationForm.controls['fileData'].updateValueAndValidity();
      return false;
    }

    this.messageService.showError('Invalid_file_type');

    this.fileList = [];
    this.validationForm.controls['fileData'].setValue(null);
    this.validationForm.controls['fileData'].updateValueAndValidity();
    return false;
  };

  public onFileRemove = (): boolean => {
    this.fileList = [];
    this.validationForm.controls['fileData'].setValue(null);
    this.validationForm.controls['fileData'].updateValueAndValidity();
    return false;
  };

  public onGoBack(): void {
    this.fileList = [];
    this.validationForm.controls['fileData'].setValue(null);
    this.validationForm.controls['fileData'].updateValueAndValidity();
    this.showSuccessMessage = false;
    this.showErrorUploadMessage = false;
  }

  public onClose(): void {
    this.isVisible = false;
    this.isSpinning = false;
    this.showSuccessMessage = false;
    this.showErrorUploadMessage = false;
    this.validateSquad = true;
    this.fileList = [];
    this.validationForm.reset();
    this.isVisibleModalChange.emit(this.isVisible);
  }

  public disabledDate = (current: Date): boolean => {
    const currentUTC = new Date(Date.UTC(current.getFullYear(), current.getMonth(), 1));
    const nowUTC = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1));

    return compareAsc(currentUTC, nowUTC) > 0;
  };

  private setupEventListeners(): void {
    this.route.queryParams.pipe(takeUntil(this._destroying$)).subscribe((params: Params) => {
      const defaultRefDate = new Date().toISOString();

      const refDate = params['ref_date'] || defaultRefDate;
      const newDate = new Date(refDate);
      const newDateUTC = new Date(
        newDate.getUTCFullYear(),
        newDate.getUTCMonth(),
        newDate.getUTCDate()
      );

      this.validationForm.controls['referenceDate'].setValue(newDateUTC);
    });
  }

  ngOnChanges(): void {
    this.setupEventListeners();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
