import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommitmentService } from '@core/services/commitment/commitment.service';
import { MessageService } from '@core/services/message/message.service';
import { CommitmentCompleteModel } from '@shared/models';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { CommitmentComponentService } from '../commitment-component.service';

@Component({
  selector: 'app-commitment-grid',
  templateUrl: './commitment-grid.component.html',
  styleUrls: ['./commitment-grid.component.scss']
})
export class CommitmentGridComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  commitment: CommitmentCompleteModel = {} as CommitmentCompleteModel;

  commitments: CommitmentCompleteModel[] = [];

  isLoadingDelete = false;

  isVisibleEditModal = false;

  constructor(
    private commitmentService: CommitmentService,
    private commitmentComponentService: CommitmentComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTableSubscribe();
  }

  getData(): void {
    this.commitmentComponentService.isTableLoading.next(true);
    this.commitmentService
      .getCommitments('CreatedAt', true, 5)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (Commitments: CommitmentCompleteModel[]) => {
          this.commitments = Commitments;
          this.commitmentComponentService.updateNetworks.next(true);
          this.commitmentComponentService.isTableLoading.next(false);
        },
        error: error => {
          this.commitmentComponentService.isTableLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTableSubscribe(): void {
    this.commitmentComponentService.updateCommitmentsGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(commitmentId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.commitmentService
        .deleteCommitment(commitmentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Commitment deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(commitment: CommitmentCompleteModel): void {
    this.commitment = commitment;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(commitment.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  handleEdit(commitment: CommitmentCompleteModel): void {
    this.commitment = commitment;
    this.isVisibleEditModal = true;
  }

  handleCancel(): void {
    this.isVisibleEditModal = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
