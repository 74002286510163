import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { ServicesModule } from '@core/services/services.module';
import { SharedModule } from '@shared/shared.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CommonFileTabComponent } from './common-file-tab/common-file-tab.component';
import { ConsolidatedTabComponent } from './consolidated-tab/consolidated-tab.component';
import { FilesComponentService } from './files-component.service';
import { FilesComponent } from './files.component';
import { DmiFileTabComponent } from './dmi-file-tab/dmi-file-tab.component';

@NgModule({
  declarations: [
    FilesComponent,
    ConsolidatedTabComponent,
    CommonFileTabComponent,
    DmiFileTabComponent
  ],
  exports: [FilesComponent],
  providers: [FilesComponentService],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    FormsModule,
    AppRoutingModule,
    NzSpinModule,
    NzModalModule,
    NzTableModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzEmptyModule,
    NzIconModule,
    NzTabsModule,
    ServicesModule
  ]
})
export class FilesModule {}
