import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CommitmentComponentService } from './commitment-component.service';

@Component({
  selector: 'app-commitment',
  templateUrl: './commitment.component.html',
  styleUrls: ['./commitment.component.scss']
})
export class CommitmentComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  isSpinningForm!: boolean;

  isSpinningTable!: boolean;

  constructor(
    private commitmentComponentService: CommitmentComponentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.commitmentComponentService.isFormLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((formIsLoading: boolean) => {
        this.isSpinningForm = formIsLoading;
      });

    this.commitmentComponentService.isTableLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((tableIsLoading: boolean) => {
        this.isSpinningForm = tableIsLoading;
      });
  }

  navigate(): void {
    this.router.navigateByUrl('registrations/list?category=commitment');
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
