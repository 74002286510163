import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { MessageService } from '@core/services/message/message.service';
import { userGroups } from '@env';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../pages/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class AnalystGuard implements CanActivate {
  constructor(
    private userInfoService: UserInfoService,
    private message: MessageService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userGroup = this.userInfoService.getUserGroup();
    if (userGroup === userGroups.analyst || userGroup === userGroups.admin) {
      return true;
    }
    this.message.showErrorByStatus(403);
    this.router.navigate(['/']);
    return false;
  }
}
