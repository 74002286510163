import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { FromToRules } from '@models/fromToRules';
import { ApprovedRuleModel } from '@models/fromToRules/fromToRules.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovedRulesService {
  constructor(private http: HttpClient) {}

  public get(
    listOfFilter: number[],
    orderByDate: boolean,
    page: number,
    pageSize: number
  ): Observable<FromToRules> {
    const filter = {
      inconsistencyGroups: listOfFilter,
      direction: orderByDate,
      page: page,
      pageSize: pageSize
    };

    return this.http.get<FromToRules>(`${baseWebApiURL}/v1/ApprovedRules`, {
      params: filter
    });
  }

  public addRule(ruleToAdd: ApprovedRuleModel): Observable<ApprovedRuleModel> {
    return this.http.post<ApprovedRuleModel>(`${baseWebApiURL}/v1/ApprovedRules`, ruleToAdd);
  }

  public removeRules(ruleIds: string[]): Observable<any> {
    return this.http.delete(`${baseWebApiURL}/v1/ApprovedRules`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ruleIds)
    });
  }
}
