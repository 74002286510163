<form nz-form [formGroup]="validationForm" class="formCommitment">
  <nz-row [nzGutter]="24">
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Media' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
          <nz-select nzShowSearch nzAllowClear formControlName="commitmentMedia" required>
            <nz-option *ngFor="let media of medias" [nzValue]="media.id" [nzLabel]="media.mediaName">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Network' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
          <nz-select nzShowSearch nzAllowClear formControlName="commitmentNetwork" required>
            <nz-option *ngFor="let network of networks" [nzValue]="network.id" [nzLabel]="network.networkName">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Commitment_Value' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
          <nz-input-group [nzPrefix]="'R$' | translate">
            <input nz-input required currencyMask type="text" maxlength="20" formControlName="commitmentValue"
              [options]="{ prefix: '', thousands: '.', decimal: ',', align: 'left' }" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Year' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
          <nz-input-group>
            <input nz-input required type="number" formControlName="commitmentYear" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <div class="d-flex justify-content-end my-2">
    <app-button class="me-2" type="link" (click)="cleanForm()">
      {{ 'Clean_form' | translate }}
    </app-button>
    <app-button type="primary" [disabled]="!validationForm?.valid" (click)="handleSave()">
      {{ 'Save' | translate }}
    </app-button>
  </div>
</form>