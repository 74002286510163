import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ordination, RegistrationCategory } from '@shared/enums';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public OrdinationEnum = Ordination;

  public RegistrationCategoryEnum = RegistrationCategory;

  public ordination: Ordination = Ordination.Last;

  public categorySelected = null;

  public search = '';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const categoryParam = params['category'];

      this.categorySelected = categoryParam;
    });
  }

  categoryChange(): void {
    this.search = '';
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
