import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileExceptionModel } from '../../../shared/models';

@Component({
  selector: 'app-message-file-error',
  templateUrl: './message-file-error.component.html',
  styleUrls: ['./message-file-error.component.scss']
})
export class MessageFileErrorComponent {
  @Input() fileError: FileExceptionModel = {};

  @Input() isShowMessage = false;

  @Output() isShowMessageEmitter = new EventEmitter<boolean>();

  public objectKeys = Object.keys;
}
