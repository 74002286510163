import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { userGroups } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '@pages/user-info.service';
import { ConsolidatedStatus } from '@shared/enums';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ComparativeService } from '../../core/services/comparative/comparative.service';
import { ConsolidatedService } from '../../core/services/consolidated/consolidated.service';
import { ModalService } from '../../core/services/modal/modal.service';
import {
  ComparativeCampaign,
  ComparativeCampaignResponse,
  ComparativePackage,
  ComparativePackageResponse
} from '../../shared/models/comparative';
import { ConsolidatesSquadsResponse } from '../../shared/models/consolidated';

const PAGE_SIZE = 10;
@Component({
  selector: 'app-comparative',
  templateUrl: './comparative.component.html',
  styleUrls: ['./comparative.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComparativeComponent implements OnInit, OnDestroy {
  public financialFileId = '';

  public generalFinancialFileId = '';

  public lastDmiImportDate: Date | null = null;

  public consolidatedSquad = {} as ConsolidatesSquadsResponse;

  public consolidatesSquads: ConsolidatesSquadsResponse[] = [];

  public comparativeCampaign: ComparativeCampaign[] = [];

  public comparativePackage: ComparativePackage[] = [];

  public ConsolidatedStatus = ConsolidatedStatus;

  public currentPageIndex = 1;

  public pageSize = PAGE_SIZE;

  public totalRecords = 0;

  public dateRef = new Date();

  public dateRefText = '';

  public isSpinning = false;

  public isLoadingConsolidatesSquads = false;

  public isLoadingComparativeCampaign = false;

  public expandSet = new Set<string>();

  public userGroup = '';

  public userGroups = userGroups;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public consolidatedService: ConsolidatedService,
    public comparativeService: ComparativeService,
    public userInfoService: UserInfoService,
    public message: MessageService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private getConsolidatesSquads(): void {
    this.isLoadingConsolidatesSquads = true;
    this.consolidatedService
      .getConsolidatesSquads(this.dateRef)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response: ConsolidatesSquadsResponse[]) => {
          this.isLoadingConsolidatesSquads = false;
          this.consolidatesSquads = response;
          const generalConsolidated = this.consolidatesSquads.find(
            squad => squad.squadName === 'Geral'
          );
          if (generalConsolidated) {
            this.generalFinancialFileId = generalConsolidated.financialFileId;
          }
          const consolidatedSquad = this.consolidatesSquads.find(
            squad => squad.financialFileId === this.financialFileId
          );
          if (consolidatedSquad) {
            this.consolidatedSquad = consolidatedSquad;
          }
        },
        error: error => {
          this.isLoadingConsolidatesSquads = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  private getComparativeByCampaign(financialFileId: string, page: number, pageSize: number): void {
    this.isLoadingComparativeCampaign = true;
    this.comparativeService
      .getComparativeByCampaign(financialFileId, page, pageSize)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response: ComparativeCampaignResponse) => {
          this.isLoadingComparativeCampaign = false;
          this.totalRecords = response.totalRecords;
          this.comparativeCampaign = response.comparatives;
        },
        error: error => {
          this.isLoadingComparativeCampaign = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  private getComparativeByPackage(
    financialFileId: string,
    squadId: string,
    campaignId: string,
    page?: number,
    pageSize?: number
  ): void {
    const prevComparativeCampaign = this.comparativeCampaign.find(
      comparative => comparative.squadId == squadId && comparative.campaignId === campaignId
    );

    if (prevComparativeCampaign) {
      if (prevComparativeCampaign?.comparativePackages?.length > 0) return;

      prevComparativeCampaign.isLoadingPackages = true;
      this.comparativeService
        .getComparativeByPackage(financialFileId, squadId, campaignId, page, pageSize)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: (response: ComparativePackageResponse) => {
            prevComparativeCampaign.isLoadingPackages = false;

            prevComparativeCampaign.comparativePackages = response.comparatives;
          },
          error: error => {
            prevComparativeCampaign.isLoadingPackages = false;
            this.message.showErrorByStatus(error.status);
          }
        });
    }
  }

  onExpandChange(squadId: string, campaignId: string, checked: boolean): void {
    if (checked) {
      this.getComparativeByPackage(this.consolidatedSquad.financialFileId, squadId, campaignId);
      this.expandSet.add(`${squadId}-${campaignId}`);
    } else {
      this.expandSet.delete(`${squadId}-${campaignId}`);
    }
  }

  onPageIndexChange(pageIndex: number): void {
    this.expandSet.clear();
    this.currentPageIndex = pageIndex;
    this.getComparativeByCampaign(this.consolidatedSquad.financialFileId, pageIndex, PAGE_SIZE);
  }

  public ngValueChange(event: ConsolidatesSquadsResponse): void {
    this.expandSet.clear();
    this.consolidatedSquad = event;
    this.getComparativeByCampaign(this.consolidatedSquad.financialFileId, 1, PAGE_SIZE);
    this.router.navigate(['/files/comparative'], {
      queryParams: {
        financial_file_id: this.consolidatedSquad.financialFileId,
        last_dmi_import_date: this.lastDmiImportDate,
        ref_date: this.dateRef
      }
    });
  }

  private setDateRefText(): void {
    this.dateRefText = `${
      CONSTANTS.MONTH_NAMES[this.dateRef.getMonth()]
    } ${this.dateRef.getUTCFullYear()}`;
  }

  private setUserGroup(): void {
    this.userGroup = this.userInfoService.getUserGroup();
  }

  private setupEventListeners(): void {
    this.route.queryParams.pipe(takeUntil(this._destroying$)).subscribe((params: Params) => {
      this.financialFileId = params['financial_file_id'];
      this.lastDmiImportDate = params['last_dmi_import_date'];
      const defaultRefDate = new Date().toISOString();

      const refDate = params['ref_date'] || defaultRefDate;
      const newDate = new Date(refDate);
      const newDateUTC = new Date(
        newDate.getUTCFullYear(),
        newDate.getUTCMonth(),
        newDate.getUTCDate()
      );

      this.dateRef = newDateUTC;
      this.setDateRefText();
    });
  }

  ngOnInit(): void {
    this.setUserGroup();

    this.setupEventListeners();

    this.getConsolidatesSquads();
    this.getComparativeByCampaign(this.financialFileId, 1, PAGE_SIZE);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
