import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../../../shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private toastrService: ToastrService, private translate: TranslateService) {}

  public showSuccess(text: string, override?: Partial<IndividualConfig>): void {
    this.toastrService.success(
      this.translate.instant(text),
      this.translate.instant('Success'),
      override
    );
  }

  public showInfo(text: string, override?: Partial<IndividualConfig>): void {
    this.toastrService.info(
      this.translate.instant(text),
      this.translate.instant('Information'),
      override
    );
  }

  public showWarning(text: string, override?: Partial<IndividualConfig>): void {
    this.toastrService.warning(
      this.translate.instant(text),
      this.translate.instant('Warning'),
      override
    );
  }

  public showErrorByStatus(status: number): void {
    this.toastrService.error(
      this.translate.instant('Error_if_the_problem_persists_try_again'),
      this.translate.instant(`api_status.${status}`)
    );
  }

  public showError(text?: string, override?: Partial<IndividualConfig>): void {
    if (typeof text !== 'string') {
      text = CONSTANTS.DEFAULT_ERROR_MESSAGE;
    }
    this.toastrService.error(
      this.translate.instant(text),
      this.translate.instant('Error'),
      override
    );
  }
}
