<div class="container-fluid">
  <div class="d-flex flex-column">
    <div nz-col class="cm-page-title">
      {{ 'Registration' | translate }}
    </div>

    <div nz-col class="cm-page-subtitle">
      {{ 'View records' | translate }}
    </div>

    <nz-card class="h-100">
      <div class="d-flex flex-row flex-md-wrap mb-3">
        <div>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="{{ 'Choose_Category' | translate }}"
            [(ngModel)]="categorySelected"
            (ngModelChange)="categoryChange()"
          >
            <nz-option
              nzLabel="{{ 'Product' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Product }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'Campaign' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Campaign }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'Media' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Media }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'Network' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Network }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'Vehicle' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Vehicle }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'Package' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Package }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'PurchaseType' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.PurchaseType }}"
            ></nz-option>
            <nz-option
              nzLabel="{{ 'Commitment' | translate }}"
              nzValue="{{ RegistrationCategoryEnum.Commitment }}"
            ></nz-option>
          </nz-select>
        </div>
        <div class="px-3 flex-fill">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input [(ngModel)]="search" class="mw-200" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
        <div class="d-flex flex-row flex-nowrap align-items-center">
          <label class="pe-2 text-nowrap">{{ 'Order_By' | translate }}:</label>
          <nz-radio-group [(ngModel)]="ordination">
            <label nz-radio nzValue="{{ OrdinationEnum.Last }}">{{ 'Last' | translate }}</label>
            <label nz-radio nzValue="{{ OrdinationEnum.Older }}">{{ 'Older' | translate }}</label>
          </nz-radio-group>
        </div>
      </div>
      <div [ngSwitch]="categorySelected" class="d-flex flex-column flex-fill">
        <div *ngSwitchCase="'product'">
          <app-product-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-product-data-view>
        </div>
        <div *ngSwitchCase="'campaign'">
          <app-campaign-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-campaign-data-view>
        </div>
        <div *ngSwitchCase="'media'">
          <app-media-data-view [search]="search" [ordination]="ordination"></app-media-data-view>
        </div>
        <div *ngSwitchCase="'network'">
          <app-network-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-network-data-view>
        </div>
        <div *ngSwitchCase="'vehicle'">
          <app-vehicle-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-vehicle-data-view>
        </div>
        <div *ngSwitchCase="'package'">
          <app-package-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-package-data-view>
        </div>
        <div *ngSwitchCase="'purchaseType'">
          <app-purchase-type-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-purchase-type-data-view>
        </div>
        <div *ngSwitchCase="'commitment'">
          <app-commitment-data-view
            [search]="search"
            [ordination]="ordination"
          ></app-commitment-data-view>
        </div>
        <div *ngSwitchDefault class="m-auto">
          <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="contentEmptyTpl"></nz-empty>
          <ng-template #contentEmptyTpl>
            <label
              >Para carregar as informações de cadastros, selecione uma das categorias
              disponíveis.</label
            >
          </ng-template>
        </div>
      </div>
    </nz-card>
  </div>
</div>
