import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { baseWebApiURL } from '@env';
import { ExecutedLineModel, GetExecutedLineByFinancialFileIdResponse } from '@models/executedLine';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExecutedLineService {
  constructor(private http: HttpClient) {}

  public updateTable = new Subject<FormGroup>();

  public getByFinancialFileId(
    financialFileId: string,
    page?: number,
    pageSize?: number,
    productId?: string,
    campaignId?: string,
    mediaId?: string,
    vehicleId?: string,
    nroPi?: string
  ): Observable<GetExecutedLineByFinancialFileIdResponse> {
    const route = `${baseWebApiURL}/v1/ExecutedLine?financialFileId=${financialFileId}${
      page ? '&page=' + page : ''
    }${pageSize ? '&pageSize=' + pageSize : ''}${productId ? '&productId=' + productId : ''}${
      campaignId ? '&campaignId=' + campaignId : ''
    }${mediaId ? '&mediaId=' + mediaId : ''}${vehicleId ? '&vehicleId=' + vehicleId : ''}${
      nroPi ? '&nroPi=' + nroPi : ''
    }
      `;

    return this.http.get<GetExecutedLineByFinancialFileIdResponse>(route);
  }

  public update(
    financialFileId: string,
    key: string,
    value: string,
    lines: number[]
  ): Observable<ExecutedLineModel[]> {
    const route = `${baseWebApiURL}/v1/ExecutedLine/update`;

    return this.http.post<ExecutedLineModel[]>(route, {
      financialFileId,
      key,
      value,
      lines
    });
  }
}
