import { FormControl } from '@angular/forms';

export class CustomStringValidators {
  static validateString(c: FormControl): { error: boolean; invalid: boolean } | null {
    if (c.value?.length > 0) {
      if (c.value.indexOf('~') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('`') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('!') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('@') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('#') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('$') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('%') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('^') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('&') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('*') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('"') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf("'") >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('<') >= 0) return { error: true, invalid: true };

      if (c.value.indexOf('>') >= 0) return { error: true, invalid: true };

      return null;
    }
    return null;
  }
}
