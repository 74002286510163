import { Component, Input, DoCheck, OnDestroy } from '@angular/core';
import { DmiLinesService } from '@core/services/dmiLines/dmiLines.service';
import { MessageService } from '@core/services/message/message.service';
import { DmiModel } from '@models/dmi/dmi.model';
import { TranslateService } from '@ngx-translate/core';
import { DateHelperService } from 'ng-zorro-antd/i18n';
import { Subject, takeUntil } from 'rxjs';

declare let require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-dmi-card',
  templateUrl: './dmi-card.component.html',
  styleUrls: ['./dmi-card.component.scss']
})
export class DmiCardComponent implements OnDestroy, DoCheck {
  @Input() dmiFile!: DmiModel;

  private readonly destroy$ = new Subject<void>();

  displayDate: string | null = '';

  step = 0;

  isDownloading = false;

  constructor(
    public translate: TranslateService,
    private message: MessageService,
    private dmiLinesService: DmiLinesService,
    private datePipe: DateHelperService
  ) {}

  ngDoCheck(): void {
    this.displayDate =
      this.datePipe.format(
        new Date(this.dmiFile.referenceYear, this.dmiFile.referenceMonth - 1, 1),
        'MMMM, YYYY'
      ) ?? '';
  }

  public downloadFile(file: DmiModel): void {
    this.isDownloading = true;
    const downloadFileName = this.getDownloadFileName(file);

    this.dmiLinesService
      .downloadFile(this.dmiFile.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: blob => {
          FileSaver.saveAs(blob, downloadFileName);
          this.isDownloading = false;
        },
        error: error => {
          this.isDownloading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  private getDownloadFileName = (file: DmiModel): string => {
    const fileType = 'DMI';

    const month = String(file.referenceMonth).padStart(2, '0');

    const year = file.referenceYear;

    const filename = `${fileType}_${month}_${year}.xlsx`;

    return filename;
  };

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
