<nz-modal
  nzWidth="654px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="handleClose()"
  (nzAfterOpen)="afterOpen()"
  [(nzVisible)]="isVisible"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-title">
      <div class="modal-title my-3">{{ 'Edit' | translate }}</div>
    </div>
    <div></div>
  </ng-container>

  <ng-container *nzModalContent>
    <div class="cm-modal-body">
      <nz-spin nzSize="large" [nzSpinning]="isSpinning || isLoadingData">
        <div>
          <form nz-form [formGroup]="validationForm">
            <nz-row nzGutter="6">
              <nz-col [nzXs]="{ span: 24 }">
                <div class="mb-2">
                  <nz-form-label nzRequired>{{ 'Email' | translate }}</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input formControlName="email" nz-input [disabled]="true" />
                  </nz-form-control>
                </div>
              </nz-col>

              <nz-col [nzXs]="{ span: 24 }">
                <div class="mb-2">
                  <nz-form-label nzRequired>{{ 'Group' | translate }}</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <nz-select
                      formControlName="groupId"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="{{ 'Choose_Group' | translate }}"
                      [(ngModel)]="validationForm.value.groupId"
                      (ngModelChange)="ngChangeGroup($event)"
                    >
                      <nz-option
                        *ngFor="let group of groups"
                        [nzValue]="group.id"
                        [nzLabel]="group.name | translate"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </div>
              </nz-col>

              <nz-col *ngIf="isShowSquad" [nzXs]="{ span: 24 }">
                <div class="mb-2">
                  <nz-form-label nzRequired>{{ 'Squad' | translate }}</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <nz-select
                      formControlName="squadId"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="{{ 'Choose_Squad' | translate }}"
                    >
                      <nz-option
                        *ngFor="let squad of squads"
                        [nzValue]="squad.id"
                        [nzLabel]="squad.squadName | translate"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </div>
              </nz-col>
            </nz-row>
          </form>
        </div>
      </nz-spin>
    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div *ngIf="!isSpinning && !isLoadingData" class="d-flex justify-content-end">
      <app-button class="me-2" type="link" (click)="handleClose()">
        {{ 'Cancel' | translate }}
      </app-button>
      <app-button type="primary" [disabled]="!validationForm.valid" (click)="handleSave()">
        {{ 'Save_changes' | translate }}
      </app-button>
    </div>
  </ng-container>
</nz-modal>
