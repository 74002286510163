import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ComponentsModule } from '@components/components.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { IframeContainer } from './iframe-container/iframe-container.component';
import { DashboardService } from './dashboard.service';

@NgModule({
  declarations: [DashboardComponent, IframeContainer],
  exports: [DashboardComponent],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    AppRoutingModule,
    NzTabsModule,
    NzSpinModule
  ],
  providers: [DashboardService]
})
export class DashboardModule {}
