import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@core/services/message/message.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public constructor(
    private route: ActivatedRoute,
    private message: MessageService,
    private router: Router
  ) {}

  private showModalFromRoute(): void {
    const { message } = this.route.snapshot.data;

    if (message === 'not_found') {
      this.message.showErrorByStatus(404);
      this.router.navigate(['/']);
    }
  }

  public ngOnInit(): void {
    this.showModalFromRoute();
  }
}
