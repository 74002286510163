import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommitmentService } from '@core/services/commitment/commitment.service';
import { MessageService } from '@core/services/message/message.service';
import { Ordination } from '@shared/enums';
import { CommitmentCompleteModel } from '@shared/models';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../../core/services/modal/modal.service';
import { CommitmentComponentService } from '../../../pages/registrations/commitment/commitment-component.service';

@Component({
  selector: 'app-commitment-data-view',
  templateUrl: './commitment-data-view.component.html',
  styleUrls: ['./commitment-data-view.component.scss']
})
export class CommitmentDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private commitmentsData: CommitmentCompleteModel[] = [];

  loading = false;

  commitment: CommitmentCompleteModel = {} as CommitmentCompleteModel;

  commitments: CommitmentCompleteModel[] = [];

  isLoadingDelete = false;

  isVisibleEditModal = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private commitmentService: CommitmentService,
    private commitmentComponentService: CommitmentComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.commitments = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.commitments = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): CommitmentCompleteModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.commitmentsData
      : this.commitmentsData.filter(
          commitment =>
            StringHelper.includesNormalized(commitment.network.networkName, termNormalized) ||
            StringHelper.includesNormalized(commitment.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(commitment.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): CommitmentCompleteModel[] {
    return direction === Ordination.Last
      ? this.commitments.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.commitments.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.commitmentService
      .getCommitments('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (commitments: CommitmentCompleteModel[]) => {
          this.commitmentsData = commitments;
          this.commitments =
            this.search === '' ? commitments : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.commitmentComponentService.updateCommitmentsGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDeleted(commitmentId: string): void {
    this.isLoadingDelete = true;
    this.commitmentService
      .deleteCommitment(commitmentId)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.isLoadingDelete = false;
          this.message.showSuccess('Commitment deleted successfully');
          this.getData();
        },
        error: error => {
          this.message.showErrorByStatus(error.status);
          this.isLoadingDelete = false;
        }
      });
  }

  handleDelete(commitment: CommitmentCompleteModel): void {
    this.commitment = commitment;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: () => this.onDeleted(commitment.id)
    });
  }

  handleEdit(commitment: CommitmentCompleteModel): void {
    this.commitment = commitment;
    this.isVisibleEditModal = true;
  }

  handleCancel(): void {
    this.isVisibleEditModal = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
