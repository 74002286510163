<nz-modal
  nzWidth="1200px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="onClose()"
  [(nzVisible)]="isVisible"
>
  <nz-spin nzSize="large" [nzSpinning]="isSpinning">
    <ng-container *nzModalTitle>
      <div class="cm-modal-title">
        <div class="modal-title my-3">{{ 'Send_data' | translate }}</div>
      </div>
    </ng-container>

    <ng-container *nzModalContent>
      <div *ngIf="!showSuccessMessage && !showErrorUploadMessage" class="cm-modal-body">
        <nz-spin nzSize="large" [nzSpinning]="isSpinning">
          <div>
            <form nz-form [formGroup]="validationForm">
              <div>
                <nz-row nzGutter="6">
                  <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
                    <div class="cm-label-input mb-2">
                      <nz-form-label nzRequired>{{ 'ReferenceDate' | translate }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-date-picker
                          formControlName="referenceDate"
                          class="d-flex"
                          nzSize="large"
                          nzMode="month"
                          nzFormat="MMMM yyyy"
                          nzAllowClear="false"
                          nzPlaceHolder="{{ 'Select_referenceDate' | translate }}"
                          [nzDisabledDate]="disabledDate"
                        ></nz-date-picker>
                      </nz-form-control>
                    </div>
                  </nz-col>

                  <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
                    <div class="cm-label-input mb-2">
                      <nz-form-label nzRequired>{{ 'FileType' | translate }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          formControlName="fileType"
                          nzSize="large"
                          nzLimit="1"
                          nzPlaceHolder="{{ 'Select_fileType' | translate }}"
                          (ngModelChange)="onFileTypeChange($event)"
                        >
                          <nz-option
                            *ngFor="let item of fileTypes"
                            [nzValue]="item.id"
                            [nzLabel]="item.name | translate"
                          ></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </div>
                  </nz-col>

                  <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
                    <div class="cm-label-input mb-2">
                      <nz-form-label
                        [nzRequired]="
                          validationForm.get('fileType')?.value === FinancialFileTypes.Planned ||
                          validationForm.get('fileType')?.value === FinancialFileTypes.Executed
                        "
                        >{{ 'Squad' | translate }}</nz-form-label
                      >
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          formControlName="squad"
                          nzSize="large"
                          nzLimit="1"
                          nzPlaceHolder="{{ 'Select_squad' | translate }}"
                          [nzDisabled]="
                            validationForm.get('fileType')?.value === FinancialFileTypes.Planned ||
                            validationForm.get('fileType')?.value === FinancialFileTypes.Executed
                              ? false
                              : true
                          "
                        >
                          <nz-option
                            *ngFor="let item of squads"
                            [nzValue]="item.id"
                            [nzLabel]="item.squadName"
                          ></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </div>
                  </nz-col>

                  <nz-col [nzXs]="{ span: 24 }">
                    <div class="cm-label-input mb-2">
                      <nz-form-label nzRequired>{{ 'File' | translate }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-upload
                          formArrayName="fileData"
                          [(nzFileList)]="fileList"
                          [nzBeforeUpload]="onFileBeforeUpload"
                          [nzRemove]="onFileRemove"
                          nzType="drag"
                          [nzMultiple]="false"
                          [nzDisabled]="isSpinning"
                        >
                          <p class="ant-upload-drag-icon">
                            <span nz-icon nzType="inbox"></span>
                          </p>
                          <p class="ant-upload-text">
                            {{ 'Click_or_drag_file_to_this_area_to_upload' | translate }}
                          </p>
                          <div class="text-center my-2">
                            {{ 'Accepted_formats' | translate }} <b>.xls</b> e <b>.xlsx</b>
                          </div>
                        </nz-upload>
                      </nz-form-control>
                    </div>
                  </nz-col>
                </nz-row>
              </div>
            </form>
          </div>
        </nz-spin>
      </div>
      <div *ngIf="showSuccessMessage || showErrorUploadMessage">
        <div *ngIf="showSuccessMessage">
          <span class="modal-icon success material-icons d-flex justify-content-center mb-2"
            >done</span
          >
          <p class="text-center fw-bold mb-3">
            {{ 'Data_sent_successfully' | translate }}
          </p>

          <app-alert
            *ngIf="inconsistencyId !== ''"
            type="secondary"
            customClass="text-center text-danger"
          >
            <a
              class="e-underline"
              [routerLink]="['/files/inconsistencies', inconsistencyId]"
              (click)="(onClose)"
            >
              {{ 'File_contains_errors_View_errors' | translate }}
              <span class="material-icons align-middle"> open_in_new </span>
            </a>
          </app-alert>
        </div>

        <div *ngIf="showErrorUploadMessage">
          <span class="modal-icon error material-icons d-flex justify-content-center mb-2"
            >error_outline</span
          >
          <p class="text-center fw-bold mb-3">
            {{ 'File_could_not_be_imported_because_it_contains_the_following_errors' | translate }}
          </p>
          <app-message-dmi-error *ngIf="dmiError | objLength" [dmiError]="dmiError">
          </app-message-dmi-error>
          <app-message-file-error
            *ngIf="(plannedOrExecutedError | objLength) > 0"
            [fileError]="plannedOrExecutedError"
          ></app-message-file-error>
        </div>
        <div>
          <p class="text-center fw-bold mb-4">
            {{ 'Do_you_want_to_send_new_data' | translate }}
          </p>
          <div class="d-flex justify-content-center text-center">
            <app-button type="link-secondary" (click)="onClose()" class="me-2">
              {{ 'No' | translate }}
            </app-button>
            <app-button type="primary" (click)="onGoBack()">
              {{ 'Yes' | translate }}
            </app-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *nzModalFooter>
      <div
        *ngIf="!showSuccessMessage && !showErrorUploadMessage && !isSpinning"
        class="d-flex justify-content-end"
      >
        <app-button class="me-2" type="link" (click)="onClose()">
          {{ 'Cancel' | translate }}
        </app-button>
        <app-button type="primary" [disabled]="!validationForm.valid" (click)="onUploadData()">
          {{ 'Send_data' | translate }}
        </app-button>
      </div>
    </ng-container>
  </nz-spin>
</nz-modal>
