import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { VehicleComponentService } from './vehicle-component.service';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  isSpinningForm!: boolean;

  isSpinningTable!: boolean;

  constructor(private vehicleComponentService: VehicleComponentService, private router: Router) {}

  ngOnInit(): void {
    this.vehicleComponentService.isFormLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((formIsLoading: boolean) => {
        this.isSpinningForm = formIsLoading;
      });

    this.vehicleComponentService.isTableLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((tableIsLoading: boolean) => {
        this.isSpinningTable = tableIsLoading;
      });
  }

  navigate(): void {
    this.router.navigateByUrl('registrations/list?category=vehicle');
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
