<nz-table #filterTable [nzData]="listOfCurrentPageRules" [nzShowPagination]="false">
  <thead>
    <tr>
      <th
        class="column-header column-header--field ps-4"
        [nzChecked]="checked"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th
        class="col-2 column-header column-header--field"
        [nzFilterMultiple]="true"
        [nzFilterFn]="true"
        [nzFilters]="listOfFilters"
        (nzFilterChange)="changeFilters($event)"
      >
        {{ 'Type' | translate }}
      </th>
      <th class="col-3 column-header column-header--field">
        {{ 'From' | translate | uppercase }}
      </th>
      <th class="col-3 column-header column-header--field">
        {{ 'To' | translate | uppercase }}
      </th>
      <th class="col-2 column-header column-header--field">
        {{ 'CreatedBy' | translate }}
      </th>
      <th
        class="col-2 column-header column-header--field"
        [nzSortFn]="true"
        [nzSortDirections]="sortDirections"
        (nzSortOrderChange)="changeOrder($event)"
      >
        {{ 'CreatedAt' | translate }}
      </th>
      <th class="column-header column-header--field__icon pe-2"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of filterTable.data">
      <td
        [nzChecked]="selectedCheckboxesIds.has(data.id)"
        (nzCheckedChange)="onItemChecked(data.id, $event)"
        class="column-content ps-4"
      ></td>

      <td class="column-content--type">
        <span
          class="fw-semibold"
          *ngIf="data.inconsistencyGroup === InconsistencyGroup.ProductCampaign"
        >
          {{ 'ProductCampaign' | translate }}
        </span>
        <span
          class="fw-semibold"
          *ngIf="data.inconsistencyGroup === InconsistencyGroup.MediaNetworkVehicle"
        >
          {{ 'MediaNetworkVehicle' | translate }}
        </span>
        <span class="fw-semibold" *ngIf="data.inconsistencyGroup === InconsistencyGroup.Package">
          {{ 'Package' | translate }}
        </span>
        <span
          class="fw-semibold"
          *ngIf="data.inconsistencyGroup === InconsistencyGroup.PurchaseType"
        >
          {{ 'PurchaseType' | translate }}
      </span>
      </td>
      <td class="column-content">{{ data.from }}</td>
      <td class="column-content">{{ data.to }}</td>
      <td class="column-content">{{ data.createdBy }}</td>
      <td class="column-content">
        {{ data.createdAt | date : 'dd/MM/yy HH:mm' }}
      </td>
      <td class="column-content--icon">
        <span
          nz-icon
          class="column-content--icon pe-2"
          nzType="delete"
          nzTheme="fill"
          (click)="showExclusionModal(data.id)"
          nz-tooltip
          nzTooltipTitle="{{ 'Delete' | translate }}"
        ></span>
      </td>
    </tr>
  </tbody>
</nz-table>
<div class="d-flex flex-row justify-content-center p-4" *ngIf="listOfCurrentPageRules.length > 0">
  <app-pagination
    [pageIndex]="fromToRules.page"
    [pageSize]="fromToRules.pageSize"
    [total]="fromToRules.totalRecords"
    (pageIndexChange)="onPageIndexChange($event)"
  ></app-pagination>
</div>
