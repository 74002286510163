import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { CampaignCompleteModel, CampaignModel } from '@models/campaign/campaign.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private http: HttpClient) {}

  public getCampaigns(
    columnToOrder: string,
    descendingDirection: boolean,
    length?: number
  ): Observable<CampaignCompleteModel[]> {
    const lengthParam = length ? `&length=${length}` : '';

    const route = `${baseWebApiURL}/v1/Campaign/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}${lengthParam}`;

    return this.http.get<CampaignCompleteModel[]>(route);
  }

  public getAllCampaigns(): Observable<CampaignModel[]> {
    return this.http.get<CampaignModel[]>(`${baseWebApiURL}/v1/Campaign/campaigns`);
  }

  public saveCampaign(productId: string, campaignName: string): Observable<CampaignModel> {
    return this.http.post<CampaignModel>(
      `${baseWebApiURL}/v1/Campaign?productId=${productId}&name=${campaignName}`,
      null
    );
  }

  public deleteCampaign(campaignId: string): Observable<CampaignCompleteModel> {
    return this.http.delete<CampaignCompleteModel>(`${baseWebApiURL}/v1/Campaign/${campaignId}`);
  }
}
