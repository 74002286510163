import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public getToken(): string | false {
    const key = window.localStorage['okta-token-storage'];
    if (key) {
      return `Bearer ${JSON.parse(key).accessToken.accessToken}`;
    } else {
      return false;
    }
  }
}
