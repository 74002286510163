import { Subject } from "rxjs"
import * as jose from 'jose';
import { JWTPayload } from "@okta/okta-auth-js";
import { DomSanitizer } from "@angular/platform-browser";
import { Injectable } from "@angular/core";

@Injectable()
export class DashboardService {

    private alg = "HS256";

    constructor(
        public sanitizer: DomSanitizer,
    ) {
    }

    private generatePayload(dashboardId: number): Partial<JWTPayload> {
        return {
            resource: { dashboard: dashboardId },
            params: {},
            exp: Math.round(Date.now() / 1000) + 10 * 60
        }
    }

    private async generateJWT(payload: Partial<JWTPayload>, secret: Uint8Array) {
        const token = await new jose.SignJWT(payload).setProtectedHeader({ alg: this.alg }).sign(secret)
        return token;
    }

    private urlGenerator(token: string, baseUrl: string, bordered = false, titled = false) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}/embed/dashboard/${token}#bordered=${bordered}&titled=${titled}`)
    }

    public async generateMetabaseUrl(dashboardId: number, secret: Uint8Array, baseUrl: string) {
        const payload = this.generatePayload(dashboardId)
        const token = await this.generateJWT(payload, secret)
        return this.urlGenerator(token, baseUrl);
    }
}