export enum RegistrationCategory {
  Product = 'product',
  Campaign = 'campaign',
  Media = 'media',
  Network = 'network',
  Vehicle = 'vehicle',
  Package = 'package',
  PurchaseType = 'purchaseType',
  Commitment = 'commitment'
}
