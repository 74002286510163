import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '@core/services/message/message.service';
import { ProductService } from '@core/services/product/product.service';
import { TranslateService } from '@ngx-translate/core';
import { atLeastOneCheckboxCheckedValidator } from '@shared/validators/at-least-one-checkbox-checked.validators';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject, takeUntil } from 'rxjs';
import { SquadService } from '../../../../core/services/squad/squad.service';
import { SquadModel } from '../../../../shared/models';
import { ProductComponentService } from '../product-component.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  dataSquads: SquadModel[] = [];

  isSpinning = false;

  autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    private productService: ProductService,

    private squadService: SquadService,
    private productComponentService: ProductComponentService,
    public translate: TranslateService
  ) {}

  get formGroupControls(): FormGroup['controls'] {
    return this.validationForm && this.validationForm.controls;
  }

  get squads(): FormGroup {
    return this.formGroupControls && <FormGroup>this.formGroupControls['squads'];
  }

  get squadsSelectedIds(): string[] {
    const ids: string[] = [];
    if (this.squads) {
      for (const key in this.squads.controls) {
        if (this.squads.controls[key].value) {
          ids.push(key);
        }
      }
    }
    return ids;
  }

  buildCategoryFormGroup(squads: SquadModel[], selectedCategoryIds: string[] = []): FormGroup {
    const group = this.formBuilder.group(
      {},
      {
        validators: atLeastOneCheckboxCheckedValidator()
      }
    );
    squads.forEach(category => {
      const isSelected = selectedCategoryIds.some(id => id === category.id);
      group.addControl(category.id, this.formBuilder.control(isSelected));
    });
    return group;
  }

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      productName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
    this.getData();
  }

  getData(): void {
    this.productComponentService.isFormLoading.next(true);
    this.squadService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: squads => {
          this.dataSquads = squads;
          this.validationForm.addControl('squads', this.buildCategoryFormGroup(squads));
          this.productComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.productComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  cleanForm(): void {
    this.validationForm.controls['productName'].reset();
    this.validationForm.controls['squads'].reset();
  }

  save(): void {
    this.productComponentService.isFormLoading.next(true);

    const productName = this.validationForm.get('productName')?.value;

    const product = {
      productName,
      squadIds: this.squadsSelectedIds
    };

    this.productService
      .saveProduct(product)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['productName'].reset();

          this.productComponentService.isFormLoading.next(false);

          this.productComponentService.updateProductGrid.next(true);

          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.productComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
