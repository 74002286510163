import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { VehicleService } from '@core/services/vehicle/vehicle.service';
import { VehicleCompleteModel } from '@models/vehicle';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { VehicleComponentService } from '../vehicle-component.service';

@Component({
  selector: 'app-vehicle-grid',
  templateUrl: './vehicle-grid.component.html',
  styleUrls: ['./vehicle-grid.component.scss']
})
export class VehicleGridComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  vehicle: VehicleCompleteModel = {} as VehicleCompleteModel;

  vehicles: VehicleCompleteModel[] = [];

  isLoadingDelete = false;

  constructor(
    private vehicleService: VehicleService,
    private vehicleComponentService: VehicleComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTable();
  }

  getData(): void {
    this.vehicleComponentService.isTableLoading.next(true);
    this.vehicleService
      .listVehicles('CreatedAt', true, 5)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (vehicles: VehicleCompleteModel[]) => {
          this.vehicles = vehicles;
          this.vehicleComponentService.isTableLoading.next(false);
        },
        error: error => {
          this.vehicleComponentService.isTableLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.vehicleComponentService.updateVehiclesGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(vehicleId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.vehicleService
        .deleteVehicle(vehicleId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Vehicle deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(vehicle: VehicleCompleteModel): void {
    this.vehicle = vehicle;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(vehicle.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
