<div (window:resize)="onResize($event)">
  <nz-table
    class="small-padding-table"
    #fixedTable
    [nzFrontPagination]="false"
    [nzScroll]="{ x: widthScroll }"
    [nzShowPagination]="false"
    [nzWidthConfig]="
      fileType === FinancialFileTypes.Planned || fileType === FinancialFileTypes.MediaHub
        ? plannedWidthCols
        : executedWidthCols
    "
  >
    <thead>
      <tr>
        <th *ngFor="let col of columns; index as indexCol">
          <app-spreadsheet-column-header
            [col]="col"
            [indexCol]="indexCol"
            [bannerInformation]="bannerInformation"
          ></app-spreadsheet-column-header>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows; let indexRow = index">
        <td
          nzBreakWord
          *ngFor="let cell of columns; let indexColumn = index"
          [nzBreakWord]="false"
          [ngClass]="{
            'cell-error-table': row[indexColumn].status == RowStatus.Error,
            'cell-success-table': row[indexColumn].status == RowStatus.Corrected
          }"
        >
          {{ row[indexColumn].text }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<app-form-product-campaign
  [validationFileId]="validationFileId"
  [isVisible]="isVisibleModalProductCampaign"
  (isVisibleModalChange)="isVisibleModalProductCampaign = $event"
  [squadId]="squadId"
>
</app-form-product-campaign>
<app-form-media-network-vehicle
  [validationFileId]="validationFileId"
  [isVisible]="isVisibleModalMediaNetworkVehicle"
  (isVisibleModalChange)="isVisibleModalMediaNetworkVehicle = $event"
>
</app-form-media-network-vehicle>
<app-form-package
  [validationFileId]="validationFileId"
  [isVisible]="isVisibleModalPackage"
  (isVisibleModalChange)="isVisibleModalPackage = $event"
>
</app-form-package>
<app-form-purchase-type
  [validationFileId]="validationFileId"
  [isVisible]="isVisibleModalPurchaseType"
  (isVisibleModalChange)="isVisibleModalPurchaseType = $event"
>
</app-form-purchase-type>
<ng-template #modalTitle>
  <h4 class="mt-3 fw-bold">{{ 'Error_correction' | translate }}</h4>
</ng-template>
