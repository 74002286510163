import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { Observable } from 'rxjs';
import { ConsolidatedStatus } from '../../../shared/enums';
import { CustomConsolidatedFilters } from '../../../shared/models';
import { ConsolidatesSquadsResponse } from '../../../shared/models/consolidated';
import { SelectModel } from '../../../shared/models/select';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ConsolidatedService {
  private headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  };

  constructor(public configService: ConfigService, private http: HttpClient) {}

  public generatedConsolidatedSquad(squadId: string, financialFileId: string): Observable<unknown> {
    return this.http.post<unknown>(
      `${baseWebApiURL}/v1/Consolidated?squadId=${squadId}&financialFileId=${financialFileId}`,
      {},
      {
        headers: this.headers
      }
    );
  }

  public generatedConsolidatedGeneral(financialFileId: string): Observable<unknown> {
    return this.http.post<unknown>(
      `${baseWebApiURL}/v1/Consolidated/general?financialFileId=${financialFileId}`,
      {},
      {
        headers: this.headers
      }
    );
  }

  public getConsolidatesSquads(referenceDate: Date): Observable<ConsolidatesSquadsResponse[]> {
    return this.http.get<ConsolidatesSquadsResponse[]>(
      `${baseWebApiURL}/v1/Consolidated/squads?referenceDate=${referenceDate.toISOString()}`
    );
  }

  public changeConsolidateSquadStatus(
    financialFileId: string,
    status: ConsolidatedStatus
  ): Observable<SelectModel[]> {
    return this.http.post<SelectModel[]>(
      `${baseWebApiURL}/v1/Consolidated/changeStatus?financialFileId=${financialFileId}&status=${status}`,
      {}
    );
  }

  public getConsolidatedColumns(): Observable<SelectModel[]> {
    return this.http.get<SelectModel[]>(`${baseWebApiURL}/v1/Consolidated/columns`);
  }

  public getConsolidatedDistinctValues(
    financialFileId: string,
    column: string,
    filters: CustomConsolidatedFilters[]
  ): Observable<SelectModel[]> {
    return this.http.post<SelectModel[]>(
      `${baseWebApiURL}/v1/Consolidated/distinctValues?financialFileId=${financialFileId}&column=${column}`,
      filters
    );
  }
}
