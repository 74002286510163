import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { NetworkModel } from '@models/network/network.model';
import { Observable } from 'rxjs';
import { MediaModel } from '../../../shared/models/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(private http: HttpClient) {}

  public getMedias(): Observable<MediaModel[]> {
    return this.http.get<MediaModel[]>(`${baseWebApiURL}/v1/Medias`);
  }

  public getAllMedias(): Observable<MediaModel[]> {
    return this.http.get<MediaModel[]>(`${baseWebApiURL}/v1/Medias/all`);
  }

  public getOrderedMedias(
    columnToOrder: string,
    descendingDirection: boolean,
    length?: number
  ): Observable<MediaModel[]> {
    const lengthParam = length ? `&length=${length}` : '';

    const route = `${baseWebApiURL}/v1/Medias/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}${lengthParam}`;

    return this.http.get<MediaModel[]>(route);
  }

  public saveMedia(mediaName: string): Observable<MediaModel> {
    return this.http.post<MediaModel>(`${baseWebApiURL}/v1/Medias?name=${mediaName}`, null);
  }

  public getNetworkByMedias(mediaId: string): Observable<NetworkModel[]> {
    return this.http.get<NetworkModel[]>(`${baseWebApiURL}/v1/Medias/${mediaId}/networks`);
  }

  public deleteMedia(mediaId: string): Observable<MediaModel> {
    return this.http.delete<MediaModel>(
      `${baseWebApiURL}/v1/Medias/${mediaId}`
    );
  }
}
