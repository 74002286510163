<nz-modal
  nzWidth="1280px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="close()"
  [(nzVisible)]="isVisible"
  (nzAfterOpen)="afterOpen()"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-title">{{ 'Error_correction' | translate }}</div>
  </ng-container>

  <ng-container *nzModalContent>
    <div class="cm-modal-body" *ngIf="!isSavedUpdates">
      <nz-spin nzSize="large" [nzSpinning]="isSpinning">
        <div class="cm-modal-subtitle mb-3">{{ 'Package' | translate }}</div>
        <div class="row mb-1">
          <div class="col">
            <div class="fw-bold">{{ 'From' | translate }}</div>
          </div>
          <div class="col">
            <div class="fw-bold">{{ 'To' | translate }}</div>
          </div>
        </div>

        <form class="cm-form-scroll">
          <div class="row" *ngFor="let item of packageInconsistencies">
            <div class="col mb-2">
              <input
                nz-input
                [title]="item.package.original"
                [value]="item.package.original"
                [disabled]="true"
                class="disabled-input-color"
              />
            </div>
            <div class="col mb-2">
              <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="{{ 'Select_package' | translate }}"
                [(ngModel)]="item.package.id"
                (ngModelChange)="ngValueChangePackage()"
              >
                <nz-option
                  *ngFor="let package of packages"
                  [nzValue]="package.id"
                  [nzLabel]="package.description"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
        </form>
      </nz-spin>
    </div>
    <div
      *ngIf="isSavedUpdates"
      class="d-flex flex-column justify-content-center align-items-center p-5"
    >
      <span class="modal-icon success material-icons align-middle">done</span>
      <p class="modal-text success mt-2">{{ 'Saved_updates' | translate }}</p>
    </div>
    <div
      *ngIf="isSavedError"
      class="d-flex flex-column justify-content-center align-items-center modal-error-message"
    >
      <span class="modal-icon error material-icons-outlined align-middle">cancel</span>
      <p class="modal-text error mt-3 mb-4">{{ 'Saved_error' | translate }}</p>

      <app-button class="d-flex" type="link" (click)="back()">
        <span class="modal-text error material-icons md-18">west</span>
        <span class="modal-text error ms-2">
          <u>{{ 'Back' | translate }}</u>
        </span>
      </app-button>
    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div *ngIf="!isSavedUpdates" class="d-flex justify-content-end">
      <app-button class="me-2" *ngIf="!isSpinning" type="link" (click)="close()">
        {{ 'Cancel' | translate }}
      </app-button>
      <app-button type="primary" *ngIf="!isSpinning" [disabled]="disabledSaveBtn" (click)="save()">
        {{ 'Save_changes' | translate }}
      </app-button>
    </div>
  </ng-container>
</nz-modal>
