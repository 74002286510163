import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DmiErrorModel } from '@models/dmi';

@Component({
  selector: 'app-message-dmi-error',
  templateUrl: './message-dmi-error.component.html',
  styleUrls: ['./message-dmi-error.component.scss']
})
export class MessageDmiErrorComponent {
  @Input() dmiError: DmiErrorModel = {};

  @Input() isShowMessage = false;

  @Output() isShowMessageEmitter = new EventEmitter<boolean>();
}
