import { Component, OnInit } from '@angular/core';
import { GroupService } from '@core/services/group/group.service';
import { SquadService } from '@core/services/squad/squad.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupModel, SquadModel } from '@shared/models';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from '../../core/services/message/message.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  public activeTab = 0;

  public squads: SquadModel[] = [];

  public groups: GroupModel[] = [];

  constructor(
    public translate: TranslateService,
    private message: MessageService,
    public groupService: GroupService,
    private squadService: SquadService
  ) {}

  public changeTab(tabIndex: number | undefined): void {
    tabIndex = tabIndex ?? 0;

    this.activeTab = tabIndex;
  }

  getGroups(): void {
    this.groupService
      .getAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: groups => {
          this.groups = groups;
        },
        error: error => {
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  getSquads(): void {
    this.squadService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: squads => {
          this.squads = squads;
        },
        error: error => {
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnInit(): void {
    this.getGroups();
    this.getSquads();
  }
}
