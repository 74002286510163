<div class="container-fluid">
  <app-header-calendar
    [isBackArrow]="true"
    [dateRefText]="dateRefText"
    [queryParams]="{
      active_tab: 3,
      ref_date: dateRef.toISOString().substring(0, 10)
    }"
  ></app-header-calendar>
  <div class="dmi-title mb-3">DMI</div>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div *ngIf="lastDmiImportDate" class="dmi-last-import">
      <span class="dmi-info-icon material-icons-outlined md-20 me-2">info</span>
      <span class="dmi-last-import-text">{{ 'Last_dmi_uploaded' | translate }}</span>
      <span> </span>
      <span class="dmi-last-import-text" style="white-space: pre">
        {{ lastDmiImportDate | date : "dd 'de' MMMM 'de' yyyy 'às' HH:mm" }}
      </span>
    </div>
    <div>
      <div
        *ngIf="
          consolidatedSquad.squadName &&
          (userGroup === userGroups.squad || userGroup === userGroups.customer)
        "
        class="squad-title mb-3"
      >
        Squad: {{ consolidatedSquad.squadName }}
      </div>
      <nz-select
        *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
        class="squads-select"
        nzShowSearch
        nzPlaceHolder="{{ 'Select_comparative' | translate }}"
        [(ngModel)]="consolidatedSquad"
        (ngModelChange)="ngValueChange($event)"
        [nzLoading]="isLoadingConsolidatesSquads"
        [nzDisabled]="isLoadingConsolidatesSquads"
      >
        <nz-option
          *ngFor="let consolidated of consolidatesSquads"
          [nzValue]="consolidated"
          [nzLabel]="consolidated.squadName"
          [nzDisabled]="
            !consolidated.financialFileId ||
            (consolidated.financialFile.status != ConsolidatedStatus.Generated &&
              consolidated.financialFile.status != ConsolidatedStatus.Approved)
          "
        >
        </nz-option>
      </nz-select>
    </div>
  </div>
  <nz-spin nzSize="large" [nzSpinning]="isLoadingComparativeCampaign">
    <nz-table #nestedTable [nzData]="comparativeCampaign" [nzShowPagination]="false">
      <thead>
        <tr>
          <th #plus class="label-th" nzWidth="60px"></th>
          <th #squad [hidden]="financialFileId !== generalFinancialFileId" class="label-th">
            {{ 'Squad' | translate }}
          </th>
          <th #campaign class="label-th">{{ 'Campaign' | translate }}</th>
          <th #product class="label-th">{{ 'Product' | translate }}</th>
          <th #dmi class="label-th">{{ 'DMITotal' | translate }}</th>
          <th #consolidated class="label-th">{{ 'Consolidated' | translate }}</th>
          <th #difference class="label-th">{{ 'Difference' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let campaignData of nestedTable.data">
          <tr class="table-tr">
            <td
              class="expand-td"
              [nzExpand]="expandSet.has(campaignData.squadId + '-' + campaignData.campaignId)"
              (nzExpandChange)="
                onExpandChange(campaignData.squadId, campaignData.campaignId, $event)
              "
            ></td>
            <td *ngIf="financialFileId === generalFinancialFileId">{{ campaignData.squadName }}</td>
            <td>{{ campaignData.campaignName }}</td>
            <td>{{ campaignData.productName }}</td>
            <td>{{ campaignData.totalCampaignDmi | currency : 'BRL' }}</td>
            <td>
              {{ campaignData.totalCampaignConsolidated | currency : 'BRL' }}
            </td>
            <td [ngClass]="campaignData.differenceCampaign != 0 ? 'fw-bold' : ''">
              {{ campaignData.differenceCampaign | currency : 'BRL' }}
            </td>
          </tr>
          <tr
            class="package-table"
            [nzExpand]="expandSet.has(campaignData.squadId + '-' + campaignData.campaignId)"
          >
            <nz-spin nzSize="large" [nzSpinning]="campaignData.isLoadingPackages">
              <nz-table
                #innerTable
                [nzData]="campaignData.comparativePackages"
                [nzShowPagination]="false"
              >
                <thead>
                  <tr class="table-tr">
                    <th
                      *ngIf="financialFileId === generalFinancialFileId"
                      class="label-th package"
                      [width]="squad.offsetWidth"
                    ></th>
                    <th
                      *ngIf="innerTable.data.length > 0"
                      class="label-th package"
                      [width]="campaign.offsetWidth + product.offsetWidth"
                    >
                      {{ 'Package' | translate }}
                    </th>
                    <th class="label-th package" [width]="dmi.offsetWidth"></th>
                    <th class="label-th package" [width]="consolidated.offsetWidth"></th>
                    <th class="label-th package" [width]="difference.offsetWidth"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let packageData of innerTable.data">
                    <td *ngIf="financialFileId === generalFinancialFileId"></td>
                    <td>{{ packageData.packageName }}</td>
                    <td>
                      {{ packageData.totalPackageDmi | currency : 'BRL' }}
                    </td>
                    <td>
                      {{ packageData.totalPackageConsolidated | currency : 'BRL' }}
                    </td>
                    <td [ngClass]="packageData.differencePackage != 0 ? 'fw-bold' : ''">
                      {{ packageData.differencePackage | currency : 'BRL' }}
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </nz-spin>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <div class="d-flex flex-row justify-content-center p-4">
      <app-pagination
        [pageIndex]="currentPageIndex"
        [pageSize]="pageSize"
        [total]="totalRecords"
        (pageIndexChange)="onPageIndexChange($event)"
      ></app-pagination>
    </div>
  </nz-spin>
</div>
