import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { InconsistencyService } from '@core/services/inconsistency/inconsistency.service';
import { MessageService } from '@core/services/message/message.service';
import { PurchaseTypeService } from '@core/services/purchase-type/purchase-type.service';
import {
  InconsistenciesGroupsModel,
  InconsistencyGroupErrorModel
} from '@models/inconsistency/inconsistency.model';
import {
  InconsistencyErrorStatus,
  InconsistencyGroup
} from '@shared/enums/inconsistency-status.enum';
import { Subject, takeUntil } from 'rxjs';
import { PurchaseTypeModel } from '../../../shared/models';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'app-form-purchase-type',
  templateUrl: './form-purchase-type.component.html',
  styleUrls: ['./form-purchase-type.component.scss']
})
export class FormPurchaseTypeComponent implements OnDestroy {
  @Input() validationFileId = '';

  @Input() isVisible = false;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _destroying$ = new Subject<void>();

  public purchaseTypeInconsistencies: InconsistencyGroupErrorModel[] = [];

  public isSpinning = true;

  public disabledSaveBtn = true;

  public groupInconsistencies?: InconsistenciesGroupsModel;

  public purchaseTypes: PurchaseTypeModel[] = [];

  public isSavedUpdates = false;

  public isSavedError = false;

  private autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private purchaseTypeService: PurchaseTypeService,
    private inconsistencyService: InconsistencyService,
    private spreadsheetService: SpreadsheetService,
    private message: MessageService
  ) {}

  public afterOpen(): void {
    this.getPurchaseTypes();
  }

  private getInconsistenciesGroup(): void {
    if (!this.validationFileId) return;

    this.inconsistencyService
      .getByIdGroup(this.validationFileId, InconsistencyGroup.PurchaseType)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: response => {
          this.groupInconsistencies = response;

          const purchaseTypeError = response.inconsistencies[0].errors;

          if (purchaseTypeError.length > 0) {
            this.purchaseTypeInconsistencies = this.filterPendingErrors(purchaseTypeError);
          }
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  public getPurchaseTypes(): void {
    this.isSpinning = true;
    this.purchaseTypeService.getPurchaseTypes().subscribe({
      next: data => {
        this.purchaseTypes = data;
        this.getInconsistenciesGroup();
      },
      error: () => {
        this.isSpinning = false;
      }
    });
  }

  public ngValueChangePurchaseType(): void {
    const isSomeCorrect = Object.values(this.purchaseTypeInconsistencies).some(
      inconsistency => inconsistency.purchaseType.id
    );

    this.disabledSaveBtn = !isSomeCorrect;
  }

  private filterPendingErrors(
    errors: InconsistencyGroupErrorModel[]
  ): InconsistencyGroupErrorModel[] {
    return errors.filter(
      inconsistency => inconsistency.status === InconsistencyErrorStatus.Pending
    );
  }

  private setIdAndCorrect(): void {
    this.purchaseTypeInconsistencies.forEach(item => {
      if (item.purchaseType.id) {
        const purchaseType = this.purchaseTypes.find(
          purchaseTypeItem => purchaseTypeItem.id === item.purchaseType.id
        );

        if (purchaseType) {
          item.purchaseType.correct = purchaseType.name;
          item.status = InconsistencyErrorStatus.Corrected;
        }
      } else {
        item.purchaseType.correct = '';
        item.status = InconsistencyErrorStatus.Pending;
      }
    });
  }

  public save(): void {
    if (!this.groupInconsistencies) return;

    this.isSpinning = true;
    this.setIdAndCorrect();

    this.inconsistencyService
      .saveInconsistencyGroup(
        this.validationFileId,
        this.groupInconsistencies,
        InconsistencyGroup.PurchaseType
      )
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: result => {
          if (result) {
            this.isSavedUpdates = true;
            this.spreadsheetService.updateInconsistenciesSubject.next();
            this.autoModalTimer = setTimeout(() => {
              this.close();
            }, 5000);
          } else {
            this.isSavedError = true;
          }
        },
        error: () => {
          this.isSpinning = false;
          this.isSavedError = true;
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  public close(): void {
    this.isSpinning = true;
    this.isSavedUpdates = false;
    this.isVisible = false;
    this.disabledSaveBtn = true;
    this.isSavedError = false;
    this.groupInconsistencies = undefined;
    this.purchaseTypeInconsistencies = [];
    this.purchaseTypes = [];
    this.isVisibleModalChange.emit(this.isVisible);
    clearTimeout(this.autoModalTimer);
  }

  public back(): void {
    this.isSavedError = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
