<label class="total py-3">{{
  'PurchaseTypes_Found' | translate : { total: purchaseTypes.length }
}}</label>
<nz-table
  #basicTable
  [nzFrontPagination]="false"
  [nzData]="purchaseTypes"
  [nzLoading]="loading"
  [nzScroll]="{ x: '400px', y: 'calc(100vh - 415px)' }"
>
  <thead>
    <tr>
      <th nzWidth="calc(270px + 13vw)" class="column-header">
        {{ 'PurchaseType' | translate }}
      </th>
      <th nzWidth="calc(220px + 2vw)" class="column-header">{{ 'CreatedBy' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'CreatedAt' | translate }}</th>
      <th nzWidth="130px"></th>
    </tr>
  </thead>
  <tbody class="column-content">
    <tr *ngFor="let purchaseType of purchaseTypes">
      <td class="column-content__media">{{ purchaseType.name }}</td>
      <td>{{ purchaseType.createdBy }}</td>
      <td>{{ purchaseType.createdAt | date : 'dd/MM/yyyy' }}</td>
      <td>
        <div class="d-flex justify-content-end">
          <app-button type="link" (click)="handleDelete(purchaseType)">
            <span class="material-icons-outlined md-24">delete</span>
          </app-button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
