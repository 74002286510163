<nz-pagination
  class="app-pagination"
  [nzPageIndex]="pageIndex"
  [nzPageSize]="pageSize"
  [nzTotal]="total"
  (nzPageIndexChange)="onPageIndexChange($event)"
  (nzPageSizeChange)="onPageSizeChange($event)"
  [nzItemRender]="renderItemTemplate"
  [nzShowTotal]="showTotal ? totalTemplate : null"
  [nzShowSizeChanger]="showSizeChanger"
  [nzPageSizeOptions]="pageSizeOptions"
></nz-pagination>
<ng-template #totalTemplate let-range="range" let-total>
  {{ range[0] }} - {{ range[1] }} {{ 'of' | translate }} {{ total }}
</ng-template>
<ng-template #renderItemTemplate let-type let-page="page">
  <ng-container [ngSwitch]="type" class="app-pagination">
    <p class="page-item" *ngSwitchCase="'page'">{{ page }}</p>
    <p class="arrow-left mb-0" *ngSwitchCase="'prev'">
      <span class="arrow-left-icon material-icons-outlined md-24">arrow_left</span>
    </p>
    <p class="arrow-right mb-0" *ngSwitchCase="'next'">
      <span class="arrow-right-icon material-icons-outlined md-24">arrow_right</span>
    </p>
    <p class="arrow-double-left mb-0" *ngSwitchCase="'prev_5'">
      <span class="arrow-double-left-icon material-icons-outlined md-24">more_horiz</span>
    </p>
    <p class="arrow-double-right mb-0" *ngSwitchCase="'next_5'">
      <span class="arrow-double-right-icon material-icons-outlined md-24">more_horiz</span>
    </p>
  </ng-container>
</ng-template>
