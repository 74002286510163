<nz-spin nzSize="large" [nzSpinning]="isSpinning">
  <nz-table [nzFrontPagination]="false" [nzData]="users">
    <thead>
      <tr>
        <th
          nzCustomFilter
          class="column-header"
          [nzSortFn]="true"
          [nzSortDirections]="sortDirections"
          (nzSortOrderChange)="onChangeOrder($event, 'Email')"
        >
          {{ 'Email' | translate }}
          <nz-filter-trigger
            class="ms-2"
            [(nzVisible)]="isVisibleSearch"
            [nzActive]="searchValue.length > 0"
            [nzDropdownMenu]="menu"
          >
            <span class="material-icons-outlined md-20">search</span>
          </nz-filter-trigger>
        </th>
        <th
          class="column-header"
          [nzFilterMultiple]="true"
          [nzFilterFn]="true"
          [nzFilters]="listOfFilters"
          (nzFilterChange)="changeFilters($event)"
        >
          {{ 'Group' | translate }}
        </th>
        <th
          class="column-header"
          [nzSortFn]="true"
          [nzSortDirections]="sortDirections"
          (nzSortOrderChange)="onChangeOrder($event, 'CreatedAt')"
        >
          {{ 'CreatedAt' | translate }}
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of users">
        <td>{{ data.email }}</td>
        <td>
          <span [ngClass]="'group-tag ' + data.group.name | lowercase">
            {{
              data.squad
                ? (data.group.name | translate) + ' - ' + (data.squad.name | translate)
                : (data.group.name | translate)
            }}
          </span>
        </td>
        <td>
          {{ data.createdAt | date : 'dd/MM/yy HH:mm' }}
        </td>
        <td>
          <div class="d-flex justify-content-end">
            <app-button class="me-2" type="link" (click)="handleEdit(data)">
              <span class="material-icons-outlined md-24">edit</span>
            </app-button>
            <app-button type="link" (click)="handleDelete(data)">
              <span class="material-icons-outlined md-24">delete</span>
            </app-button>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown p-2">
      <input
        type="text"
        nz-input
        placeholder="{{ 'Search_email' | translate }}"
        [(ngModel)]="searchValue"
      />
      <div class="d-flex justify-content-between mt-2">
        <app-button type="link" (click)="onResetSearch()">
          {{ 'Reset' | translate }}
        </app-button>
        <app-button type="primary" (click)="onSearch()">
          {{ 'Search' | translate }}
        </app-button>
      </div>
    </div>
  </nz-dropdown-menu>
  <div class="d-flex flex-row justify-content-center p-4" *ngIf="users.length > 0">
    <app-pagination
      [pageIndex]="data.page"
      [pageSize]="data.pageSize"
      [total]="data.totalRecords"
      [showSizeChanger]="true"
      [showTotal]="true"
      (pageIndexChange)="onPageIndexChange($event)"
      (pageSizeChange)="onPageSizeChange($event)"
    ></app-pagination>
  </div>
</nz-spin>

<app-edit-user
  [isVisible]="isVisibleEditModal"
  [user]="user"
  (isVisibleModalChange)="handleCancel()"
  [groups]="groups"
  [squads]="squads"
></app-edit-user>
