import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '@core/services/message/message.service';
import { UserService } from '@core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupModel, SquadModel, UserCreateUpdateModel, UserModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { UserComponentService } from '../user.component.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isVisible = false;

  @Input() user: UserModel = {} as UserModel;

  @Input() groups: GroupModel[] = [];

  @Input() squads: SquadModel[] = [];

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly destroy$ = new Subject<void>();

  constants = CONSTANTS;

  validationForm!: FormGroup;

  public isSpinning = false;

  public isLoadingData = true;

  public isShowSquad = true;

  public squadGroup: GroupModel | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    public translate: TranslateService,
    private userService: UserService,
    private userComponentService: UserComponentService
  ) {}

  public handleClose(): void {
    this.isVisible = false;
    this.isSpinning = false;
    this.isVisibleModalChange.emit(this.isVisible);
    this.validationForm.reset();

    this.isLoadingData = true;
    this.isShowSquad = true;
  }

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      email: [null, [Validators.required]],
      groupId: [null, [Validators.required]],
      squadId: [null]
    });
  }

  getUser(): void {
    this.isLoadingData = true;
    this.userService
      .getById(this.user.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: user => {
          this.isLoadingData = false;
          this.user = user;
          this.setValues(user);
        },
        error: error => {
          this.isLoadingData = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  handleSave(): void {
    this.isSpinning = true;

    const email = this.validationForm.get('email')?.value;
    const groupId = this.validationForm.get('groupId')?.value;
    let squadId = this.validationForm.get('squadId')?.value;

    if (groupId != this.squadGroup?.id) {
      squadId = null;
    }

    const user: UserCreateUpdateModel = {
      email: email,
      groupId: groupId,
      squadId: squadId
    };

    this.userService
      .update(this.user.id, user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSpinning = false;
          this.handleClose();

          this.userComponentService.updateGrid.next(true);

          this.message.showSuccess(this.translate.instant('Update_saved'));

          this.validationForm.controls['email'].reset();
          this.validationForm.controls['groupId'].reset();
          this.validationForm.controls['squadId'].reset();
        },
        error: error => {
          this.isSpinning = false;
          this.handleClose();
          this.userComponentService.updateGrid.next(true);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  setValues(user: UserModel): void {
    if (user && this.validationForm) {
      this.validationForm.controls['email'].setValue(user.email);
      this.validationForm.controls['email'].disable();
      if (user.group && user.group.id != undefined) {
        this.validationForm.controls['groupId'].setValue(user.group.id);
      }
      this.validationForm.controls['squadId'].setValue(user.squad?.id);
    }
  }

  updateSquadValidation(groupId: string): void {
    if (groupId == this.squadGroup?.id) {
      this.validationForm.controls['squadId'].setValidators([Validators.required]);
      this.isShowSquad = true;
    } else {
      this.validationForm.controls['squadId'].clearValidators();
      this.isShowSquad = false;
    }
    this.validationForm.controls['squadId'].updateValueAndValidity();
  }

  ngChangeGroup(groupId: string): void {
    this.updateSquadValidation(groupId);
  }

  afterOpen(): void {
    this.getUser();
  }

  ngOnChanges(): void {
    if (this.user != undefined) {
      this.setValues(this.user);
    }
    this.squadGroup = this.groups.find(x => x.name == CONSTANTS.GROUP_SQUAD);
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
