import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { PackageService } from '@core/services/package/package.service';
import { PackageCompleteModel } from '@models/package';
import { PackageComponentService } from '@pages/registrations/package/package-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-package-data-view',
  templateUrl: './package-data-view.component.html',
  styleUrls: ['./package-data-view.component.scss']
})
export class PackageDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private packagesData: PackageCompleteModel[] = [];

  loading = false;

  packages: PackageCompleteModel[] = [];

  singlePackage: PackageCompleteModel = {} as PackageCompleteModel;

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private packageService: PackageService,
    private message: MessageService,
    private modalService: ModalService,
    private packageComponentService: PackageComponentService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.packages = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.packages = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): PackageCompleteModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.packagesData
      : this.packagesData.filter(
          _package =>
            StringHelper.includesNormalized(_package.description, termNormalized) ||
            StringHelper.includesNormalized(_package.vehicle?.vehicleName, termNormalized) ||
            StringHelper.includesNormalized(_package.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(_package.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): PackageCompleteModel[] {
    return direction === Ordination.Last
      ? this.packages.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.packages.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.packageService
      .getLastPackages('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (packages: PackageCompleteModel[]) => {
          this.packagesData = packages;
          this.packages = this.search === '' ? packages : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.packageComponentService.updatePackagesGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(packageId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.packageService
        .deletePackage(packageId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Package deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(singlePackage: PackageCompleteModel): void {
    this.singlePackage = singlePackage;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(singlePackage.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
