import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FinancialFileTypes } from '@shared/enums';
import { BannerInformationModel, InconsistenciesModel, RowsModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'src/app/core/services/message/message.service';
import { SpreadsheetService } from '../../components/spreadsheet/spreadsheet.service';
import { InconsistencyService } from '../../core/services/inconsistency/inconsistency.service';

@Component({
  selector: 'app-inconsistencies',
  templateUrl: './inconsistencies.component.html'
})
export class InconsistenciesComponent implements OnInit, OnDestroy {
  public isOnlyInconsistencies = true;

  public currentPageIndex = 1;

  public pageSize = 10;

  public totalRecords = 0;

  redirectIndex = 0;

  public dateRef = new Date();

  public dateRefText = '';

  public fileType = FinancialFileTypes.Unknown;

  public columns: string[] = [];

  public bannerInformation: BannerInformationModel = {
    product: 0,
    campaign: 0,
    media: 0,
    network: 0,
    vehicle: 0,
    package: 0
  };

  public rows: RowsModel[][] = [];

  public isSpinning = false;

  public hasInconsistencies = false;

  public validationFileId = '';

  public dataResult: InconsistenciesModel = {
    fileData: {
      columns: [],
      fileType: FinancialFileTypes.Unknown,
      referenceMonth: 0,
      referenceYear: 0,
      rows: [],
      squadId: '',
      financialFileId: '',
      squadName: '',
      page: 1,
      pageSize: 10,
      totalPages: 0,
      totalRecords: 0
    },
    bannerInformation: {
      product: 0,
      campaign: 0,
      media: 0,
      network: 0,
      vehicle: 0,
      package: 0
    }
  };

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private inconsistencyService: InconsistencyService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private messageService: MessageService,
    private spreadsheetService: SpreadsheetService
  ) {}

  private setDateRefText(): void {
    this.dateRefText = `${
      CONSTANTS.MONTH_NAMES[this.dateRef.getMonth()]
    } ${this.dateRef.getUTCFullYear()}`;
  }

  ngOnInit(): void {
    this.getInconsistencies(this.isOnlyInconsistencies, 1, this.pageSize);
    this.updateInconsistenciesObservable();
  }

  public checkHasInconsistencies(): void {
    this.hasInconsistencies = Object.values(this.bannerInformation).some(value => value > 0);
  }

  private getInconsistencies(isOnlyInconsistencies: boolean, page: number, pageSize: number): void {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.validationFileId = String(routeParams.get('inconsistencyId'));

    this.isSpinning = true;
    this.inconsistencyService
      .getById(this.validationFileId, isOnlyInconsistencies, page, pageSize)
      .subscribe({
        next: result => {
          this.dataResult = result;

          this.dataResult.fileData.rows = result.fileData.rows.map(row => {
            row.forEach((column, index) =>
              index === 0
                ? (column.text = (parseInt(column.text, 10) + 2).toString())
                : (column.text = column.text)
            );
            return row;
          });

          this.bannerInformation = result.bannerInformation;

          const month = this.dataResult.fileData.referenceMonth - 1;
          const year = this.dataResult.fileData.referenceYear;

          this.totalRecords = this.dataResult.fileData.totalRecords;

          this.dateRef = new Date(year, month, 1);
          this.setDateRefText();

          this.fileType = this.dataResult.fileData.fileType;

          if (this.fileType === FinancialFileTypes.Consolidated) {
            this.redirectIndex = 3;
          } else {
            this.redirectIndex = this.fileType - 1;
          }

          this.checkInconsistencies(this.dataResult);

          this.isSpinning = false;
        },
        error: error => {
          this.isSpinning = false;
          this.messageService.showErrorByStatus(error.status);
        }
      });
  }

  public onPageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
    this.getInconsistencies(this.isOnlyInconsistencies, pageIndex, this.pageSize);
  }

  public onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;

    this.currentPageIndex = 1;
    this.getInconsistencies(this.isOnlyInconsistencies, this.currentPageIndex, pageSize);
  }

  public onCheckFilterChange(isOnlyInconsistencies: boolean): void {
    this.isOnlyInconsistencies = isOnlyInconsistencies;

    this.currentPageIndex = 1;
    this.getInconsistencies(this.isOnlyInconsistencies, this.currentPageIndex, this.pageSize);
  }

  private updateInconsistenciesObservable(): void {
    this.isSpinning = true;
    this.spreadsheetService.updateInconsistenciesSubject
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.currentPageIndex = 1;
          this.getInconsistencies(this.isOnlyInconsistencies, this.currentPageIndex, this.pageSize);
          this.isSpinning = false;
        }
      });
  }

  private checkInconsistencies(data: InconsistenciesModel): void {
    this.columns = data.fileData.columns;
    this.rows = data.fileData.rows;
    this.checkHasInconsistencies();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
