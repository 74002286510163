import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { PurchaseTypeService } from '@core/services/purchase-type/purchase-type.service';
import { PurchaseTypeCompleteModel } from '@models/purchase-type';
import { PurchaseTypeComponentService } from '@pages/registrations/purchase-type/purchase-type-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-purchase-type-data-view',
  templateUrl: './purchase-type-data-view.component.html',
  styleUrls: ['./purchase-type-data-view.component.scss']
})
export class PurchaseTypeDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private purchaseTypesData: PurchaseTypeCompleteModel[] = [];

  loading = false;

  purchaseTypes: PurchaseTypeCompleteModel[] = [];

  purchaseType: PurchaseTypeCompleteModel = {} as PurchaseTypeCompleteModel;

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private purchaseTypeService: PurchaseTypeService,
    private message: MessageService,
    private modalService: ModalService,
    private purchaseTypeComponentService: PurchaseTypeComponentService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.purchaseTypes = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.purchaseTypes = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): PurchaseTypeCompleteModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.purchaseTypesData
      : this.purchaseTypesData.filter(
          purchaseType =>
            StringHelper.includesNormalized(purchaseType.name, termNormalized) ||
            StringHelper.includesNormalized(purchaseType.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(purchaseType.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): PurchaseTypeCompleteModel[] {
    return direction === Ordination.Last
      ? this.purchaseTypes.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.purchaseTypes.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.purchaseTypeService
      .getLastPurchaseTypes('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (purchaseTypes: PurchaseTypeCompleteModel[]) => {
          this.purchaseTypesData = purchaseTypes;
          this.purchaseTypes =
            this.search === '' ? purchaseTypes : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.purchaseTypeComponentService.updatePurchaseTypesGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(purchaseTypeId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.purchaseTypeService
        .deletePurchaseType(purchaseTypeId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('PurchaseType deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(purchaseType: PurchaseTypeCompleteModel): void {
    this.purchaseType = purchaseType;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(purchaseType.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
