<div class="container-fluid">
  <nz-spin nzSize="large" [nzSpinning]="isSpinningForm || isSpinningTable">
    <div nz-col class="cm-page-title">
      {{ 'Registration' | translate }}
    </div>

    <div nz-col class="cm-page-subtitle">
      {{ 'Vehicle' | translate }}
    </div>

    <nz-card>
      <app-vehicle-form></app-vehicle-form>
      <p class="card-text">
        {{ 'Last_vehicles' | translate }}
      </p>
      <app-vehicle-grid></app-vehicle-grid>
    </nz-card>
    <app-button class="redirect-button mt-2" type="link" (click)="navigate()">
      {{ 'See_all_vehicles' | translate }}
    </app-button>
  </nz-spin>
</div>
