import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { VehicleCompleteModel, VehicleModel } from '@models/vehicle';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(private http: HttpClient) {}

  public getAllVehicles(): Observable<VehicleModel[]> {
    const route = `${baseWebApiURL}/v1/Vehicle`;

    return this.http.get<VehicleModel[]>(route);
  }

  public listVehicles(
    columnToOrder: string,
    descendingDirection: boolean,
    length?: number
  ): Observable<VehicleCompleteModel[]> {
    const route = `${baseWebApiURL}/v1/Vehicle/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}&length=${length}`;

    return this.http.get<VehicleCompleteModel[]>(route);
  }

  public saveVehicle(
    mediaId: string,
    networkId: string,
    vehicleName: string
  ): Observable<VehicleModel> {
    return this.http.post<VehicleModel>(
      `${baseWebApiURL}/v1/Vehicle?mediaId=${mediaId}&networkId=${networkId}&name=${vehicleName}`,
      null
    );
  }

  public deleteVehicle(vehicleId: string): Observable<VehicleModel> {
    return this.http.delete<VehicleModel>(
      `${baseWebApiURL}/v1/Vehicle/${vehicleId}`
    );
  }
}
