import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { SystemSettingModel, SystemSettingRequest } from '@models/systemSetting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(private http: HttpClient) {}

  public getAll(): Observable<SystemSettingModel[]> {
    return this.http.get<SystemSettingModel[]>(`${baseWebApiURL}/v1/SystemSetting`);
  }

  public getByKey(key: string): Observable<SystemSettingModel[]> {
    return this.http.get<SystemSettingModel[]>(`
    ${baseWebApiURL}/v1/SystemSetting/GetByKey?key=${key}`, );
  }

  public create(
    body: SystemSettingRequest[]
  ): Observable<object> {
    return this.http.post(
      `${baseWebApiURL}/v1/SystemSetting`,
      body
    );
  }
}