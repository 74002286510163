<form nz-form [formGroup]="validationForm">
  <div class="filter-card">
    <nz-row nzGutter="16">
      <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="d-block mb-2">
          <label class="fw-bold mb-1">{{ 'Product' | translate }}</label>
          <nz-form-control [nzSpan]="24">
            <nz-select
              nzShowSearch
              nzAllowClear
              formControlName="product"
              nzLimit="1"
              (ngModelChange)="onProductChange($event)"
              nzPlaceHolder="{{ 'Select_product' | translate }}"
            >
              <nz-option
                *ngFor="let product of productsFilter"
                [nzValue]="product.value"
                [nzLabel]="product.label"
              >
              </nz-option>
            </nz-select>
          </nz-form-control>
        </div>
      </nz-col>
      <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="d-block mb-2">
          <label class="fw-bold mb-1">{{ 'Campaign' | translate }}</label>
          <nz-form-control [nzSpan]="24">
            <nz-select
              nzShowSearch
              nzAllowClear
              formControlName="campaign"
              nzLimit="1"
              nzPlaceHolder="{{ 'Select_campaign' | translate }}"
            >
              <nz-option
                *ngFor="let campaign of campaignsFilter"
                [nzValue]="campaign.value"
                [nzLabel]="campaign.label"
              >
              </nz-option>
            </nz-select>
          </nz-form-control>
        </div>
      </nz-col>
      <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="d-block mb-2">
          <label class="fw-bold mb-1">{{ 'Media' | translate }}</label>
          <nz-form-control [nzSpan]="24">
            <nz-select
              nzShowSearch
              nzAllowClear
              formControlName="media"
              nzLimit="1"
              (ngModelChange)="onMediaChange($event)"
              nzPlaceHolder="{{ 'Select_media' | translate }}"
            >
              <nz-option
                *ngFor="let media of mediasFilter"
                [nzValue]="media.value"
                [nzLabel]="media.label"
              >
              </nz-option>
            </nz-select>
          </nz-form-control>
        </div>
      </nz-col>
      <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="d-block mb-2">
          <label class="fw-bold mb-1">{{ 'Vehicle' | translate }}</label>
          <nz-form-control [nzSpan]="24">
            <nz-select
              nzShowSearch
              nzAllowClear
              formControlName="vehicle"
              nzLimit="1"
              nzPlaceHolder="{{ 'Select_vehicle' | translate }}"
            >
              <nz-option
                *ngFor="let vehicle of vehiclesFilter"
                [nzValue]="vehicle.value"
                [nzLabel]="vehicle.label"
              >
              </nz-option>
            </nz-select>
          </nz-form-control>
        </div>
      </nz-col>
    </nz-row>
    <nz-row nzGutter="16" class="d-flex justify-content-between">
      <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="d-block">
          <label class="fw-bold mb-1">{{ 'PI' | translate }}</label>
          <nz-form-control [nzSpan]="24">
            <input
              nz-input
              formControlName="nro_pi"
              placeholder="{{ 'Enter_nroPi' | translate }}"
            />
          </nz-form-control>
        </div>
      </nz-col>
      <nz-col
        class="d-flex justify-content-end align-items-end"
        [nzXs]="{ span: 24 }"
        [nzLg]="{ span: 6 }"
      >
        <div class="d-flex justify-content-end mt-2">
          <app-button type="link" (click)="cleanForm()" [disabled]="!atLeastOneValueSelected">
            {{ 'Clear_filters' | translate }}
          </app-button>
          <app-button
            class="ms-2"
            type="primary"
            [disabled]="!validationForm.valid"
            (click)="filter()"
          >
            {{ 'Filter' | translate }}
          </app-button>
        </div>
      </nz-col>
    </nz-row>
  </div>
</form>
