import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MediaService } from '@core/services/media/media.service';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { MediaModel } from '@models/media';
import { MediaComponentService } from '@pages/registrations/media/media-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-media-data-view',
  templateUrl: './media-data-view.component.html',
  styleUrls: ['./media-data-view.component.scss']
})
export class MediaDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private mediasData: MediaModel[] = [];

  loading = false;

  medias: MediaModel[] = [];

  media: MediaModel = {} as MediaModel;

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private mediaService: MediaService,
    private message: MessageService,
    private mediaComponentService: MediaComponentService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.medias = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.medias = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): MediaModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.mediasData
      : this.mediasData.filter(
          media =>
            StringHelper.includesNormalized(media.mediaName, termNormalized) ||
            StringHelper.includesNormalized(media.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(media.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): MediaModel[] {
    return direction === Ordination.Last
      ? this.medias.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.medias.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.mediaService
      .getOrderedMedias('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (medias: MediaModel[]) => {
          this.mediasData = medias;
          this.medias = this.search === '' ? medias : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.mediaComponentService.updateMediasGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(mediaId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.mediaService
        .deleteMedia(mediaId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Media deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(media: MediaModel): void {
    this.media = media;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(media.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
