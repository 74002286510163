import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CommitmentComponentService {
  public updateCommitmentsGrid = new Subject<boolean>();

  public updateNetworks = new Subject<boolean>();

  public isFormLoading = new Subject<boolean>();

  public isTableLoading = new Subject<boolean>();
}
