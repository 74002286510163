import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignService } from '@core/services/campaign/campaign.service';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { CampaignCompleteModel } from '@models/campaign';
import { Subject, takeUntil } from 'rxjs';
import { CampaignComponentService } from '../campaign-component.service';

@Component({
  selector: 'app-campaign-grid',
  templateUrl: './campaign-grid.component.html',
  styleUrls: ['./campaign-grid.component.scss']
})
export class CampaignGridComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  campaigns: CampaignCompleteModel[] = [];

  campaign: CampaignCompleteModel = {} as CampaignCompleteModel;

  isLoadingDelete = false;

  constructor(
    private campaignService: CampaignService,
    private campaignComponentService: CampaignComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTable();
  }

  getData(): void {
    this.campaignComponentService.isTableLoading.next(true);
    this.campaignService
      .getCampaigns('CreatedAt', true, 5)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (campaigns: CampaignCompleteModel[]) => {
          this.campaigns = campaigns;
          this.campaignComponentService.isTableLoading.next(false);
        },
        error: error => {
          this.campaignComponentService.isTableLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.campaignComponentService.updateCampaignGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(campaignId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.campaignService
        .deleteCampaign(campaignId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Campaign deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(campaign: CampaignCompleteModel): void {
    this.campaign = campaign;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(campaign.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
