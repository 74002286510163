import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
  @Input() type: 'primary' | 'default' | 'link' | 'link-secondary' | 'success' = 'default';

  @Input() class: string | null = null;

  @Input() icon: string | null = null;

  @Input() disabled = false;

  @Input() loading = false;

  @Output() clickEmitter = new EventEmitter<Event>();

  onClick(event: Event): void {
    this.clickEmitter.emit(event);
  }
}
