export enum ConsolidatedStatus {
  None = -1,
  Validated = 0,
  Waiting = 1,
  InQueue = 2,
  Generated = 3,
  Approved = 4,
  Rejected = 5,
  Error = 9
}
