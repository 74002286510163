import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ConsolidatedService } from '@core/services/consolidated/consolidated.service';
import { CustomConsolidatedService } from '@core/services/custromConsolidated/custromConsolidated.service';
import { FinancialFileService } from '@core/services/financial-file/financial-file.service';
import { ModalService } from '@core/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ConsolidatedStatus, FinancialFileTypes } from '@shared/enums';
import {
  CustomConsolidatedFilters,
  FinancialFileModel,
  GenerateCustomConsolidatedRequest
} from '@shared/models';
import { SelectModel } from '@shared/models/select';
import { getContainerFinancialFileTypes } from '@shared/utils/utils';
import { capitalize, startCase } from 'lodash';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { MessageService } from 'src/app/core/services/message/message.service';

type ItemForm = {
  id: number;
  column: string | null;
  values: SelectModel[];
  selectedValues: string[];
  isLoadingValues: boolean;
  isFiltrable: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let require: any;
const FileSaver = require('file-saver');
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public isSpinning = false;

  public isDownloading = false;

  public dateRef = new Date();

  private financialFiles: FinancialFileModel[] = [];

  public financialFile = new FinancialFileModel();

  public consolidatedColumns: SelectModel[] = [];

  public validationForm!: FormArray;

  public listOfControls: Array<ItemForm> = [];

  constructor(
    public translate: TranslateService,
    public financialFileService: FinancialFileService,
    public consolidatedService: ConsolidatedService,
    public customConsolidatedService: CustomConsolidatedService,
    public message: MessageService,
    private modalService: ModalService,
    private fb: FormBuilder
  ) {}

  private getConsolidatedColumns(): void {
    this.isSpinning = true;
    this.consolidatedService
      .getConsolidatedColumns()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: data => {
          this.isSpinning = false;
          this.consolidatedColumns = data.map(item => ({
            ...item,
            checked: true
          }));
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  getSelectedCheckboxes(): string[] {
    const selectedCheckboxes = this.consolidatedColumns
      .filter(column => column.checked)
      .map(column => column.value);

    return selectedCheckboxes;
  }

  private getGeneralConsolidated(): void {
    this.isSpinning = true;
    this.financialFileService
      .get(null, FinancialFileTypes.Consolidated, ConsolidatedStatus.Generated, true)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: data => {
          this.isSpinning = false;
          this.financialFiles = data.financialFiles;

          const lastFile = this.financialFiles[this.financialFiles.length - 1];

          if (lastFile) {
            this.financialFile = lastFile;
            this.dateRef = new Date(lastFile.referenceYear, lastFile.referenceMonth - 1, 1);
            this.addFields();
          }
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  shouldDisableExportButton(): boolean {
    return (
      !this.financialFile.id ||
      !this.validationForm.valid ||
      this.isDownloading ||
      !this.consolidatedColumns.some(column => column.checked)
    );
  }

  private getDownloadFileName = (file: FinancialFileModel): string => {
    const fileType = capitalize(getContainerFinancialFileTypes(file.fileType));

    const month = String(file.referenceMonth).padStart(2, '0');

    const year = file.referenceYear;

    const squadName = startCase(file?.squad?.squadName.toLowerCase()).replace(/ /g, '_');

    let filename = `Report_${fileType}_General_${month}_${year}.xlsx`;

    if (squadName) {
      filename = `Report_${fileType}_${squadName}_${month}_${year}.xlsx`;
    }

    return filename;
  };

  private updateHiddenColumns(): void {
    this.consolidatedColumns.forEach(c => {
      c.isHidden = this.listOfControls.some(item => item?.column?.includes(c.value));
    });
  }

  private addFields(): void {
    this.listOfControls = [];
    this.validationForm = this.fb.array([]);
  }

  private createControl(item: ItemForm): AbstractControl {
    return this.fb.group({
      column: [item.column, [Validators.required]],
      values: [item.values],
      selectedValues: [item.selectedValues, [Validators.required]],
      isLoadingValues: [item.isLoadingValues],
      isFiltrable: [item.isFiltrable]
    });
  }

  onValuesChange(selectedValues: string[], item: ItemForm): void {
    const index = this.listOfControls.indexOf(item);

    if (index > -1) {
      const element = this.listOfControls[index];

      element.selectedValues = selectedValues;

      this.listOfControls[index] = element;
      this.validationForm.controls[index].patchValue(element);

      this.removeFieldsAfterIndex(index);
    }
  }

  removeFieldsAfterIndex(index: number): void {
    this.listOfControls = this.listOfControls.filter((_, i) => i <= index);
    this.validationForm = this.fb.array(this.listOfControls.map(item => this.createControl(item)));
    this.updateHiddenColumns();
  }

  async onColumnChange(value: string, item: ItemForm): Promise<void> {
    this.updateHiddenColumns();
    const index = this.listOfControls.indexOf(item);

    if (index > -1) {
      const element = this.listOfControls[index];
      element.selectedValues = [];
      element.values = [];
      this.validationForm.controls[index].patchValue(element);

      if (value) {
        const [column, isFiltrableString] = value.split('-');
        const isFiltrable = isFiltrableString === 'true';

        element.isFiltrable = isFiltrable;

        if (isFiltrable) {
          element.isLoadingValues = true;

          const filters: CustomConsolidatedFilters[] = this.listOfControls
            .filter(control => control.column)
            .map(control => ({
              column: control.column?.split('-')[0] || '',
              values: control.selectedValues
            }));

          element.values = await lastValueFrom(
            this.consolidatedService
              .getConsolidatedDistinctValues(this.financialFile.id, column, filters)
              .pipe(takeUntil(this._destroying$))
          );

          element.isLoadingValues = false;
        }
      } else {
        element.column = null;
        element.isFiltrable = false;
      }

      this.listOfControls[index] = element;
      this.validationForm.controls[index].patchValue(element);
    }
  }

  onAddField(e: MouseEvent): void {
    e.preventDefault();

    const id =
      this.listOfControls.length > 0
        ? this.listOfControls[this.listOfControls.length - 1].id + 1
        : 0;

    const element = {
      id,
      column: null,
      values: [],
      selectedValues: [],
      isLoadingValues: false,
      isFiltrable: false
    };

    this.listOfControls.push(element);
    this.validationForm.push(this.createControl(element));
  }

  onRemoveField(e: MouseEvent, item: ItemForm): void {
    e.preventDefault();

    if (this.listOfControls.length > 0) {
      const index = this.listOfControls.indexOf(item);

      this.listOfControls.splice(index, 1);
      this.validationForm.removeAt(index);
    }

    this.updateHiddenColumns();
  }

  onChangeDateReference(currentDate: Date): void {
    this.dateRef = currentDate;
    const currentFile = this.financialFiles.find(
      item =>
        item.referenceMonth === currentDate.getMonth() + 1 &&
        item.referenceYear === currentDate.getFullYear()
    );

    if (currentFile) {
      this.financialFile = currentFile;
      this.addFields();
    }
  }

  onExport(): void {
    this.isDownloading = true;
    const downloadFileName = this.getDownloadFileName(this.financialFile);

    const body: GenerateCustomConsolidatedRequest = {
      columns: this.getSelectedCheckboxes(),
      filters: this.validationForm.value.map((item: ItemForm) => ({
        column: item.column?.split('-')[0],
        values: item.selectedValues
      }))
    };

    this.customConsolidatedService
      .exportReport(this.financialFile.id, body)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: blob => {
          this.isDownloading = false;
          FileSaver.saveAs(blob, downloadFileName);
          this.message.showSuccess('Report exported successfully');
        },
        error: async result => {
          const jsonError = await new Response(result.error).json();
          this.isDownloading = false;
          this.message.showError(jsonError?.detail);
        }
      });
  }

  onNewReport(): void {
    this.listOfControls = [];
    this.validationForm = this.fb.array([], [Validators.required]);
  }

  disabledDate = (current: Date): boolean => {
    const hasFile = this.financialFiles.find(
      item =>
        item.referenceMonth === current.getMonth() + 1 &&
        item.referenceYear === current.getFullYear()
    );

    if (hasFile) {
      return false;
    }

    return true;
  };

  ngOnInit(): void {
    this.getConsolidatedColumns();
    this.getGeneralConsolidated();
    this.validationForm = this.fb.array([], [Validators.required]);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
