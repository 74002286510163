import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import {
  GetAllUsersResponse,
  UserCreateUpdateModel,
  UserModel,
  UserOktaModel
} from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constants = CONSTANTS;

  constructor(private http: HttpClient) {}

  public getById(id?: string): Observable<UserModel> {
    const route = `${baseWebApiURL}/v1/User/${id}`;
    return this.http.get<UserModel>(route);
  }

  public getByEmailOkta(email?: string): Observable<UserOktaModel[]> {
    const route = `${baseWebApiURL}/v1/User/${email}/okta`;
    return this.http.get<UserOktaModel[]>(route);
  }

  public getAll(
    columnToOrder?: string,
    isDescendingDirection?: boolean,
    page?: number,
    pageSize?: number,
    groups?: string[],
    search?: string
  ): Observable<GetAllUsersResponse> {
    const columnToOrderParam = columnToOrder ? `&columnToOrder=${columnToOrder}` : '';
    const isDescendingDirectionParam = isDescendingDirection
      ? `&isDescendingDirection=${isDescendingDirection}`
      : '';
    const pageParam = page ? `&page=${page}` : '';
    const pageSizeParam = pageSize ? `&pageSize=${pageSize}` : '';
    const groupsParam = groups && groups?.length > 0 ? `&groups=${groups}` : '';
    const searchParam = search ? `&search=${search}` : '';

    const route = `${baseWebApiURL}/v1/User?${columnToOrderParam}${isDescendingDirectionParam}${pageParam}${pageSizeParam}${groupsParam}${searchParam}`;

    return this.http.get<GetAllUsersResponse>(route);
  }

  public create(user: UserCreateUpdateModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${baseWebApiURL}/v1/User`, user);
  }

  public update(id: string, user: UserCreateUpdateModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${baseWebApiURL}/v1/User/${id}`, user);
  }

  public delete(id: string): Observable<UserModel> {
    return this.http.delete<UserModel>(`${baseWebApiURL}/v1/User/${id}`);
  }
}
