import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { baseWebApiURL } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  downloadFile(financialFIleId: string): Observable<Blob> {
    const url = `${baseWebApiURL}/v1/FinancialFile/download?financialFileId=${financialFIleId}`;

    const token = this.configService.getToken();

    if (!token) throw new Error('Token inválido');

    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
