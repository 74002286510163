import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { Observable } from 'rxjs';
import { CommitmentCompleteModel, CommitmentModel } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class CommitmentService {
  constructor(private http: HttpClient) {}

  public getCommitments(
    columnToOrder?: string,
    isDescendingDirection?: boolean,
    length?: number
  ): Observable<CommitmentCompleteModel[]> {
    const columnToOrderParam = columnToOrder ? `&columnToOrder=${columnToOrder}` : '';
    const isDescendingDirectionParam = isDescendingDirection
      ? `&isDescendingDirection=${isDescendingDirection}`
      : '';
    const lengthParam = length ? `&length=${length}` : '';

    const route = `${baseWebApiURL}/v1/Commitment?${columnToOrderParam}${isDescendingDirectionParam}${lengthParam}`;

    return this.http.get<CommitmentCompleteModel[]>(route);
  }

  public saveCommitment(commitment: CommitmentModel): Observable<CommitmentCompleteModel> {
    return this.http.post<CommitmentCompleteModel>(`${baseWebApiURL}/v1/Commitment`, commitment);
  }

  public updateCommitment(
    commitmentId: string,
    commitment: CommitmentModel
  ): Observable<CommitmentCompleteModel> {
    return this.http.put<CommitmentCompleteModel>(
      `${baseWebApiURL}/v1/Commitment/${commitmentId}`,
      commitment
    );
  }

  public deleteCommitment(commitmentId: string): Observable<CommitmentCompleteModel> {
    return this.http.delete<CommitmentCompleteModel>(
      `${baseWebApiURL}/v1/Commitment/${commitmentId}`
    );
  }
}
