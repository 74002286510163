import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { Observable } from 'rxjs';
import { PackageCompleteModel, PackageModel } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  constructor(private http: HttpClient) {}

  public getPackages(): Observable<PackageCompleteModel[]> {
    return this.http.get<PackageCompleteModel[]>(`${baseWebApiURL}/v1/Packages`);
  }

  public getLastPackages(
    columnToOrder: string,
    descendingDirection: boolean,
    length?: number
  ): Observable<PackageCompleteModel[]> {
    const lengthParam = length ? `&length=${length}` : '';

    const route = `${baseWebApiURL}/v1/Packages/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}${lengthParam}`;

    return this.http.get<PackageCompleteModel[]>(route);
  }

  public savePackage(packageName: string, vehicleId?: number): Observable<PackageModel> {
    const vehicleIdParam = vehicleId ? `&vehicleId=${vehicleId}` : '';
    return this.http.post<PackageModel>(
      `${baseWebApiURL}/v1/Packages?packageName=${packageName}${vehicleIdParam}`,
      null
    );
  }

  public deletePackage(packageId: string): Observable<PackageModel> {
    return this.http.delete<PackageModel>(
      `${baseWebApiURL}/v1/Packages/${packageId}`
    );
  }
}
