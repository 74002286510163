import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { PurchaseTypeService } from '@core/services/purchase-type/purchase-type.service';
import { PurchaseTypeCompleteModel } from '@models/purchase-type';
import { Subject, takeUntil } from 'rxjs';
import { PurchaseTypeComponentService } from '../purchase-type-component.service';

@Component({
  selector: 'app-purchase-type-grid',
  templateUrl: './purchase-type-grid.component.html',
  styleUrls: ['./purchase-type-grid.component.scss']
})
export class PurchaseTypeGridComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  purchaseTypes: PurchaseTypeCompleteModel[] = [];

  purchaseType: PurchaseTypeCompleteModel = {} as PurchaseTypeCompleteModel;

  isLoadingDelete = false;

  constructor(
    private purchaseTypeService: PurchaseTypeService,
    private purchaseTypeComponentService: PurchaseTypeComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTable();
  }

  getData(): void {
    this.purchaseTypeComponentService.isTableLoading.next(true);
    this.purchaseTypeService
      .getLastPurchaseTypes('CreatedAt', true, 5)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (PurchaseTypes: PurchaseTypeCompleteModel[]) => {
          this.purchaseTypes = PurchaseTypes;
          this.purchaseTypeComponentService.isTableLoading.next(false);
        },
        error: error => {
          this.purchaseTypeComponentService.isTableLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.purchaseTypeComponentService.updatePurchaseTypesGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(purchaseTypeId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.purchaseTypeService
        .deletePurchaseType(purchaseTypeId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('PurchaseType deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(purchaseType: PurchaseTypeCompleteModel): void {
    this.purchaseType = purchaseType;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(purchaseType.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
