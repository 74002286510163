<div class="container-fluid">
  <nz-spin nzSize="large" [nzSpinning]="isSpinningForm || isSpinningTable">
    <div nz-col class="cm-page-title">
      {{ 'Registration' | translate }}
    </div>

    <div nz-col class="cm-page-subtitle">
      {{ 'Network' | translate }}
    </div>

    <nz-card>
      <app-network-form></app-network-form>
      <p class="card-text">{{ 'Last_networks' | translate }}</p>
      <app-network-grid></app-network-grid>
    </nz-card>
    <app-button class="redirect-button mt-2" type="link" (click)="navigate()">
      {{ 'See_all_networks' | translate }}
    </app-button>
  </nz-spin>
</div>
