import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { baseWebApiURL } from '@env';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthHeaderToAllowedOrigins(request));
  }

  private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let req = request;

    let authToken;
    const allowedOrigins = [baseWebApiURL];
    if (!!allowedOrigins.find(origin => request.url.includes(origin))) {
      authToken = this._oktaAuth.getAccessToken();

      req = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` }
      });
    }
    return req;
  }
}
