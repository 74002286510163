import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MediaService } from '@core/services/media/media.service';
import { MessageService } from '@core/services/message/message.service';
import { VehicleService } from '@core/services/vehicle/vehicle.service';
import { MediaModel } from '@models/media';
import { NetworkModel } from '@models/network';
import { TranslateService } from '@ngx-translate/core';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VehicleComponentService } from '../vehicle-component.service';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  medias: MediaModel[] = [];

  networks: NetworkModel[] = [];

  disabledSaveBtn = true;

  hasMedia = true;

  isSpinning = false;

  autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
    private message: MessageService,
    private vehicleService: VehicleService,
    private vehicleComponentService: VehicleComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.validationForm = this.formBuilder.group({
      media: [null, [Validators.required]],
      network: [null, [Validators.required]],
      vehicleName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
  }

  getData(): void {
    this.vehicleComponentService.isFormLoading.next(true);
    this.mediaService
      .getMedias()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: medias => {
          this.medias = medias;
          this.vehicleComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.vehicleComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  cleanForm(): void {
    this.validationForm.controls['media'].setValue('');
    this.validationForm.controls['media'].reset();
    this.validationForm.controls['network'].setValue('');
    this.validationForm.controls['network'].reset();
    this.validationForm.controls['vehicleName'].setValue('');
    this.validationForm.controls['vehicleName'].reset();
    this.hasMedia = true;
  }

  save(): void {
    this.vehicleComponentService.isFormLoading.next(true);

    const mediaId = this.validationForm.get('media')?.value;
    const networkId = this.validationForm.get('network')?.value;
    const vehicleName = this.validationForm.get('vehicleName')?.value;

    this.vehicleService
      .saveVehicle(mediaId, networkId, vehicleName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['vehicleName'].reset();

          this.getData();

          this.vehicleComponentService.updateVehiclesGrid.next(true);

          this.vehicleComponentService.isFormLoading.next(false);

          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.vehicleComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  changeMedia(mediaId: string): void {
    if (mediaId) {
      this.validationForm.controls['network'].setValue('');
      this.networks = this.medias.find(media => media.id == mediaId)?.networks ?? [];
      this.hasMedia = false;
    } else this.hasMedia = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
