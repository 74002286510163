<div class="container-fluid">
  <div nz-col class="cm-page-title">
    {{ 'Settings' | translate }}
  </div>
  <nz-tabset (nzSelectChange)="changeTab($event.index)" [nzSelectedIndex]="activeTab">
    <nz-tab nz-tab-link [routerLink]="['.']" nzTitle="{{ 'Add user' | translate }}">
      <app-add-user [groups]="groups" [squads]="squads"></app-add-user>
    </nz-tab>
    <nz-tab nz-tab-link [routerLink]="['.']" nzTitle="{{ 'Users' | translate }}">
      <app-list-users [groups]="groups" [squads]="squads"></app-list-users>
    </nz-tab>
  </nz-tabset>
</div>
