<div class="file-card">
  <nz-card [nzBordered]="false">
    <div class="cardTilte">
      <span class="cardTilte--title">
        <span>
          <b>{{ 'DMI' | translate }}</b></span
        >
      </span>
      <span class="font-size-16 mb-2">
        <span>{{ 'Month' | translate }}:</span>
        <b class="font-size-16 text-capitalize"> {{ displayDate }}</b>
      </span>
    </div>
    <div class="d-flex file-card--steps">
      <div class="col-12 col-lg-9 mb-3">
        <nz-steps [nzCurrent]="step" [nzProgressDot]="progressTemplate">
          <nz-step [nzTitle]="upload"> </nz-step>
        </nz-steps>
        <ng-template #upload>
          {{ 'Upload_step' | translate }}
          <span
            nz-tooltip
            nzTooltipTitle="{{ 'By' | translate }}: {{ this.dmiFile.updatedBy }}, {{
              'in' | translate
            }}: {{ this.dmiFile.updatedAt | date : 'dd/MM/yyyy HH:mm' }}"
          >
            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          </span>
        </ng-template>

        <ng-template #progressTemplate let-dot let-status="status" let-index="index">
          <span style="margin-left: -100%">
            <ng-template [ngTemplateOutlet]="dot"></ng-template>
          </span>
        </ng-template>
      </div>
      <div class="col-12 col-lg-3">
        <div class="d-flex justify-content-end">
          <app-button (click)="downloadFile(dmiFile)" type="default" [loading]="isDownloading">
            <span class="material-icons-outlined md-24">file_download</span>
          </app-button>
        </div>
      </div>
    </div>
  </nz-card>
</div>
