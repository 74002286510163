<div nz-row>
  <div nz-col nzSpan="24" class="mb-2">
    <label
      nz-checkbox
      [(ngModel)]="checkFilter"
      (ngModelChange)="checkFilterChange.emit(checkFilter)"
      >{{ 'Show_error_lines' | translate }}</label
    >
  </div>
  <div nz-col nzSpan="24" class="mb-4 d-flex flex-grow-1" *ngIf="quantInconsistencies === 0">
    <div class="icon-banner-inconsistencies success">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
    </div>
    <div class="message-banner-inconsistencies px-3">
      <div class="all-inconsistencies-corrected">
        <div class="resume-inconsistencies">
          {{ 'The_inconsistencies_in_this_worksheet_have_been_corrected' | translate }}
        </div>
        <div>
          <a
            class="go-to-approve-corrections"
            routerLink="/files"
            [queryParams]="{
              active_tab: fileType === FinancialFileTypes.MediaHub ? 2 : fileType - 1,
              open_approve: validationFileId,
              ref_date: dateRef.toISOString().substring(0, 10)
            }"
          >
            {{ 'Go_to_approve_corrections' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div nz-col nzSpan="24" class="mb-4" *ngIf="quantInconsistencies > 0">
    <div class="icon-banner-inconsistencies">
      <i nz-icon nzType="info-circle" nzTheme="outline"></i>
    </div>
    <div class="message-banner-inconsistencies justify-content-between px-3">
      <div *ngIf="!resumeInconsistencies" class="resume-inconsistencies">
        {{ 'Number_of_errors_found_in_the_spreadsheet' | translate }}
        <b class="text-danger text-decoration-underline">{{ quantInconsistencies }}</b>
      </div>

      <div *ngIf="resumeInconsistencies" class="mt-3 mb-2">
        <ul *ngFor="let item of bannerInformation | keyvalue" class="mb-1">
          <li *ngIf="item.value > 0">
            {{ 'In_column' | translate }}
            <b class="text-capitalize">{{
              item.key === 'purchaseType' &&
              (fileType === FinancialFileTypes.Planned || fileType === FinancialFileTypes.MediaHub)
                ? ('PurchaseTypeColPlanned' | translate)
                : item.key === 'purchaseType' && fileType === FinancialFileTypes.Executed
                ? ('PurchaseType' | translate)
                : (item.key.charAt(0).toUpperCase() + item.key.substring(1) | translate)
            }}</b>
            {{ (item.value === 1 ? 'was_found' : 'were_found') | translate }}
            {{ item.value }}
            {{ (item.value === 1 ? 'inconsistency' : 'inconsistencies') | translate }}.
          </li>
        </ul>
      </div>
      <div class="btn-toggle-inconsistencies" (click)="toggleInconsistencies()">
        <i nz-icon nzType="down" nzTheme="outline" *ngIf="!resumeInconsistencies"></i>
        <i class="up" nz-icon nzType="up" nzTheme="outline" *ngIf="resumeInconsistencies"></i>
      </div>
    </div>
  </div>
</div>
