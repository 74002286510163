<nz-spin nzSize="large" [nzSpinning]="isSpinning">
  <div class="container-fluid">
    <div nz-col class="cm-page-title d-flex cm-botoes">
      {{ 'Rules_from_to' | translate }}
      <div class="d-flex justify-content-end ms-3 col-gap">
        <app-button
          (click)="showMultipleExclusionModal(rulesIdsToDelete)"
          [disabled]="!isButtonEnabled"
          >{{ 'Delete_selected' | translate }}</app-button
        >
        <app-button
          type="primary"
          nz-button
          nz-tooltip
          nzTooltipTitle="{{ 'Add' | translate }}"
          (click)="showFromToModal()"
          ><span nz-icon nzType="plus" nzTheme="outline"></span
        ></app-button>
      </div>
    </div>
    <app-rules-grid
      [updateTable]="updateTable"
      (sendSpinningData)="getSpinningValue($event)"
      (deleteButtonIsEnabled)="checkButtonEnabled($event)"
      (rulesDataToDelete)="(rulesIdsToDelete)"
    ></app-rules-grid>
  </div>
  <app-modal-new-from-to
    [isVisible]="isVisibleModalFromTo"
    (isVisibleModalChange)="isVisibleModalFromTo = $event"
    (ruleAdded_UpdateTable)="shouldUpdateTable($event)"
  ></app-modal-new-from-to>
</nz-spin>
