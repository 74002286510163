import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ComponentsModule } from '../../../components/components.module';
import { CommitmentDataViewComponent } from '../../../components/data-view/commitment-data-view/commitment-data-view.component';
import { SharedModule } from '../../../shared/shared.module';
import { CommitmentComponentService } from './commitment-component.service';
import { CommitmentEditComponent } from './commitment-edit/commitment-edit.component';
import { CommitmentFormComponent } from './commitment-form/commitment-form.component';
import { CommitmentGridComponent } from './commitment-grid/commitment-grid.component';
import { CommitmentComponent } from './commitment.component';

@NgModule({
  declarations: [
    CommitmentComponent,
    CommitmentFormComponent,
    CommitmentEditComponent,
    CommitmentGridComponent,
    CommitmentDataViewComponent
  ],
  exports: [
    CommitmentComponent,
    CommitmentFormComponent,
    CommitmentEditComponent,
    CommitmentGridComponent,
    CommitmentDataViewComponent
  ],
  providers: [CommitmentComponentService],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    RouterModule,
    FormsModule,
    NzLayoutModule,
    NzIconModule,
    NzModalModule,
    NzGridModule,
    NzRadioModule,
    NzCheckboxModule,
    NzSpinModule,
    NzCardModule,
    NzDividerModule,
    NzTableModule,
    NzFormModule,
    ReactiveFormsModule,
    TranslateModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzEmptyModule,
    CurrencyMaskModule
  ]
})
export class CommitmentModule {}
