export const CONSTANTS = {
  GROUP_SQUAD: 'Squad',
  MONTH_NAMES: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  EXCEL_FILE_TYPE: '.xlsx',
  ALLOWED_FILE_TYPES: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  UPLOAD_TYPE_MEDIA_HUB: 'Media Hub',
  UPLOAD_TYPE_PLANNED: 'Planejado',
  UPLOAD_TYPE_EXECUTED: 'Realizado',
  UPLOAD_TYPE_DMI: 'DMI',
  FILE_EXECUTED: 'realizado.xlsx',
  FILE_PLANNED: 'planejado.xlsx',
  FILE_CONSOLIDATED: 'consolidado.xlsx',
  METABASE_EMBEDDING_SECRET_KEY: 'METABASE_EMBEDDING_SECRET_KEY',
  METABASE_SITE_URL: 'METABASE_SITE_URL',
  METABASE_DASHBOARD_ID: 'METABASE_DASHBOARD_ID',
  METABASE_DASHBOARD_COMMITMENT_ID: "METABASE_DASHBOARD_COMMITMENT_ID",
  INCONSISTENCIES_PLANNED_WIDTH_COLS: [
    '60px',
    '100px',
    '120px',
    '120px',
    '100px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '300px',
    '100px',
    '100px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '180px',
    '70px',
    '150px'
  ],
  INCONSISTENCIES_EXECUTED_WIDTH_COLS: [
    '60px',
    '150px',
    '200px',
    '300px',
    '300px',
    '250px',
    '200px',
    '250px',
    '180px',
    '100px',
    '100px',
    '300px',
    '150px',
    '300px',
    '150px',
    '60px',
    '200px',
    '200px',
    '200px',
    '300px',
    '120px',
    '120px',
    '120px',
    '100px',
    '60px',
    '60px',
    '100px',
    '100px',
    '180px',
    '120px',
    '180px',
    '180px',
    '300px',
    '180px',
    '250px',
    '150px'
  ],

  DEFAULT_ERROR_MESSAGE:
    'An unexpected error occurred. Please try again later. If the problem persists, contact our support team.'
};
