import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { InconsistencyService } from '@core/services/inconsistency/inconsistency.service';
import { MessageService } from '@core/services/message/message.service';
import { SquadService } from '@core/services/squad/squad.service';
import { CampaignModel } from '@models/campaign';
import {
  InconsistenciesGroupsModel,
  InconsistencyGroupErrorModel
} from '@models/inconsistency/inconsistency.model';
import { ProductModel } from '@models/product';
import {
  InconsistencyErrorStatus,
  InconsistencyGroup
} from '@shared/enums/inconsistency-status.enum';
import { Subject, takeUntil } from 'rxjs';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'app-form-product-campaign',
  templateUrl: './form-product-campaign.component.html',
  styleUrls: ['./form-product-campaign.component.scss']
})
export class FormProductCampaignComponent implements OnDestroy {
  @Input() validationFileId = '';

  @Input() isVisible = false;

  @Input() squadId = '';

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _destroying$ = new Subject<void>();

  public productCampaignInconsistencies: InconsistencyGroupErrorModel[] = [];

  public isSpinning = true;

  public disabledSaveBtn = true;

  public groupInconsistencies?: InconsistenciesGroupsModel;

  public products: ProductModel[] = [];

  public isSavedUpdates = false;

  public isSavedError = false;

  public isLineIncomplete = false;

  public isShowingMsgError = false;

  public isSaving = false;

  private autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private squadService: SquadService,
    private inconsistencyService: InconsistencyService,
    private spreadsheetService: SpreadsheetService,
    private message: MessageService
  ) {}

  public afterOpen(): void {
    this.getProducts();
  }

  private getInconsistenciesGroup(): void {
    if (!this.validationFileId) return;

    this.inconsistencyService
      .getByIdGroup(this.validationFileId, InconsistencyGroup.ProductCampaign)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: response => {
          this.groupInconsistencies = response;

          const productCampaignError = response.inconsistencies[0].errors;

          if (productCampaignError.length > 0) {
            this.productCampaignInconsistencies = this.filterPendingErrors(productCampaignError);
          }

          this.productCampaignInconsistencies.forEach(error => {
            if (error.product.id) {
              error.product.tempId = error.product.id;
              error.campaignList = this.filterCampaignByProduct(error.product.id);
            }
          });
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  private getProducts(): void {
    this.isSpinning = true;
    this.squadService.getProductBySquad(this.squadId).subscribe({
      next: data => {
        this.products = data;
        this.getInconsistenciesGroup();
      },
      error: () => {
        this.isSpinning = false;
      }
    });
  }

  private checkEmpty(): void {
    const isSomeLineComplete = this.productCampaignInconsistencies.some(
      inconsistency => inconsistency?.product?.id && inconsistency?.campaign?.id
    );

    this.disabledSaveBtn = !isSomeLineComplete;

    const isSomeLineIncomplete = this.productCampaignInconsistencies.some(item => {
      const missingCampaign = item.product.id && !item.campaign.id && item.product.hasError;

      const removedProduct = !item.product.hasError && !item.product.id;

      const removedCampaign = !item.campaign.hasError && !item.campaign.id;

      const removedProductOrCampaign = removedProduct || removedCampaign;

      return missingCampaign || removedProductOrCampaign;
    });

    this.isLineIncomplete = isSomeLineIncomplete;
  }

  public ngValueChangeProduct(productId: string, index: number): void {
    this.productCampaignInconsistencies[index].product.id = productId;
    this.productCampaignInconsistencies[index].campaign.id = null;
    this.productCampaignInconsistencies[index].campaignList =
      this.filterCampaignByProduct(productId);
    this.checkEmpty();
  }

  public ngValueChangeCampaign(campaignId: string, index: number): void {
    this.productCampaignInconsistencies[index].campaign.id = campaignId;
    this.checkEmpty();
  }

  private filterCampaignByProduct(productId: string | null): CampaignModel[] {
    const product = this.products.find(item => item.id === productId);

    if (product?.campaigns) {
      return product.campaigns;
    }
    return [];
  }

  private filterPendingErrors(
    errors: InconsistencyGroupErrorModel[]
  ): InconsistencyGroupErrorModel[] {
    return errors.filter(
      inconsistency => inconsistency.status === InconsistencyErrorStatus.Pending
    );
  }

  private setIdAndCorrect(): void {
    this.productCampaignInconsistencies.forEach(inconsistency => {
      const isAllCorrected = inconsistency.product.id && inconsistency.campaign.id;

      if (isAllCorrected) {
        const product = this.products.find(
          productItem => productItem.id === inconsistency.product.id
        );

        const campaign = product?.campaigns.find(
          campaignItem => campaignItem.id === inconsistency.campaign.id
        );

        const isAllFound = product && campaign;

        if (isAllFound) {
          inconsistency.product.correct = product.productName;
          inconsistency.campaign.correct = campaign.description;
          inconsistency.status = InconsistencyErrorStatus.Corrected;
        }
      } else {
        inconsistency.product.correct = '';
        inconsistency.campaign.correct = '';
        if (inconsistency.product.tempId) inconsistency.product.id = inconsistency.product.tempId;

        inconsistency.status = InconsistencyErrorStatus.Pending;
      }
    });
  }

  public save(): void {
    if (!this.groupInconsistencies) return;

    this.isSaving = true;
    this.checkEmpty();

    if (!this.isLineIncomplete) {
      this.isSpinning = true;
      this.setIdAndCorrect();

      this.inconsistencyService
        .saveInconsistencyGroup(
          this.validationFileId,
          this.groupInconsistencies,
          InconsistencyGroup.ProductCampaign
        )
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: result => {
            if (result) {
              this.isSavedUpdates = true;
              this.spreadsheetService.updateInconsistenciesSubject.next();
              this.autoModalTimer = setTimeout(() => {
                this.close();
              }, 5000);
            } else {
              this.isSavedError = true;
            }
          },
          error: () => {
            this.isSpinning = false;
            this.isSavedError = true;
          },
          complete: () => {
            this.isSpinning = false;
          }
        });
    } else {
      this.isSpinning = false;
    }
  }

  public close(): void {
    this.isSpinning = true;
    this.isSavedUpdates = false;
    this.isSaving = false;
    this.isVisible = false;
    this.disabledSaveBtn = true;
    this.isSavedError = false;
    this.groupInconsistencies = undefined;
    this.productCampaignInconsistencies = [];
    this.products = [];
    this.isLineIncomplete = false;
    this.isVisibleModalChange.emit(this.isVisible);
    clearTimeout(this.autoModalTimer);
  }

  public back(): void {
    this.isSavedError = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
