// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  servicePrincipal: '0f827ec9-be92-4419-a787-cab950c487a5'
};

export const baseWebApiURL = 'https://api-uat.mediahub.wppmediaservices.com.br/api';

export const referenceDay = 10;

export const oktaConfig = {
  domain: 'devh-wpp.okta.com',
  clientId: '0oa6el0waxEY2Hx1s417',
  suffixUrl: 'oauth2/default'
};

export const clarityConfig = {
  enabled: 'true',
  projectId: 'f97aerpghr'
};

export const userGroups = {
  admin: 'GRP_HUB_AUTHZ_WPP_DTI_OPENX_CM_ADMIN',
  analyst: 'GRP_HUB_AUTHZ_WPP_DTI_OPENX_CM_ANALYST',
  customer: 'GRP_HUB_AUTHZ_WPP_DTI_OPENX_CM_CUSTOMER',
  squad: 'GRP_HUB_AUTHZ_WPP_DTI_OPENX_CM_SQUAD',
  all: 'Everyone'
};
