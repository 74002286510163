import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CommitmentService } from '@core/services/commitment/commitment.service';
import { MessageService } from '@core/services/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { CommitmentCompleteModel, CommitmentModel, MediaModel, NetworkCompleteModel } from '@shared/models';
import { Subject, takeUntil } from 'rxjs';
import { NetworkService } from '../../../../core/services/network/network.service';
import { CommitmentComponentService } from '../commitment-component.service';
import { MediaService } from '@core/services/media/media.service';

@Component({
  selector: 'app-commitment-form',
  templateUrl: './commitment-form.component.html',
  styleUrls: ['./commitment-form.component.scss']
})
export class CommitmentFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  commitments: CommitmentCompleteModel[] = [];

  private allNetworks: NetworkCompleteModel[] = []

  networks: NetworkCompleteModel[] = [];

  medias: MediaModel[] = []

  disabledSaveBtn = true;

  mediaSelect = false;

  constructor(
    private formBuilder: FormBuilder,
    private networkService: NetworkService,
    private mediaService: MediaService,
    private message: MessageService,
    private commitmentService: CommitmentService,
    private commitmentComponentService: CommitmentComponentService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getData();
    this.updateNetworksSubscribe();
    this.initializeForm();
    this.disableCommitmentFields()
  }

  initializeForm(): void {
    this.validationForm = this.formBuilder.group({
      commitmentValue: [null, [Validators.required]],
      commitmentMedia: [null, [Validators.required]],
      commitmentNetwork: [null, [Validators.required]],
      commitmentYear: [null, [Validators.required]]
    });

    this.mediaChangeSubscription()
  }

  mediaChangeSubscription(): void {
    this.validationForm.get("commitmentMedia")?.valueChanges.subscribe({
      next: () => this.onMediaChange()
    })
  }

  loading(value: boolean) {
    this.commitmentComponentService.isFormLoading.next(value);
  }

  getData(): void {
    this.loading(true)
    this.mediaService.getAllMedias().pipe(takeUntil(this.destroy$)).subscribe({
      next: medias => {
        this.medias = medias;
        this.loading(false)
      },
      error: error => {
        this.loading(false)
        this.message.showErrorByStatus(error.status);
      }
    })
  }

  updateNetworksSubscribe(): void {
    this.commitmentComponentService.updateNetworks
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onMediaChange(): void {
    this.enableCommitmentFields();

    const mediaId = this.validationForm.get("commitmentMedia")?.value
    this.mediaSelect = !!mediaId;

    if (!mediaId) {
      this.disableCommitmentFields()
      return;
    }
    this.fetchNetworks(mediaId)
  }

  fetchNetworks(mediaId: string): void {
    if (this.allNetworks.length > 0) {
      this.filterNetworkLogic(mediaId)
      this.enableCommitmentFields()
      return;
    }

    this.networkService.getNetworks("CreatedAt", true).pipe(takeUntil(this.destroy$)).subscribe({
      next: networks => {
        this.loading(true)
        this.allNetworks = networks;
        this.filterNetworkLogic(mediaId)
        this.loading(false)
      }
    })
  }

  filterNetworkLogic(mediaId: string) {
    this.networks = this.allNetworks.filter(network => network.mediaId === mediaId)
  }

  disableCommitmentFields(): void {
    this.cleanCommitmentFields()
    this.validationForm.get("commitmentValue")?.disable();
    this.validationForm.get("commitmentNetwork")?.disable();
    this.validationForm.get('commitmentYear')?.disable()
  }

  enableCommitmentFields(): void {
    this.validationForm.get("commitmentNetwork")?.enable();
    this.validationForm.get("commitmentValue")?.enable()
    this.validationForm.get("commitmentYear")?.enable();
  }

  cleanCommitmentFields() {
    this.validationForm.controls['commitmentValue'].reset();
    this.validationForm.controls['commitmentNetwork'].reset();
    this.validationForm.controls['commitmentYear'].reset()
  }

  cleanForm(): void {
    this.validationForm.controls['commitmentMedia'].reset()
    this.cleanCommitmentFields()
  }

  handleSave(): void {
    this.loading(true)

    const commitmentValue = this.validationForm.get('commitmentValue')?.value;
    const commitmentNetworkId = this.validationForm.get('commitmentNetwork')?.value;
    const commitmentYear = this.validationForm.get("commitmentYear")?.value

    const commitment: CommitmentModel = {
      value: commitmentValue,
      networkId: commitmentNetworkId,
      year: commitmentYear
    };

    this.commitmentService
      .saveCommitment(commitment)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.loading(false)

          this.cleanForm()

          this.commitmentComponentService.updateCommitmentsGrid.next(true);

          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.loading(false)
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
