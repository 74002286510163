<nz-modal
  nzWidth="1200px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  [(nzVisible)]="isVisible"
  (nzOnCancel)="onCancel()"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-title">
      <div class="modal-title my-3">{{ 'Add_new_from_to' | translate }}</div>
    </div>
  </ng-container>

  <form nz-form [formGroup]="validateForm">
    <ng-container *nzModalContent>
      <div *ngIf="!showSuccessMessage && !showErrorMessage" class="cm-modal-body">
        <nz-spin nzSize="large" [nzSpinning]="isSpinning">
          <div nz-row nzGutter="12">
            <div nz-col nzSpan="6">
              <nz-form-label nzRequired>{{ 'Type' | translate }}</nz-form-label>
              <nz-form-item>
                <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
                  <nz-select
                    nzSize="large"
                    nzPlaceHolder="{{ 'Select_Type' | translate }}"
                    (ngModelChange)="onTypeChange($event)"
                    formControlName="inconsistencyGroup"
                  >
                    <nz-option
                      *ngFor="let item of listFromToType"
                      [nzValue]="item.id"
                      [nzLabel]="item.name | translate"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div *ngIf="typeSelectedId === InconsistencyGroup.Package">
            <div nz-row nzGutter="12">
              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'Package' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongPackage" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          formControlName="correctPackage"
                          (click)="setFormValidation(typeSelectedId)"
                        >
                          <nz-option
                            *ngFor="let package of packages"
                            [nzValue]="package"
                            [nzLabel]="package.description"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="typeSelectedId === InconsistencyGroup.ProductCampaign">
            <div nz-row nzGutter="12">
              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'Product' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongProduct" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          (ngModelChange)="onProductChange($event)"
                          formControlName="correctProduct"
                        >
                          <nz-option
                            *ngFor="let product of products"
                            [nzValue]="product"
                            [nzLabel]="product.productName"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>

              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'Campaign' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongCampaign" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          [nzDisabled]="!this.validateForm.get('correctProduct')?.value"
                          formControlName="correctCampaign"
                          (ngModelChange)="setFormValidation(typeSelectedId)"
                        >
                          <nz-option
                            *ngFor="let campaign of productCampaigns"
                            [nzValue]="campaign"
                            [nzLabel]="campaign.description"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="typeSelectedId === InconsistencyGroup.MediaNetworkVehicle">
            <div nz-row nzGutter="12">
              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'Media' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongMedia" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          (ngModelChange)="onMediaChange($event)"
                          formControlName="correctMedia"
                        >
                          <nz-option
                            *ngFor="let media of medias"
                            [nzValue]="media"
                            [nzLabel]="media.mediaName"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>

              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'Network' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongNetwork" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          [nzDisabled]="!this.validateForm.get('correctMedia')?.value"
                          required
                          (ngModelChange)="onNetworkChange($event)"
                          formControlName="correctNetwork"
                        >
                          <nz-option
                            *ngFor="let network of mediaNetworks"
                            [nzValue]="network"
                            [nzLabel]="network.networkName"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>

              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'Vehicle' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongVehicle" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          [nzDisabled]="!this.validateForm.get('correctNetwork')?.value"
                          formControlName="correctVehicle"
                          (ngModelChange)="setFormValidation(typeSelectedId)"
                        >
                          <nz-option
                            *ngFor="let vehicle of networkVehicles"
                            [nzValue]="vehicle"
                            [nzLabel]="vehicle.vehicleName"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="typeSelectedId === InconsistencyGroup.PurchaseType">
            <div nz-row nzGutter="12">
              <div nz-col [nzSpan]="12">
                <h2 class="mt-2 mb-0">{{ 'PurchaseType' | translate }}</h2>
                <div nz-row nzGutter="6">
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'FROM' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <input nz-input nzSize="large" formControlName="wrongPurchaseType" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="12" class="cm-label-input mb-1">
                    <nz-form-item class="d-flex flex-wrap flex-column">
                      <nz-form-label class="align-self-start" nzRequired>{{
                        'TO' | translate
                      }}</nz-form-label>
                      <nz-form-control
                        nzHasFeedback
                        nzErrorTip="{{ 'Required_field' | translate }}"
                      >
                        <nz-select
                          nzShowSearch
                          nzSize="large"
                          required
                          formControlName="correctPurchaseType"
                          (ngModelChange)="setFormValidation(typeSelectedId)"
                        >
                          <nz-option
                            *ngFor="let purchaseType of purchaseTypes"
                            [nzValue]="purchaseType"
                            [nzLabel]="purchaseType.name"
                          >
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-spin>
      </div>

      <div *ngIf="showSuccessMessage">
        <span class="modal-icon success material-icons d-flex justify-content-center mb-2"
          >done</span
        >
        <p class="text-center fw-bold mb-3">
          {{ 'From_to_rule_was_successfully_added' | translate }}
        </p>

        <p class="text-center fw-bold mb-4">
          {{ 'Do_you_want_to_add_more' | translate }}
        </p>
        <div class="d-flex justify-content-center text-center">
          <app-button type="link-secondary" class="me-2" (click)="onCancel()">
            {{ 'No' | translate }}
          </app-button>
          <app-button type="primary" (click)="onAddMore()">
            {{ 'Yes' | translate }}
          </app-button>
        </div>
      </div>

      <div *ngIf="showErrorMessage">
        <span class="modal-icon error material-icons d-flex justify-content-center mb-2"
          >error_outline</span
        >
        <p class="text-center fw-bold mb-3">
          {{
            'An unexpected error occurred. Please try again later. If the problem persists, contact our support team.'
              | translate
          }}
        </p>
        <div class="d-flex justify-content-center text-center">
          <app-button type="link-secondary" class="me-2" (click)="onCancel()">
            {{ 'Close' | translate }}
          </app-button>
          <app-button type="primary" (click)="onTryAgain()">
            {{ 'Try_again' | translate }}
          </app-button>
        </div>
      </div>
    </ng-container>

    <ng-container *nzModalFooter>
      <div *ngIf="!isSpinning && typeSelectedId && !showSuccessMessage && !showErrorMessage">
        <div class="d-flex justify-content-end">
          <app-button class="me-2" type="link" (click)="onCancel()">
            {{ 'Cancel' | translate }}
          </app-button>

          <app-button
            type="primary"
            [disabled]="!validateForm.valid"
            (click)="submitForm(typeSelectedId)"
          >
            {{ 'Add' | translate }}
          </app-button>
        </div>
      </div>
    </ng-container>
  </form>
</nz-modal>
