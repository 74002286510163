<nz-modal
  nzWidth="1200px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="close()"
  [(nzVisible)]="isVisible"
  (nzAfterOpen)="afterOpen()"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-title">
      <div class="modal-title my-3">{{ title }}</div>
      <div class="modal-squad-name mb-3">{{ 'Squad' | translate }} {{ squadName }}</div>
    </div>
  </ng-container>

  <ng-container *nzModalContent>
    <div
      *ngIf="!showSuccessMessage && !showErrorMessage && !showReproveMessage"
      class="cm-modal-body"
    >
      <nz-spin nzSize="large" [nzSpinning]="isSpinning">
        <div class="table-approved cm-form-scroll">
          <nz-table
            *ngIf="showTable"
            #approvedTable
            [nzData]="inconsistenciesTable"
            [nzShowPagination]="false"
            [nzFrontPagination]="false"
          >
            <thead>
              <tr>
                <th nzWidth="50px" class="table-th">
                  <label
                    class="checkboxRed"
                    nz-checkbox
                    [(ngModel)]="allChecked"
                    [nzIndeterminate]="indeterminate"
                    (ngModelChange)="updateAllChecked()"
                  >
                  </label>
                </th>
                <th>{{ 'Type' | translate }}</th>
                <th>{{ 'FROM' | translate }}</th>
                <th>{{ 'TO' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of approvedTable.data"
                [ngClass]="{ hideLine: data.status !== InconsistencyErrorStatus.Corrected }"
              >
                <td>
                  <label
                    nz-checkbox
                    [(ngModel)]="data.checked"
                    (ngModelChange)="updateSingleChecked()"
                  >
                  </label>
                </td>
                <td>
                  <span
                    class="fw-semibold"
                    *ngIf="data.inconsistencyGroup === InconsistencyGroup.ProductCampaign"
                  >
                    {{ 'ProductCampaign' | translate }}
                  </span>
                  <span
                    class="fw-semibold"
                    *ngIf="data.inconsistencyGroup === InconsistencyGroup.MediaNetworkVehicle"
                  >
                    {{ 'MediaNetworkVehicle' | translate }}
                  </span>
                  <span
                    class="fw-semibold"
                    *ngIf="data.inconsistencyGroup === InconsistencyGroup.Package"
                  >
                    {{ 'Package' | translate }}
                  </span>
                  <div
                    class="fw-semibold"
                    *ngIf="data.inconsistencyGroup === InconsistencyGroup.PurchaseType"
                  >
                    {{ 'PurchaseType' | translate }}
                  </div>
                  <div *ngIf="data.inconsistencyGroup === InconsistencyGroup.PurchaseType">
                    ({{ data.campaign.original }}/ {{ data.vehicle.original }}/
                    {{ data.package.original }})
                  </div>
                </td>
                <td>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.ProductCampaign">
                    {{ data.product.original }} / {{ data.campaign.original }}
                  </span>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.MediaNetworkVehicle">
                    {{ data.media.original }} / {{ data.network.original }} /
                    {{ data.vehicle.original }}
                  </span>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.Package">
                    {{ data.package.original }}
                  </span>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.PurchaseType">
                    {{ data.purchaseType.original }}
                  </span>
                </td>
                <td>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.ProductCampaign">
                    {{ data.product.correct }} / {{ data.campaign.correct }}
                  </span>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.MediaNetworkVehicle">
                    {{ data.media.correct }} / {{ data.network.correct }} /
                    {{ data.vehicle.correct }}
                  </span>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.Package">
                    {{ data.package.correct }}
                  </span>
                  <span *ngIf="data.inconsistencyGroup === InconsistencyGroup.PurchaseType">
                    {{ data.purchaseType.correct }}
                  </span>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-spin>
    </div>
    <div
      *ngIf="showSuccessMessage"
      class="d-flex flex-column justify-content-center align-items-center p-5"
      style="height: 300px"
    >
      <span class="modal-icon success material-icons align-middle">done</span>
      <p class="modal-text success mt-2">{{ 'Saved_approvals' | translate }}</p>
    </div>

    <div
      *ngIf="showErrorMessage"
      class="d-flex flex-column justify-content-center align-items-center p-5"
      style="height: 300px"
    >
      <span class="modal-icon error material-icons-outlined align-middle">cancel</span>
      <p class="modal-text error mt-3 mb-4">{{ 'Error_modal_approved' | translate }}</p>
    </div>

    <div
      *ngIf="showReproveMessage"
      class="d-flex flex-column justify-content-center align-items-center p-5"
      style="height: 300px"
    >
      <span class="p-0 modal-icon alert material-icons-outlined align-middle">info</span>
      <p class="text-center fw-bold error">{{ 'Items_that_were_not_approved' | translate }}</p>
      <p class="text-center fw-bold error">
        {{ 'Do_you_want_to_correct_it_again_now' | translate }}
      </p>
      <div class="d-flex justify-content-center text-center">
        <app-button type="link-secondary" (click)="close()" class="me-2">
          {{ 'No' | translate }}
        </app-button>
        <app-button type="primary" (click)="viewInconsisteciesPage()">
          {{ 'Yes' | translate }}
        </app-button>
      </div>
    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div *ngIf="showButtons" class="d-flex justify-content-end">
      <app-button class="me-2" type="default" (click)="saveReprove()" [disabled]="disabledButtons">
        <span class="material-icons-outlined md-24 me-2">cancel</span>
        {{ 'Reprove' | translate }}
      </app-button>
      <app-button type="success" [disabled]="disabledButtons" (click)="saveApprove()">
        <span class="material-icons-outlined md-24 me-2">check_circle</span>
        {{ 'Approve' | translate }}
      </app-button>
    </div>
  </ng-container>
</nz-modal>
