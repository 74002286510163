export enum InconsistencyStatus {
  None = 0,
  PendingApproval = 1,
  Approved = 2
}

export enum InconsistencyGroup {
  Package = 1,
  ProductCampaign = 2,
  MediaNetworkVehicle = 3,
  PurchaseType = 4
}

export enum InconsistencyErrorStatus {
  Pending = 1,
  Corrected = 2,
  Approved = 3
}

export enum RowStatus {
  None = 1,
  Error = 2,
  Corrected = 3
}
