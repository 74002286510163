import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ObjLengthPipe } from './obj-length.pipe';

@NgModule({
  declarations: [ObjLengthPipe],
  exports: [ObjLengthPipe],
  imports: [CommonModule]
})
export class PipesModule {}
