import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MediaService } from '@core/services/media/media.service';
import { MessageService } from '@core/services/message/message.service';
import { MediaModel } from '@models/media';
import { NetworkModel } from '@models/network';
import { TranslateService } from '@ngx-translate/core';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject, takeUntil } from 'rxjs';
import { MediaComponentService } from '../media-component.service';

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss']
})
export class MediaFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  medias: MediaModel[] = [];

  networks: NetworkModel[] = [];

  disabledSaveBtn = true;

  isSpinning = false;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    private mediaService: MediaService,
    private mediaComponentService: MediaComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      mediaName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
  }

  cleanForm(): void {
    this.validationForm.controls['mediaName'].setValue('');
    this.validationForm.controls['mediaName'].reset();
  }

  save(): void {
    this.mediaComponentService.isFormLoading.next(true);

    const mediaName = this.validationForm.get('mediaName')?.value;
    this.mediaService
      .saveMedia(mediaName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['mediaName'].reset();
          this.mediaComponentService.updateMediasGrid.next(true);
          this.message.showSuccess(this.translate.instant('Registration_saved'));
          this.mediaComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.mediaComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
