<label class="total py-3">{{
  'Commitments_Found' | translate : { total: commitments.length }
  }}</label>
<nz-table [nzFrontPagination]="false" [nzData]="commitments" [nzLoading]="loading"
  [nzScroll]="{ x: '400px', y: 'calc(100vh - 415px)' }">
  <thead>
    <tr>
      <th nzWidth="calc(130px + 5vw)" class="column-header">{{ 'Network' | translate }}</th>
      <th nzWidth="calc(190px + 0.5vw)" class="column-header">{{ 'Commitment' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'Year' | translate }}</th>
      <th nzWidth="calc(220px + 2vw)" class="column-header">{{ 'CreatedBy' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'CreatedAt' | translate }}</th>
      <th nzWidth="130px"></th>
    </tr>
  </thead>
  <tbody class="column-content">
    <tr *ngFor="let commitment of commitments">
      <td>{{ commitment.network.networkName }}</td>
      <td>{{ commitment.value | currency : 'BRL' }}</td>
      <td>{{ commitment.year}}</td>
      <td>{{ commitment.createdBy }}</td>
      <td>{{ commitment.createdAt | date : 'dd/MM/yyyy' }}</td>
      <td>
        <div class="d-flex justify-content-end">
          <app-button class="me-2" type="link" (click)="handleEdit(commitment)">
            <span class="material-icons-outlined md-24">edit</span>
          </app-button>
          <app-button type="link" (click)="handleDelete(commitment)">
            <span class="material-icons-outlined md-24">delete</span>
          </app-button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-commitment-edit [isVisible]="isVisibleEditModal" [commitment]="commitment"
  (isVisibleModalChange)="handleCancel()"></app-commitment-edit>