import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { InconsistencyGroups } from '@models/inconsistencyGroups';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InconsistencyGroupsService {
  constructor(private http: HttpClient) {}

  public get(): Observable<InconsistencyGroups[]> {
    const route = `${baseWebApiURL}/v1/InconsistencyGroup`;

    return this.http.get<InconsistencyGroups[]>(route);
  }
}
