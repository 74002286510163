import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { ProductModel, SquadModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SquadService {
  constants = CONSTANTS;

  constructor(private http: HttpClient) {}

  public get(): Observable<SquadModel[]> {
    return this.http.get<SquadModel[]>(`${baseWebApiURL}/v1/Squads`);
  }

  public getProductBySquad(squadId?: string): Observable<ProductModel[]> {
    const route = squadId
      ? `${baseWebApiURL}/v1/Squads/${squadId}/products`
      : `${baseWebApiURL}/v1/Products`;

    return this.http.get<ProductModel[]>(route);
  }
}
