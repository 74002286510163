<nz-table #basicTable [nzFrontPagination]="false" [nzScroll]="{ x: '400px' }" [nzData]="products">
  <thead>
    <tr>
      <th nzWidth="calc(180px + 7vw)" class="column-header">{{ 'Product' | translate }}</th>
      <th nzWidth="calc(200px + 7vw)" class="column-header">{{ 'Squad' | translate }}</th>
      <th nzWidth="calc(220px + 2vw)" class="column-header">{{ 'CreatedBy' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'CreatedAt' | translate }}</th>
      <th nzWidth="60px"></th>
    </tr>
  </thead>
  <tbody class="column-content">
    <tr *ngFor="let product of products">
      <td class="column-content__product">{{ product.productName }}</td>
      <td class="column-content__product">
        <span *ngFor="let squad of product.productSquad; let i = index">
          {{ squad.squad.squadName }}{{ i === product.productSquad.length - 1 ? '' : ',' }}
        </span>
      </td>
      <td>{{ product.createdBy }}</td>
      <td>{{ product.createdAt | date : 'dd/MM/yyyy' }}</td>
      <td>
        <div class="d-flex justify-content-end">
          <app-button type="link" (click)="handleDelete(product)">
            <span class="material-icons-outlined md-24">delete</span>
          </app-button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
