import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { userGroups } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from '../../core/services/message/message.service';
import { UserProfileService } from '../../core/services/userProfile/userProfile';
import { UserInfoService } from '../../pages/user-info.service';
import { UserProfileModel, UserProfileUpdateModel } from '../../shared/models';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit, OnChanges {
  @Input() userInfo = { name: '', email: '' } as { name: string; email: string } | null;

  @Input() isVisible = false;

  @Input() nameInitial = '';

  @Output() isVisibleDrawerChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly destroy$ = new Subject<void>();

  userGroup = '';

  userProfile!: UserProfileModel;

  validationForm!: FormGroup;

  isLoadingData = false;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    public translate: TranslateService,
    private userProfileService: UserProfileService,
    private userInfoService: UserInfoService
  ) {}

  private getUser(): void {
    this.isLoadingData = true;
    this.userProfileService
      .getByUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: userProfile => {
          this.isLoadingData = false;
          this.userProfile = userProfile;
          this.setValues(userProfile);
        },
        error: result => {
          this.isLoadingData = false;
          this.message.showInfo(result?.error?.detail || result?.error || result, {
            positionClass: 'toast-top-left'
          });
        }
      });
  }

  private saveUser(userProfileUpdate: UserProfileUpdateModel): void {
    this.isLoadingData = true;
    this.userProfileService
      .update(userProfileUpdate)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isLoadingData = false;
          this.userProfileService.updateUserHeader.next();
          this.message.showSuccess(this.translate.instant('Profile_updated_successfully'), {
            positionClass: 'toast-top-left'
          });
          this.onClose();
        },
        error: result => {
          this.isLoadingData = false;
          this.message.showError(result?.error?.detail || result?.error || result, {
            positionClass: 'toast-top-left'
          });
        }
      });
  }

  private setValues(user: UserProfileModel): void {
    if (!user?.profile) {
      return;
    }

    this.validationForm.controls['firstName'].setValue(user.profile?.firstName);
    this.validationForm.controls['lastName'].setValue(user.profile?.lastName);
    this.validationForm.controls['role'].setValue(user.profile?.role);
  }

  private getProfileFromGroup(group: string): string {
    switch (group) {
      case userGroups.admin:
        return 'Admin';
      case userGroups.analyst:
        return 'Analyst';
      case userGroups.squad:
        return 'Squad';
      case userGroups.customer:
        return 'Customer';
      default:
        return '';
    }
  }

  private setUserGroup(): void {
    this.userGroup = this.userInfoService.getUserGroup();
    const profile = this.getProfileFromGroup(this.userGroup);
    this.translate
      .get(profile)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.validationForm.controls['profile'].setValue(result);
        }
      });
  }

  onSave(): void {
    const userProfileUpdate = {
      firstName: this.validationForm.controls['firstName'].value || null,
      lastName: this.validationForm.controls['lastName'].value || null,
      role: this.validationForm.controls['role'].value || null
    };
    this.saveUser(userProfileUpdate);
  }

  onClose(): void {
    this.isVisible = false;
    this.isVisibleDrawerChange.emit(this.isVisible);
    this.validationForm.reset();
  }

  private afterOpen(): void {
    this.setUserGroup();
    this.getUser();
  }

  ngOnChanges(): void {
    if (this.isVisible) {
      this.afterOpen();
    }
  }

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      firstName: [null, [Validators.minLength(2), Validators.maxLength(50)]],
      lastName: [null, [Validators.minLength(2), Validators.maxLength(50)]],
      profile: [null, [Validators.required]],
      role: [null, [Validators.minLength(2), Validators.maxLength(50)]]
    });
    this.validationForm.controls['profile'].disable();
  }
}
