import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { NetworkCompleteModel, NetworkModel } from '@models/network';
import { VehicleModel } from '@models/vehicle/vehicle.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private http: HttpClient) {}

  public getVehicleByNetworks(networkId: string): Observable<VehicleModel[]> {
    return this.http.get<VehicleModel[]>(`${baseWebApiURL}/v1/Network/${networkId}/vehicles`);
  }

  public getNetworks(
    columnToOrder: string,
    descendingDirection: boolean,
    length?: number
  ): Observable<NetworkCompleteModel[]> {
    const lengthParam = length ? `&length=${length}` : '';

    const route = `${baseWebApiURL}/v1/Network/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}${lengthParam}`;

    return this.http.get<NetworkCompleteModel[]>(route);
  }

  public getNetworksWithoutCommitment(networkId?: string): Observable<NetworkCompleteModel[]> {
    const networkIdParam = networkId ? `?networkId=${networkId}` : '';

    const route = `${baseWebApiURL}/v1/Network/withoutCommitment${networkIdParam}`;

    return this.http.get<NetworkCompleteModel[]>(route);
  }

  public saveNetwork(mediaId: string, networkName: string): Observable<NetworkModel> {
    return this.http.post<NetworkModel>(
      `${baseWebApiURL}/v1/Network?mediaId=${mediaId}&name=${networkName}`,
      null
    );
  }

  public deleteNetwork(networkId: string): Observable<NetworkModel> {
    return this.http.delete<NetworkModel>(
      `${baseWebApiURL}/v1/Network/${networkId}`
    );
  }
}
