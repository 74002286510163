<button
  nz-button
  class="app-button {{ type }}"
  (nzType)="(type)"
  [nzLoading]="loading"
  [disabled]="disabled"
  (click)="onClick($event)"
>
  <i *ngIf="icon" class="material-icons app-icon">{{ icon }}</i>
  <ng-content></ng-content>
</button>
