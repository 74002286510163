<label class="total py-3">{{ 'Vehicles_Found' | translate : { total: vehicles.length } }}</label>
<nz-table
  #basicTable
  [nzFrontPagination]="false"
  [nzData]="vehicles"
  [nzLoading]="loading"
  [nzScroll]="{ x: '400px', y: 'calc(100vh - 415px)' }"
>
  <thead>
    <tr>
      <th nzWidth="calc(120px + 6vw)" class="column-header">{{ 'Vehicle' | translate }}</th>
      <th nzWidth="calc(120px + 6vw)" class="column-header">{{ 'Media' | translate }}</th>
      <th nzWidth="calc(120px + 6vw)" class="column-header">{{ 'Network' | translate }}</th>
      <th nzWidth="calc(220px + 2vw)" class="column-header">{{ 'CreatedBy' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'CreatedAt' | translate }}</th>
      <th nzWidth="60px"></th>
    </tr>
  </thead>
  <tbody class="column-content">
    <tr *ngFor="let vehicle of vehicles">
      <td class="column-content__vehicle">{{ vehicle.vehicleName }}</td>
      <td class="column-content__media">{{ vehicle.network.media.mediaName }}</td>
      <td class="column-content__network">{{ vehicle.network.networkName }}</td>
      <td>{{ vehicle.createdBy }}</td>
      <td>{{ vehicle.createdAt | date : 'dd/MM/yyyy' }}</td>
      <td>
        <div class="d-flex justify-content-end">
          <app-button type="link" (click)="handleDelete(vehicle)">
            <span class="material-icons-outlined md-24">delete</span>
          </app-button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
