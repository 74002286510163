import { Component, Input } from '@angular/core';
import { userGroups } from '@env';
import { FinancialFileModel } from '@models/financial-file';

@Component({
  selector: 'app-common-file-tab',
  templateUrl: './common-file-tab.component.html',
  styleUrls: ['./common-file-tab.component.scss']
})
export class CommonFileTabComponent {
  @Input() filesList: FinancialFileModel[] = [];

  @Input() consolidatedFiles: FinancialFileModel[] = [];

  @Input() userGroup = '';

  @Input() lastDmiImportDate: Date | null = null;

  @Input() dateRef = new Date();

  public userGroups = userGroups;
}
