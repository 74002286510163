import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@core/guards/admin.guard';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ComparativeComponent } from '@pages/comparative/comparative.component';
import { ConfigurationAreaComponent } from '@pages/configuration-area/configuration-area.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { HomeComponent } from '@pages/home/home.component';
import { InconsistenciesComponent } from '@pages/inconsistencies/inconsistencies.component';
import { CampaignComponent } from '@pages/registrations/campaign/campaign.component';
import { ListComponent } from '@pages/registrations/list/list.component';
import { MediaComponent } from '@pages/registrations/media/media.component';
import { NetworkComponent } from '@pages/registrations/network/network.component';
import { PackageComponent } from '@pages/registrations/package/package.component';
import { ProductComponent } from '@pages/registrations/product/product.component';
import { PurchaseTypeComponent } from '@pages/registrations/purchase-type/purchase-type.component';
import { VehicleComponent } from '@pages/registrations/vehicle/vehicle.component';
import { ReportComponent } from '@pages/report/report.component';
import { RulesComponent } from '@pages/rules/rules.component';
import { UpdateColumnComponent } from '@pages/update-column/update-column.component';
import { AnalystGuard } from './core/guards/analyst.guard';
import { SquadGuard } from './core/guards/squad.guard';
import { FilesComponent } from './pages/files/files.component';
import { CommitmentComponent } from './pages/registrations/commitment/commitment.component';
import { UsersComponent } from './pages/users/users.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  {
    path: 'not_found',
    component: HomeComponent,
    data: { message: 'not_found' }
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'files',
    children: [
      {
        path: '',
        component: FilesComponent
      },
      {
        path: 'inconsistencies/:inconsistencyId',
        component: InconsistenciesComponent,
        canActivate: [SquadGuard]
      },
      {
        path: 'update_column',
        component: UpdateColumnComponent,
        canActivate: [SquadGuard]
      },
      {
        path: 'comparative',
        component: ComparativeComponent
      }
    ],
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'settings',
    children: [
      {
        path: 'metabase',
        component: ConfigurationAreaComponent
      },
      {
        path: 'users',
        component: UsersComponent
      }
    ],
    canActivate: [OktaAuthGuard, AdminGuard]
  },
  { path: 'report', component: ReportComponent, canActivate: [OktaAuthGuard, AnalystGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [OktaAuthGuard, AnalystGuard] },
  { path: 'rules', component: RulesComponent, canActivate: [OktaAuthGuard, AnalystGuard] },
  {
    path: 'registrations',
    children: [
      {
        path: 'vehicle',
        component: VehicleComponent
      },
      {
        path: 'product',
        component: ProductComponent
      },
      {
        path: 'package',
        component: PackageComponent
      },
      {
        path: 'campaign',
        component: CampaignComponent
      },
      {
        path: 'media',
        component: MediaComponent
      },
      {
        path: 'network',
        component: NetworkComponent
      },
      {
        path: 'purchaseType',
        component: PurchaseTypeComponent
      },
      {
        path: 'commitment',
        component: CommitmentComponent
      },
      {
        path: 'list',
        component: ListComponent
      }
    ],
    canActivate: [OktaAuthGuard, AnalystGuard]
  },
  { path: '', redirectTo: 'files', pathMatch: 'full' },
  { path: '**', redirectTo: 'not_found', pathMatch: 'full' } // Must be last route
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
