import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { NetworkService } from '@core/services/network/network.service';
import { NetworkCompleteModel } from '@models/network';
import { Subject, takeUntil } from 'rxjs';
import { NetworkComponentService } from '../network-component.service';

@Component({
  selector: 'app-network-grid',
  templateUrl: './network-grid.component.html',
  styleUrls: ['./network-grid.component.scss']
})
export class NetworkGridComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  networks: NetworkCompleteModel[] = [];

  network: NetworkCompleteModel = {} as NetworkCompleteModel;

  isLoadingDelete = false;

  constructor(
    private networkService: NetworkService,
    private networkComponentService: NetworkComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTable();
  }

  getData(): void {
    this.networkComponentService.isTableLoading.next(true);
    this.networkService
      .getNetworks('CreatedAt', true, 5)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (networks: NetworkCompleteModel[]) => {
          this.networks = networks;
          this.networkComponentService.isTableLoading.next(false);
        },
        error: error => {
          this.networkComponentService.isTableLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.networkComponentService.updateNetworkGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(networkId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.networkService
        .deleteNetwork(networkId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Network deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(network: NetworkCompleteModel): void {
    this.network = network;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(network.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
