<div>
  <form nz-form [formGroup]="validationForm">
    <div>
      <nz-form-item class="d-block flex-grow-1">
        <nz-form-label nzRequired>{{ 'Email' | translate }}</nz-form-label>
        <div class="d-flex align-items-center">
          <div class="flex-grow-1 me-2">
            <nz-form-control nzHasFeedback>
              <input
                formControlName="email"
                nz-input
                required
                [(ngModel)]="validationForm.value.email"
                (ngModelChange)="ngChangeEmail()"
              />
            </nz-form-control>
          </div>
          <div>
            <app-button
              type="primary"
              (click)="onVerify()"
              [loading]="isSpinningVerify"
              [disabled]="isSpinningVerify"
            >
              {{ 'Verify' | translate }}
            </app-button>
          </div>
        </div>
      </nz-form-item>
      <div *ngIf="isShowGroups">
        <nz-form-item *ngIf="isShowGroups">
          <nz-radio-group
            formControlName="groupId"
            class="flex-grow-1"
            [(ngModel)]="validationForm.value.groupId"
            (ngModelChange)="ngChangeGroup($event)"
          >
            <div *ngFor="let group of groups" class="align-items-center">
              <div class="align-items-center w-100 border-bottom" nz-radio [nzValue]="group.id">
                <div class="ms-2 py-2">
                  <p class="card-text mb-1">{{ group.name | translate }}</p>
                  <span>{{ group.name + '_description' | translate }}</span>
                  <nz-form-control
                    *ngIf="group.name == constants.GROUP_SQUAD"
                    nzHasFeedback
                    class="mt-2"
                  >
                    <nz-select
                      formControlName="squadId"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="{{ 'Choose_Squad' | translate }}"
                    >
                      <nz-option
                        *ngFor="let squad of squads"
                        [nzValue]="squad.id"
                        [nzLabel]="squad.squadName"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </div>
              </div>
            </div>
          </nz-radio-group>
        </nz-form-item>
        <nz-form-item>
          <div class="d-flex w-100 justify-content-end">
            <app-button
              type="primary"
              (click)="onAssociate()"
              [loading]="isSpinningAssociate"
              [disabled]="!validationForm.valid"
            >
              {{ 'Associate' | translate }}
            </app-button>
          </div>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
