import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from './directives/directives.module';
import { AppTranslateModule } from './modules/translate/translate.module';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [DirectivesModule, PipesModule, AppTranslateModule]
})
export class SharedModule {}
