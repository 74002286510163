import { referenceDay } from '@env';
import { FinancialFileTypes } from '../enums';

export const MONTH_NAMES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

export function calculateReferenceDate(date: Date): Date {
  if (date.getDate() <= referenceDay) {
    const referenceDate = date;
    referenceDate.setMonth(date.getMonth() - 1);
    return referenceDate;
  }
  return date;
}

export function getContainerFinancialFileTypes(type: FinancialFileTypes): string {
  switch (type) {
    case FinancialFileTypes.Planned:
      return 'planned';
    case FinancialFileTypes.Executed:
      return 'executed';
    case FinancialFileTypes.Consolidated:
      return 'consolidated';
    case FinancialFileTypes.MediaHub:
      return 'mediaHub';

    default:
      return '';
  }
}

export function convertContainerNameToFinancialFileTypes(
  containerName: string
): FinancialFileTypes | null {
  switch (containerName) {
    case 'planejado':
      return FinancialFileTypes.Planned;
    case 'realizado':
      return FinancialFileTypes.Executed;
    case 'consolidado':
      return FinancialFileTypes.Consolidated;

    default:
      return null;
  }
}

export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Record<K, T[]> =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const groupByInconsistencies = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Record<K, { correct: string | null; inconsistencies: T[] }> =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous?.[group]?.inconsistencies) {
      previous[group] = { ...previous[group], inconsistencies: [] };
    }

    if (!previous?.[group]?.correct) {
      previous[group] = { ...previous[group], correct: null };
    }

    previous[group].inconsistencies.push(currentItem);

    return previous;
  }, {} as Record<K, { correct: string | null; inconsistencies: T[] }>);

export const StringHelper = {
  toNormalize: (term: string | undefined): string => {
    if (!term) return '';

    return term
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  },
  includesNormalized: (term: string | undefined, include: string | undefined): boolean => {
    return StringHelper.toNormalize(term).includes(StringHelper.toNormalize(include));
  }
};

export const DateHelper = {
  toDateString: (date: Date | undefined): string => {
    if (!date) return '';

    return new Date(date).toLocaleDateString('pt-BR', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }
};

export const getInitialFromName = (name: string): string => {
  const names = name.split(' ');
  const firstName = names[0];
  const lastName = names[names.length - 1];

  return firstName.charAt(0) + lastName.charAt(0);
};
