import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DownloadService } from '@core/services/download/download.service';
import { userGroups } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '@pages/user-info.service';
import { ConsolidatedStatus, FinancialFileTypes } from '@shared/enums';
import { InconsistencyStatus } from '@shared/enums/inconsistency-status.enum';
import { FinancialFileModel, FinancialFileResponse, MonthYear } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ConsolidatedService } from '../../core/services/consolidated/consolidated.service';
import { FinancialFileService } from '../../core/services/financial-file/financial-file.service';
import { FilesComponentService } from './files-component.service';
import { DmiModel } from '@models/dmi/dmi.model';
import { DmiService } from '@core/services/dmi/dmi.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilesComponent implements OnInit, OnDestroy {
  public CONSTANTS = CONSTANTS;

  public financialFileTypes = FinancialFileTypes;

  public ConsolidatedStatus = ConsolidatedStatus;

  public isVisibleModalUploadFile = false;

  public isVisibleModalApproveFile = false;

  public allFiles: FinancialFileModel[] = [];

  public plannedFiles: FinancialFileModel[] = [];

  public executedFiles: FinancialFileModel[] = [];

  public consolidatedFiles: FinancialFileModel[] = [];

  public mediaHubFiles: FinancialFileModel[] = [];

  public dmiFile!: DmiModel;

  public fileGeneralConsolidated: FinancialFileModel = new FinancialFileModel();

  public lastDmiImportDate: Date | null = null;

  private monthYearList: MonthYear[] = [];

  public activeTab = 0;

  public isSpinning = false;

  public dateRef = new Date();

  public userGroup = '';

  public userGroups = userGroups;

  public title = '';

  public squadName = '';

  public upload_type_planned: string = CONSTANTS.UPLOAD_TYPE_PLANNED;

  public upload_type_executed: string = CONSTANTS.UPLOAD_TYPE_EXECUTED;

  public validationFileId = '';

  public openApprove = '';

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public financialFileService: FinancialFileService,
    public consolidatedService: ConsolidatedService,
    public downloadService: DownloadService,
    public userInfoService: UserInfoService,
    public message: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private filesComponentService: FilesComponentService,
    private dmiService: DmiService
  ) {}

  ngOnInit(): void {
    this.setUserGroup();
    this.setupEventListeners();
  }

  private setUserGroup(): void {
    this.userGroup = this.userInfoService.getUserGroup();
  }

  private setupEventListeners(): void {
    this.route.queryParams.pipe(takeUntil(this._destroying$)).subscribe((params: Params) => {
      let tab = parseInt(params['active_tab'], 10) || 0;

      const defaultActiveTab = 2;
      const defaultOpenApprove = '';
      const defaultRefDate = new Date().toISOString();

      const refDate = params['ref_date'] || defaultRefDate;
      const newDate = new Date(refDate);
      const newDateUTC = new Date(
        newDate.getUTCFullYear(),
        newDate.getUTCMonth(),
        newDate.getUTCDate()
      );

      if (tab == 3 && this.mediaHubFiles.length == 0) tab = 2;
      if (tab == 3 && !this.dmiFile) tab = 2;

      this.activeTab = params['active_tab'] ? tab : defaultActiveTab;
      this.openApprove = params['open_approve'] || defaultOpenApprove;
      this.dateRef = newDateUTC;

      this.getMonths();
      this.getFiles(this.dateRef);
      this.getDmi(this.dateRef);
    });

    this.financialFileService.updateFilesSubject.pipe(takeUntil(this._destroying$)).subscribe({
      next: () => {
        this.getMonths();
        this.getFiles(this.dateRef);
        this.getDmi(this.dateRef);
      }
    });
    this.filesComponentService.loading.pipe(takeUntil(this._destroying$)).subscribe({
      next: loading => {
        this.isSpinning = loading;
      }
    });
  }

  private getMonths(): void {
    this.monthYearList = [];
    this.financialFileService.getMonths().subscribe({
      next: data => {
        this.monthYearList = data;
      },
      error: error => {
        this.message.showErrorByStatus(error.status);
      }
    });
  }

  private getDmi(refDate: Date): void {
    this.isSpinning = true;
    this.filesComponentService.loading.next(true);
    this.dmiService
      .get(refDate)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: data => {
          this.dmiFile = data;
          this.isSpinning = false;
          this.filesComponentService.loading.next(false);
        },
        error: error => {
          this.filesComponentService.loading.next(false);
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  private getFiles(refDate: Date): void {
    this.isSpinning = true;
    this.filesComponentService.loading.next(true);
    this.fileGeneralConsolidated = new FinancialFileModel();
    this.financialFileService
      .get(refDate)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: data => {
          this.isSpinning = false;
          this.setFiles(data);
          this.filesComponentService.loading.next(false);
        },
        error: error => {
          this.isSpinning = false;
          this.filesComponentService.loading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  private setFiles(financialFileResponse: FinancialFileResponse): void {
    this.plannedFiles = [];
    this.executedFiles = [];
    this.consolidatedFiles = [];
    this.mediaHubFiles = [];
    this.lastDmiImportDate = financialFileResponse.lastDmiImportDate;

    const data = financialFileResponse.financialFiles;

    this.allFiles = data;

    for (const item in data) {
      const { fileType, squadId } = data[item];

      if (fileType === this.financialFileTypes.Consolidated && squadId === null) {
        this.fileGeneralConsolidated = data[item];
      } else if (fileType === this.financialFileTypes.Planned) {
        this.plannedFiles.push(data[item]);
      } else if (fileType === this.financialFileTypes.Executed) {
        this.executedFiles.push(data[item]);
      } else if (fileType === this.financialFileTypes.Consolidated) {
        this.consolidatedFiles.push(data[item]);
      } else if (fileType === this.financialFileTypes.MediaHub) {
        this.mediaHubFiles.push(data[item]);
      }

      const financialFile = this.allFiles.find(
        file => file?.validationFile?.id === this.openApprove
      );

      if (financialFile) {
        this.showApproveModal(financialFile);
      }
    }
  }

  public showApproveModal(file: FinancialFileModel): void {
    if (file.validationFile && file.validationFile.status === InconsistencyStatus.PendingApproval) {
      const { fileType } = file;
      const fileName =
        fileType === this.financialFileTypes.Planned
          ? this.upload_type_planned
          : this.upload_type_executed;

      this.title = `${fileName} ${CONSTANTS.MONTH_NAMES[file.referenceMonth - 1]} ${
        file.referenceYear
      }`;

      this.squadName = file.squad?.squadName || '';

      this.isVisibleModalApproveFile = true;

      this.validationFileId = file.validationFile.id;
    }
  }

  public showUploadModal(): void {
    this.isVisibleModalUploadFile = true;
  }

  public changeTab(tabIndex: number | undefined): void {
    tabIndex = tabIndex ?? 0;

    this.activeTab = tabIndex;
    this.addPageOnHistory(this.dateRef);
  }

  public onChangeDateReference(result: Date): void {
    this.dateRef = result;

    const onlyDate = this.onlyDateToString(result);
    if (onlyDate !== this.route.snapshot.queryParams['ref_date']) {
      this.isSpinning = true;

      this.addPageOnHistory(result);
      this.getFiles(result);
      this.getDmi(result);

      this.isSpinning = false;
    }
  }

  private onlyDateToString(result: Date): string {
    return new Date(result).toISOString().substring(0, 10);
  }

  private addPageOnHistory(refDate: Date): void {
    this.router.navigate(['/files'], {
      queryParams: {
        active_tab: this.activeTab,
        ref_date: refDate.toISOString().substring(0, 10)
      }
    });
  }

  public disabledDate = (current: Date): boolean => {
    const hasFile = this.monthYearList.find(
      item => item.month === current.getUTCMonth() + 1 && item.year === current.getUTCFullYear()
    );

    return !this.hasFileOrIsSameMonth(!!hasFile, current.getMonth(), current.getUTCFullYear());
  };

  private hasFileOrIsSameMonth(hasfile: boolean, month: number, year: number): boolean {
    const today = new Date();

    return hasfile || (today.getMonth() == month && today.getUTCFullYear() == year);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
