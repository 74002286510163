import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '@core/services/message/message.service';
import { PackageService } from '@core/services/package/package.service';
import { VehicleService } from '@core/services/vehicle/vehicle.service';
import { VehicleModel } from '@models/vehicle';
import { TranslateService } from '@ngx-translate/core';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject, takeUntil } from 'rxjs';
import { PackageComponentService } from '../package-component.service';

@Component({
  selector: 'app-package-form',
  templateUrl: './package-form.component.html',
  styleUrls: ['./package-form.component.scss']
})
export class PackageFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  vehicles: VehicleModel[] = [];

  disabledSaveBtn = true;

  hasMedia = true;

  isSpinning = false;

  autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    private vehicleService: VehicleService,
    private packageService: PackageService,
    private packageComponentService: PackageComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.validationForm = this.formBuilder.group({
      vehicleId: [null],
      packageName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
  }

  getData(): void {
    this.packageComponentService.isFormLoading.next(true);
    this.vehicleService
      .getAllVehicles()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: vehicles => {
          this.vehicles = vehicles;
          this.packageComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.packageComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  cleanForm(): void {
    this.validationForm.controls['packageName'].setValue('');
    this.validationForm.controls['packageName'].reset();
    this.validationForm.controls['vehicleId'].setValue('');
    this.validationForm.controls['vehicleId'].reset();
    this.hasMedia = true;
  }

  save(): void {
    this.packageComponentService.isFormLoading.next(true);

    const vehicleId = this.validationForm.get('vehicleId')?.value;
    const packageName = this.validationForm.get('packageName')?.value;

    this.packageService
      .savePackage(packageName, vehicleId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['packageName'].reset();

          this.getData();

          this.packageComponentService.updatePackagesGrid.next(true);

          this.packageComponentService.isFormLoading.next(false);

          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.packageComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
