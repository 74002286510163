import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CampaignService } from '@core/services/campaign/campaign.service';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { CampaignCompleteModel } from '@models/index';
import { CampaignComponentService } from '@pages/registrations/campaign/campaign-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-campaign-data-view',
  templateUrl: './campaign-data-view.component.html',
  styleUrls: ['./campaign-data-view.component.scss']
})
export class CampaignDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private campaignsData: CampaignCompleteModel[] = [];

  loading = false;

  campaigns: CampaignCompleteModel[] = [];

  campaign: CampaignCompleteModel = {} as CampaignCompleteModel;

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private campaignService: CampaignService,
    private message: MessageService,
    private modalService: ModalService,
    private campaignComponentService: CampaignComponentService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.campaigns = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.campaigns = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): CampaignCompleteModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.campaignsData
      : this.campaignsData.filter(
          campaign =>
            StringHelper.includesNormalized(campaign.description, termNormalized) ||
            StringHelper.includesNormalized(campaign.product.productName, termNormalized) ||
            StringHelper.includesNormalized(campaign.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(campaign.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): CampaignCompleteModel[] {
    return direction === Ordination.Last
      ? this.campaigns.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.campaigns.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.campaignService
      .getCampaigns('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (campaigns: CampaignCompleteModel[]) => {
          this.campaignsData = campaigns;
          this.campaigns = this.search === '' ? campaigns : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.campaignComponentService.updateCampaignGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(campaignId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.campaignService
        .deleteCampaign(campaignId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Campaign deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(campaign: CampaignCompleteModel): void {
    this.campaign = campaign;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(campaign.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
