import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { FileTypeModel } from '@models/fileTypes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileTypeService {
  constructor(private http: HttpClient) {}

  public get(): Observable<FileTypeModel[]> {
    const route = `${baseWebApiURL}/v1/FileType`;

    return this.http.get<FileTypeModel[]>(route);
  }
}
