<div class="file-card">
  <nz-card [nzBordered]="false">
    <div class="cardTilte">
      <span class="cardTilte--squad">
        <span>{{ 'Squad' | translate }}:</span><b class="font-size-16"> {{ squadName }}</b>
      </span>
      <span>
        <span>{{ 'Month' | translate }}:</span
        ><b class="font-size-16 text-capitalize"> {{ displayDate }}</b>
      </span>
    </div>
    <div class="d-flex file-card--steps">
      <div class="col-12 col-lg-9 mb-3">
        <nz-steps [nzCurrent]="step" [nzProgressDot]="progressTemplate">
          <nz-step [nzTitle]="upload"> </nz-step>
          <nz-step *ngIf="file.validationFile" [nzTitle]="correction"></nz-step>
          <nz-step *ngIf="file.validationFile" [nzTitle]="approval"></nz-step>
          <nz-step
            *ngIf="file.fileType != financialFileTypes.MediaHub"
            [nzTitle]="consolidated"
          ></nz-step>
        </nz-steps>

        <ng-template #upload>
          {{ 'Upload_step' | translate }}
          <span
            nz-tooltip
            nzTooltipTitle="{{ 'By' | translate }}: {{ this.file.updatedBy }}, {{
              'in' | translate
            }}: {{ this.file.updatedAt | date : 'dd/MM/yyyy HH:mm' }}"
          >
            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          </span>
        </ng-template>

        <ng-template #correction>
          {{ 'Correction_step' | translate }}
          <span
            *ngIf="file.validationFile && file.validationFile?.status != 0 && step > 1"
            nz-tooltip
            nzTooltipTitle="{{ 'By' | translate }}: {{ this.file.validationFile.correctedBy }}, {{
              'in' | translate
            }}: {{ this.file.validationFile.correctedAt | date : 'dd/MM/yyyy HH:mm' }}"
          >
            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          </span>
        </ng-template>

        <ng-template #approval>
          {{ 'Approval_step' | translate }}
          <span
            *ngIf="file.validationFile && file.validationFile.status === 2"
            nz-tooltip
            nzTooltipTitle="{{ 'By' | translate }}: {{ this.file.validationFile.approvedBy }}, {{
              'in' | translate
            }}: {{ this.file.validationFile.approvedAt | date : 'dd/MM/yyyy HH:mm' }}"
          >
            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          </span>
        </ng-template>

        <ng-template #consolidated>
          {{ 'Consolidated_step' | translate }}
          <span
            *ngIf="consolidatedStatus === 3"
            nz-tooltip
            nzTooltipTitle="{{ 'By' | translate }}: {{ this.consolidatedFile?.updatedBy }}, {{
              'in' | translate
            }}: {{ this.consolidatedFile?.updatedAt | date : 'dd/MM/yyyy HH:mm' }}"
          >
            <span class="fileCard--icon" nz-icon nzType="info-circle" nzTheme="outline"></span>
          </span>
        </ng-template>

        <ng-template #progressTemplate let-dot let-status="status" let-index="index">
          <span style="margin-left: -100%">
            <ng-template [ngTemplateOutlet]="dot"></ng-template>
          </span>
        </ng-template>
      </div>
      <div class="col-12 col-lg-3">
        <div class="d-flex justify-content-end mb-3">
          <app-button
            nzTooltipTitle="{{
              consolidatedStatus === ConsolidatedStatus.Generated ||
              consolidatedStatus === ConsolidatedStatus.Approved ||
              consolidatedStatus === ConsolidatedStatus.Rejected
                ? ('Consolidated_has_already_been_generated' | translate)
                : file.status === ConsolidatedStatus.Error
                ? ('Fix_file_before_update_budget' | translate)
                : null
            }}"
            nzTooltipPlacement="top"
            nz-tooltip
            [routerLink]="['/files/update_column']"
            [queryParams]="{
              financial_file_id: file.id,
              ref_date: dateRef.toISOString().substring(0, 10)
            }"
            *ngIf="
              (userGroup === userGroups.analyst ||
                userGroup === userGroups.admin ||
                userGroup === userGroups.squad) &&
              file.fileType === FinancialFileTypes.Executed
            "
            type="default"
            [disabled]="
              consolidatedStatus === ConsolidatedStatus.Generated ||
              file.status === ConsolidatedStatus.Error ||
              consolidatedStatus === ConsolidatedStatus.Approved ||
              consolidatedStatus === ConsolidatedStatus.Rejected
            "
          >
            {{ 'Update_budget' | translate }}
          </app-button>
        </div>
        <div class="d-flex justify-content-end">
          <app-button
            [routerLink]="['/files/inconsistencies', file.validationFile.id]"
            *ngIf="
              file.validationFile &&
              file.validationFile?.status === InconsistencyStatus.None &&
              file.status === ConsolidatedStatus.Error
            "
            type="primary"
            class="me-2"
          >
            {{ 'Fix_file' | translate }}
          </app-button>

          <app-button
            type="primary"
            *ngIf="
              (userGroup === userGroups.analyst ||
                userGroup === userGroups.admin ||
                userGroup === userGroups.squad) &&
              file.validationFile &&
              file.validationFile?.status === 1 &&
              file.status === 9
            "
            (click)="showApproveModal()"
            class="me-2"
          >
            {{ 'Approve_file' | translate }}
          </app-button>

          <app-button (click)="downloadFile(file)" type="default" [loading]="isDownloading">
            <span class="material-icons-outlined md-24">file_download</span>
          </app-button>
        </div>
      </div>
    </div>
  </nz-card>
</div>
<app-modal-approve-file
  [isVisible]="isVisibleModalApproveFile"
  (isVisibleModalChange)="isVisibleModalApproveFile = $event"
  [title]="title"
  [squadName]="squadName"
  [validationFileId]="validationFileId"
>
</app-modal-approve-file>
