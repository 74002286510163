<nz-modal
  ngClass="modal-update-header"
  nzWidth="600px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="onClose()"
  [(nzVisible)]="isVisible"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-update-title">
      <span>{{ 'Change' | translate }}</span>
      <span class="ms-1 text-capitalize"> {{ column | translate }}</span>
    </div>
  </ng-container>
  <ng-container *nzModalContent>
    <nz-spin nzSize="large" [nzSpinning]="isSpinningModal">
      <form nz-form [formGroup]="validationFormUpdate">
        <div class="px-5">
          <nz-row nzGutter="16" class="mb-2">
            <nz-col [nzXs]="{ span: 24 }">
              <div>
                <nz-form-control [nzSpan]="24">
                  <input
                    nz-input
                    formControlName="value"
                    placeholder="{{ 'Enter_new_value_to' | translate }} {{ column | translate }}"
                  />
                </nz-form-control>
              </div>
            </nz-col>
          </nz-row>
          <nz-row nzGutter="16">
            <nz-col [nzXs]="{ span: 24 }">
              <div>
                <div>{{ 'This_change_will_be_applied_in_the_following_lines' | translate }}:</div>
                <div class="fw-bold">{{ linesString }}</div>
              </div>
            </nz-col>
          </nz-row>
        </div>
      </form>
    </nz-spin>
  </ng-container>
  <ng-container *nzModalFooter>
    <nz-row *ngIf="!isSpinningModal" nzGutter="16">
      <nz-col [nzXs]="{ span: 24 }">
        <div class="d-flex justify-content-end mt-2">
          <app-button type="link" (click)="cleanForm()">
            {{ 'Clear' | translate }}
          </app-button>
          <app-button
            class="ms-2"
            type="primary"
            [disabled]="!validationFormUpdate.valid"
            (click)="apply()"
          >
            {{ 'Apply' | translate }}
          </app-button>
        </div>
      </nz-col>
    </nz-row>
  </ng-container>
</nz-modal>
