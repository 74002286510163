import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { CampaignModel } from '@models/campaign';
import { ProductCreateModel, ProductModel } from '@models/product';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) {}

  public getCampaignByProduct(productId: string): Observable<CampaignModel[]> {
    return this.http.get<CampaignModel[]>(`
    ${baseWebApiURL}/v1/Products/${productId}/campaigns`);
  }

  public getProducts(): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(`${baseWebApiURL}/v1/Products`);
  }

  public listProducts(
    columnToOrder: string,
    descendingDirection: boolean,
    length: number
  ): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(
      `${baseWebApiURL}/v1/Products/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}&length=${length}`
    );
  }

  public saveProduct(product: ProductCreateModel): Observable<ProductModel> {
    return this.http.post<ProductModel>(`${baseWebApiURL}/v1/Products`, product);
  }

  public deleteProduct(productId: string): Observable<ProductModel> {
    return this.http.delete<ProductModel>(
      `${baseWebApiURL}/v1/Products/${productId}`
    );
  }
}
