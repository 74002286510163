import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { Observable } from 'rxjs';
import {
  ComparativeCampaignResponse,
  ComparativePackageResponse
} from '../../../shared/models/comparative';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ComparativeService {
  constructor(public configService: ConfigService, private http: HttpClient) {}

  public getComparativeByCampaign(
    financialFileId: string,
    page?: number,
    pageSize?: number
  ): Observable<ComparativeCampaignResponse> {
    return this.http.get<ComparativeCampaignResponse>(
      `${baseWebApiURL}/v1/comparative/campaign?financialFileId=${financialFileId}${
        page ? '&page=' + page : ''
      }${pageSize ? '&pageSize=' + pageSize : ''}
      `
    );
  }

  public getComparativeByPackage(
    financialFileId: string,
    squadId: string,
    campaignId: string,
    page?: number,
    pageSize?: number
  ): Observable<ComparativePackageResponse> {
    return this.http.get<ComparativePackageResponse>(
      `${baseWebApiURL}/v1/comparative/package?financialFileId=${financialFileId}&SquadId=${squadId}&campaignId=${campaignId}${
        page ? '&page=' + page : ''
      }${pageSize ? '&pageSize=' + pageSize : ''}
      `
    );
  }
}
