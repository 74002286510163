import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ExecutedLineService } from '@core/services/executedLine/executedLine.service';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { userGroups } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '@pages/user-info.service';
import {
  ExecutedLineModel,
  GetExecutedLineByFinancialFileIdResponse
} from '@shared/models/executedLine';
import { SelectModel } from '@shared/models/select';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { FinancialFileModel } from '../../shared/models';
interface ResizeEvent {
  target: Window;
}

@Component({
  selector: 'app-update-column',
  templateUrl: './update-column.component.html',
  styleUrls: ['./update-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateColumnComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event']) onResize(event: UIEvent | ResizeEvent): void {
    if (event.target instanceof Window) {
      const resizeEvent: ResizeEvent = {
        target: event.target
      };
      this.widthScroll = resizeEvent.target.innerWidth.toString() + 'px';
    }
  }

  public widthScroll = '';

  public financialFileId = '';

  public financialFile = new FinancialFileModel();

  public executedLines: ExecutedLineModel[] = [];

  public products: SelectModel[] = [];

  public productId = '';

  public campaigns: SelectModel[] = [];

  public campaignId = '';

  public medias: SelectModel[] = [];

  public mediaId = '';

  public vehicles: SelectModel[] = [];

  public vehicleId = '';

  public nroPi = '';

  public validationFormFilter!: FormGroup;

  public currentPageIndex = 1;

  public pageSize = 10;

  public totalRecords = 0;

  public dateRef = new Date();

  public dateRefText = '';

  public isSpinning = false;

  public checkboxSet = new Set<number>();

  public userGroup = '';

  public userGroups = userGroups;

  private readonly _destroying$ = new Subject<void>();

  public isVisibleModalUpdate = false;

  public selectedColumn = '';

  public selectedLines: number[] = [];

  constructor(
    public translate: TranslateService,
    public executedLineService: ExecutedLineService,
    public userInfoService: UserInfoService,
    public message: MessageService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  private getExecutedLinesByFinancialFileId(
    financialFileId: string,
    page: number,
    pageSize: number
  ): void {
    this.isSpinning = true;
    this.checkboxSet.clear();

    this.executedLineService
      .getByFinancialFileId(
        financialFileId,
        page,
        pageSize,
        this.productId,
        this.campaignId,
        this.mediaId,
        this.vehicleId,
        this.nroPi
      )
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response: GetExecutedLineByFinancialFileIdResponse) => {
          this.isSpinning = false;
          this.financialFile = response.financialFile;
          this.totalRecords = response.totalRecords;
          this.executedLines = response.executedLines;
          this.products = response.products;
          this.campaigns = response.campaigns;
          this.medias = response.medias;
          this.vehicles = response.vehicles;
          this.cdr.detectChanges();
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  public onCheckboxChange(lineNumber: number, checked: boolean): void {
    if (checked) {
      this.checkboxSet.add(lineNumber);
    } else {
      this.checkboxSet.delete(lineNumber);
    }
  }

  public onCheckAllChange(checked: boolean): void {
    if (checked) {
      this.executedLines.forEach(line => {
        this.checkboxSet.add(line.lineNumber);
      });
    } else {
      this.checkboxSet.clear();
    }
  }

  public onPageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
    this.getExecutedLinesByFinancialFileId(this.financialFileId, pageIndex, this.pageSize);
  }

  public onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.getExecutedLinesByFinancialFileId(this.financialFileId, 1, pageSize);
  }

  public onClickBudget(): void {
    this.selectedColumn = 'budget';
    this.selectedLines = Array.from(this.checkboxSet);
    this.isVisibleModalUpdate = true;
  }

  private setDateRefText(): void {
    this.dateRefText = `${
      CONSTANTS.MONTH_NAMES[this.dateRef.getMonth()]
    } ${this.dateRef.getUTCFullYear()}`;
  }

  private setUserGroup(): void {
    this.userGroup = this.userInfoService.getUserGroup();
  }

  private setupEventListeners(): void {
    this.route.queryParams.pipe(takeUntil(this._destroying$)).subscribe((params: Params) => {
      this.financialFileId = params['financial_file_id'];
      const defaultRefDate = new Date().toISOString();

      const refDate = params['ref_date'] || defaultRefDate;
      const newDate = new Date(refDate);
      const newDateUTC = new Date(
        newDate.getUTCFullYear(),
        newDate.getUTCMonth(),
        newDate.getUTCDate()
      );

      this.dateRef = newDateUTC;
      this.setDateRefText();
    });
  }

  private updateTable(): void {
    this.executedLineService.updateTable
      .pipe(takeUntil(this._destroying$))
      .subscribe((formGroup: FormGroup) => {
        if (formGroup) {
          this.validationFormFilter = formGroup;
          this.productId = formGroup.get('product')?.value;
          this.campaignId = formGroup.get('campaign')?.value;
          this.mediaId = formGroup.get('media')?.value;
          this.vehicleId = formGroup.get('vehicle')?.value;
          this.nroPi = formGroup.get('nro_pi')?.value;
        }

        this.getExecutedLinesByFinancialFileId(this.financialFileId, 1, this.pageSize);
      });
  }

  ngOnInit(): void {
    this.setUserGroup();

    this.setupEventListeners();

    this.updateTable();

    this.getExecutedLinesByFinancialFileId(this.financialFileId, 1, this.pageSize);

    this.onResize({ target: window, type: 'resize' });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
