import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { ConsolidatedStatus, FinancialFileTypes } from '@shared/enums';
import { FinancialFileResponse, MonthYear } from '@shared/models';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialFileService {
  private headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  };

  constructor(private http: HttpClient, public configService: ConfigService) {}

  public upload(
    file: string | Blob | NzUploadFile,
    financialFileType: FinancialFileTypes,
    squadId: string,
    referenceDate: Date,
    validateSquad: boolean
  ): Observable<any> {
    const formData = new FormData();
    const token = this.configService.getToken();

    if (!token) throw new Error('token inválido');

    formData.append('file', file as Blob);

    const month = referenceDate.getMonth() + 1;
    const year = referenceDate.getFullYear();

    const squadRoute = squadId ? `&squadId=${squadId}` : '';

    return this.http.post(
      `${baseWebApiURL}/v1/FinancialFile?financialFileType=${financialFileType}${squadRoute}&month=${
        month < 10 ? `0${month}` : month
      }&year=${year}&validateSquad=${validateSquad}`,
      formData
    );
  }

  public get(
    referenceDate?: Date | null,
    financialFileType?: FinancialFileTypes,
    status?: ConsolidatedStatus,
    isGeneral?: boolean
  ): Observable<FinancialFileResponse> {
    let month = 0;
    let year = 0;

    if (referenceDate) {
      month = referenceDate.getMonth() + 1;
      year = referenceDate.getFullYear();
    }

    return this.http.get<FinancialFileResponse>(
      `${baseWebApiURL}/v1/FinancialFile?${
        month ? `month=${month < 10 ? `0${month}` : month}` : ''
      }${year ? `&year=${year}` : ''}${
        financialFileType ? `&financialFileType=${financialFileType}` : ''
      }${status ? `&status=${status}` : ''}${isGeneral ? `&isGeneral=${isGeneral}` : ''}`
    );
  }

  public getMonths(
    financialFileType?: FinancialFileTypes,
    status?: ConsolidatedStatus,
    isGeneral?: boolean
  ): Observable<MonthYear[]> {
    return this.http.get<MonthYear[]>(
      `${baseWebApiURL}/v1/FinancialFile/months?${
        financialFileType ? `financialFileType=${financialFileType}` : ''
      }${status ? `&status=${status}` : ''}${isGeneral ? `&isGeneral=${isGeneral}` : ''}`
    );
  }

  public updateFilesSubject = new Subject<void>();
}
