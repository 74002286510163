import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FinancialFileTypes } from '@shared/enums';
import { CorrectionModalType } from '@shared/enums/correction-modal-type';
import { BannerInformationModel, RowsModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { RowStatus } from '../../shared/enums/inconsistency-status.enum';
import { SpreadsheetService } from './spreadsheet.service';

interface ResizeEvent {
  target: Window;
}
@Component({
  selector: 'app-ui-spreadsheet',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.scss']
})
export class SpreadsheetComponent implements OnInit, OnDestroy {
  @Input() fileType: FinancialFileTypes = FinancialFileTypes.Unknown;

  @Input() squadId = '';

  @Input() validationFileId = '';

  @Input() columns: string[] = [];

  @Input() rows: RowsModel[][] = [];

  @Input() bannerInformation: BannerInformationModel = {
    product: 0,
    campaign: 0,
    media: 0,
    network: 0,
    vehicle: 0,
    package: 0,
    purchaseType: 0
  };

  @HostListener('window:resize', ['$event']) onResize(event: UIEvent | ResizeEvent): void {
    if (event.target instanceof Window) {
      const resizeEvent: ResizeEvent = {
        target: event.target
      };
      this.widthScroll = resizeEvent.target.innerWidth.toString() + 'px';
    }
  }

  public widthScroll = '';

  private readonly _destroying$ = new Subject<void>();

  public RowStatus = RowStatus;

  public isVisibleModalProductCampaign = false;

  public isVisibleModalMediaNetworkVehicle = false;

  public isVisibleModalPackage = false;

  public isVisibleModalPurchaseType = false;

  public FinancialFileTypes = FinancialFileTypes;

  public plannedWidthCols: string[] = CONSTANTS.INCONSISTENCIES_PLANNED_WIDTH_COLS;

  public executedWidthCols: string[] = CONSTANTS.INCONSISTENCIES_EXECUTED_WIDTH_COLS;

  public CorrectionModalType = CorrectionModalType;

  constructor(public spreadsheetService: SpreadsheetService) {}

  ngOnInit(): void {
    this.setupEventListeners();
    this.onResize({ target: window, type: 'resize' });
  }

  setupEventListeners(): void {
    this.spreadsheetService.isVisibleModalProductCampaign
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (isVisible: boolean) => {
          this.isVisibleModalProductCampaign = isVisible;
        }
      });

    this.spreadsheetService.isVisibleModalMediaNetworkVehicle
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (isVisible: boolean) => {
          this.isVisibleModalMediaNetworkVehicle = isVisible;
        }
      });

    this.spreadsheetService.isVisibleModalPackage.pipe(takeUntil(this._destroying$)).subscribe({
      next: (isVisible: boolean) => {
        this.isVisibleModalPackage = isVisible;
      }
    });

    this.spreadsheetService.isVisibleModalPurchaseType
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (isVisible: boolean) => {
          this.isVisibleModalPurchaseType = isVisible;
        }
      });

    this.spreadsheetService.fileType = this.fileType;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
