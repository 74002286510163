import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialFileService } from '@core/services/financial-file/financial-file.service';
import { InconsistencyService } from '@core/services/inconsistency/inconsistency.service';
import { MessageService } from '@core/services/message/message.service';
import { InconsistenciesGroupsModel, ItemInconsistencyTableApproved } from '@models/inconsistency';
import { TranslateService } from '@ngx-translate/core';
import {
  InconsistencyErrorStatus,
  InconsistencyGroup,
  InconsistencyStatus
} from '@shared/enums/inconsistency-status.enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-modal-approve-file',
  templateUrl: './modal-approve-file.component.html',
  styleUrls: ['./modal-approve-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalApproveFileComponent implements OnInit, OnDestroy {
  @Input() isVisible = false;

  @Input() title = '';

  @Input() squadName = '';

  @Input() validationFileId = '';

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isSpinning = true;

  public disabledButtons = true;

  public showButtons = false;

  public showTable = false;

  public showSuccessMessage = false;

  public showErrorMessage = false;

  public showReproveMessage = false;

  private readonly _destroying$ = new Subject<void>();

  public inconsistenciesTable: ItemInconsistencyTableApproved[] = [];

  public indeterminate = false;

  public allChecked = false;

  public InconsistencyGroup = InconsistencyGroup;

  public InconsistencyErrorStatus = InconsistencyErrorStatus;

  private inconsistencies: InconsistenciesGroupsModel | undefined;

  private approve = false;

  private reprove = false;

  constructor(
    public translate: TranslateService,
    public financialFileService: FinancialFileService,
    private message: MessageService,
    private inconsistencyService: InconsistencyService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getInconsistenciesGroup();
  }

  private getInconsistenciesGroup(): void {
    debugger;
    if (!this.validationFileId) return;

    this.showButtons = false;
    this.isSpinning = true;

    this.inconsistencyService.getByIdGroup(this.validationFileId).subscribe({
      next: response => {
        this.isSpinning = false;
        this.showTable = true;
        this.showButtons = true;

        if (response) {
          this.inconsistencies = response;
          this.fillInconsistenciesTable(response);
        }

        this.changeDetectorRef.detectChanges();
      },
      error: error => {
        this.message.showErrorByStatus(error.status);
        this.isSpinning = false;
      },
      complete: () => {
        this.isSpinning = false;
      }
    });
  }

  private fillInconsistenciesTable(data: InconsistenciesGroupsModel): void {
    let errorsMapped: ItemInconsistencyTableApproved[] = [];

    for (const item of data.inconsistencies) {
      const inconsistencyTableApproved = item.errors.map(
        error =>
          ({
            checked: false,
            inconsistencyGroup: item.inconsistencyGroup,
            lines: error.lines,
            status: error.status,
            product: error.product,
            campaign: error.campaign,
            media: error.media,
            network: error.network,
            vehicle: error.vehicle,
            package: error.package,
            purchaseType: error.purchaseType
          } as ItemInconsistencyTableApproved)
      );

      errorsMapped = [...errorsMapped, ...inconsistencyTableApproved];
    }

    this.inconsistenciesTable = errorsMapped;
  }

  public updateAllChecked(): void {
    this.indeterminate = false;
    this.disabledButtons = !this.allChecked;
    if (this.allChecked) {
      this.inconsistenciesTable = this.inconsistenciesTable
        .filter(item => item.status === InconsistencyErrorStatus.Corrected)
        .map(item => ({
          ...item,
          checked: true
        }));
    } else {
      this.inconsistenciesTable = this.inconsistenciesTable
        .filter(item => item.status === InconsistencyErrorStatus.Corrected)
        .map(item => ({
          ...item,
          checked: false
        }));
    }
  }

  public updateSingleChecked(): void {
    if (this.inconsistenciesTable.every(item => !item.checked)) {
      this.allChecked = false;
      this.indeterminate = false;
      this.disabledButtons = true;
    } else if (this.inconsistenciesTable.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
      this.disabledButtons = false;
    } else {
      this.indeterminate = true;
      this.disabledButtons = false;
    }
  }

  private clearIconsistencies(): void {
    this.inconsistencies?.inconsistencies.forEach(item => {
      item.errors = [];
    });
  }

  private setStatusInconsistenciesApprovedAndReproved(): void {
    this.clearIconsistencies();
    this.inconsistenciesTable.forEach(item => {
      const inconsistenciesGrouped = this.inconsistencies?.inconsistencies.find(
        inconsistency => inconsistency.inconsistencyGroup === item.inconsistencyGroup
      );

      let statusAux = item.status;

      if (item.checked) {
        if (this.approve) {
          statusAux = InconsistencyErrorStatus.Approved;
        }

        if (this.reprove) {
          statusAux = InconsistencyErrorStatus.Pending;
          if (item.product) {
            item.product.correct = '';
            if (item.product.hasError) item.product.id = null;
          }
          if (item.campaign) {
            item.campaign.correct = '';
            if (item.campaign.hasError) item.campaign.id = null;
          }
          if (item.media) {
            item.media.correct = '';
            if (item.media.hasError) item.media.id = null;
          }
          if (item.network) {
            item.network.correct = '';
            if (item.network.hasError) item.network.id = null;
          }
          if (item.vehicle) {
            item.vehicle.correct = '';
            if (item.vehicle.hasError) item.vehicle.id = null;
          }
          if (item.package) {
            item.package.correct = '';
            if (item.package.hasError) item.package.id = null;
          }
          if (item.purchaseType) {
            item.purchaseType.correct = '';
            if (item.purchaseType.hasError) item.purchaseType.id = null;
          }
        }
      }

      inconsistenciesGrouped?.errors.push({
        status: statusAux,
        lines: item.lines,
        product: item.product,
        campaign: item.campaign,
        media: item.media,
        network: item.network,
        vehicle: item.vehicle,
        package: item.package,
        purchaseType: item.purchaseType
      });
    });
  }

  public saveApprove(): void {
    this.approve = true;
    this.reprove = false;
    this.sendData();
  }

  public saveReprove(): void {
    this.approve = false;
    this.reprove = true;
    this.sendData();
  }

  public viewInconsisteciesPage(): void {
    this.router.navigate(['/files/inconsistencies', this.inconsistencies?.id]);
  }

  public sendData(): void {
    if (!this.inconsistencies) return;

    this.disabledButtons = true;
    this.isSpinning = true;
    this.setStatusInconsistenciesApprovedAndReproved();

    this.inconsistencyService
      .saveInconsistencyGroup(this.validationFileId, this.inconsistencies)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: response => {
          this.isSpinning = false;
          this.indeterminate = false;

          if (response.status === InconsistencyStatus.Approved) {
            this.showSuccessMessage = true;
            this.showTable = false;
            this.showButtons = false;
          } else {
            this.inconsistenciesTable = [];
            this.inconsistencies = response;
            if (this.approve) {
              this.message.showSuccess(this.translate.instant('Approvals_have_been_saved'));
              this.fillInconsistenciesTable(response);
            }

            if (this.reprove) {
              this.message.showSuccess(this.translate.instant('Reprovals_have_been_saved'));
              this.showReproveMessage = true;
              this.showTable = false;
              this.showButtons = false;
            }
          }

          this.financialFileService.updateFilesSubject.next();

          this.changeDetectorRef.detectChanges();
        },
        error: () => {
          this.isSpinning = false;
          this.showErrorMessage = true;
          this.showTable = false;
          this.showButtons = false;
          this.changeDetectorRef.detectChanges();
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  public afterOpen(): void {
    this.getInconsistenciesGroup();
  }

  public close(): void {
    this.approve = false;
    this.reprove = false;
    this.disabledButtons = true;
    this.allChecked = false;
    this.isVisible = false;
    this.isSpinning = true;
    this.isVisibleModalChange.emit(this.isVisible);
    this.inconsistenciesTable = [];
    this.inconsistencies = undefined;
    this.showButtons = false;
    this.showTable = false;
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
    this.showReproveMessage = false;
    const params = { ...this.route.snapshot.queryParams };
    delete params['open_approve'];
    this.router.navigate([], { queryParams: params });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
