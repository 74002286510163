import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class VehicleComponentService {
  public updateVehiclesGrid = new Subject<boolean>();

  public isFormLoading = new Subject<boolean>();

  public isTableLoading = new Subject<boolean>();
}
