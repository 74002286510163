import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '@core/services/message/message.service';
import { SystemSettingsService } from '@core/services/systemSettings/systemSettings.service';
import { SystemSettingModel, SystemSettingRequest } from '@models/systemSetting';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-configuration-area',
  templateUrl: './configuration-area.component.html',
  styleUrls: ['./configuration-area.component.scss']
})
export class ConfigurationAreaComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public isSpinning = false;

  systemSettingsRequest: SystemSettingRequest[] = [];

  systemSettings: SystemSettingModel[] = [];

  validationForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private systemSettingService: SystemSettingsService,
    private message: MessageService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.getAllData();
  }

  buildForm(): void {
    this.validationForm = this.formBuilder.group({
      secretKey: ['', [Validators.required]],
      siteUrl: ['', [Validators.required]],
      dashboardId: ['', [Validators.required]],
      dashboardCommitmentId: ["", [Validators.required]],
    });
  }

  cleanForm(): void {
    this.validationForm.reset();
  }

  fillForm(): void {
    const secretKey = this.systemSettings.find(
      setting => setting.key === CONSTANTS.METABASE_EMBEDDING_SECRET_KEY
    )?.value;
    const siteUrl = this.systemSettings.find(
      setting => setting.key === CONSTANTS.METABASE_SITE_URL
    )?.value;
    const dashboardId = this.systemSettings.find(
      setting => setting.key === CONSTANTS.METABASE_DASHBOARD_ID
    )?.value;

    const dashboardCommitmentId = this.systemSettings.find(setting => setting.key === CONSTANTS.METABASE_DASHBOARD_COMMITMENT_ID)?.value

    this.validationForm.patchValue({
      secretKey,
      siteUrl,
      dashboardId,
      dashboardCommitmentId
    });
  }

  getAllData(): void {
    this.isSpinning = true;
    this.systemSettingService
      .getAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: systemSettings => {
          this.systemSettings = systemSettings;
          this.fillForm();
          this.isSpinning = false;
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  save(): void {
    this.isSpinning = true;
    this.systemSettingsRequest.push({
      key: CONSTANTS.METABASE_EMBEDDING_SECRET_KEY,
      value: this.validationForm.get('secretKey')?.value
    });
    this.systemSettingsRequest.push({
      key: CONSTANTS.METABASE_SITE_URL,
      value: this.validationForm.get('siteUrl')?.value
    });
    this.systemSettingsRequest.push({
      key: CONSTANTS.METABASE_DASHBOARD_ID,
      value: this.validationForm.get('dashboardId')?.value
    });

    this.systemSettingsRequest.push({
      key: CONSTANTS.METABASE_DASHBOARD_COMMITMENT_ID,
      value: this.validationForm.get('dashboardCommitmentId')?.value
    })

    this.systemSettingService
      .create(this.systemSettingsRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSpinning = false;
          this.message.showSuccess(this.translate.instant('Configuration_saved'));
        },
        error: () => {
          this.isSpinning = false;
          this.message.showError(this.translate.instant('Communication_error'));
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
