import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { InconsistenciesGroupsModel } from '@models/inconsistency';
import { InconsistencyGroup } from '@shared/enums/inconsistency-status.enum';
import { InconsistenciesModel } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InconsistencyService {
  constructor(private http: HttpClient) {}

  public getByIdGroup(
    id: string,
    group?: InconsistencyGroup
  ): Observable<InconsistenciesGroupsModel> {
    let options = {};
    if (group) {
      options = { params: new HttpParams().set('inconsistencyGroup', group) };
    }

    return this.http.get<InconsistenciesGroupsModel>(
      `${baseWebApiURL}/v1/Inconsistencies/${id}/groups`,
      options
    );
  }

  public getById(
    inconsistencyId: string,
    isOnlyInconsistencies = true,
    page?: number,
    pageSize?: number
  ): Observable<InconsistenciesModel> {
    return this.http.get<InconsistenciesModel>(
      `${baseWebApiURL}/v1/inconsistencies/${inconsistencyId}?isOnlyInconsistencies=${isOnlyInconsistencies}${
        page ? '&page=' + page : ''
      }${pageSize ? '&pageSize=' + pageSize : ''}`
    );
  }

  public saveInconsistencyGroup(
    id: string,
    inconsistencies: InconsistenciesGroupsModel,
    group?: InconsistencyGroup
  ): Observable<InconsistenciesGroupsModel> {
    let options = {};
    if (group) {
      options = {
        params: new HttpParams().set('inconsistencyGroup', group),
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        })
      };
    }

    return this.http.post<InconsistenciesGroupsModel>(
      `${baseWebApiURL}/v1/Inconsistencies/${id}/groups`,
      inconsistencies,
      options
    );
  }
}
