import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FilterUpdateComponent } from './filter/filter.component';
import { ModalUpdateComponent } from './modal/modal.component';
import { UpdateColumnComponent } from './update-column.component';

@NgModule({
  declarations: [UpdateColumnComponent, FilterUpdateComponent, ModalUpdateComponent],
  exports: [UpdateColumnComponent, FilterUpdateComponent, ModalUpdateComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzModalModule,
    NzSelectModule,
    NzSpinModule,
    NzTableModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TranslateModule
  ]
})
export class UpdateColumnModule {}
