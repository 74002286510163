import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BannerInformationModel } from '@models/inconsistency';
import {
  FinancialFileTypes,
  OrderColumnsFinancialFileExecuted,
  OrderColumnsFinancialFilePlanned
} from '@shared/enums';
import { RowStatus } from '@shared/enums/inconsistency-status.enum';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'app-spreadsheet-column-header',
  templateUrl: './spreadsheet-column-header.component.html',
  styleUrls: ['./spreadsheet-column-header.component.scss']
})
export class SpreadsheetColumnHeaderComponent implements OnInit, OnChanges {
  @Input() col = '';

  @Input() indexCol = 0;

  @Input() bannerInformation: BannerInformationModel = {
    product: 0,
    campaign: 0,
    media: 0,
    network: 0,
    vehicle: 0,
    package: 0,
    purchaseType: 0
  };

  public RowStatus = RowStatus;

  public columnStatus: RowStatus = RowStatus.None;

  constructor(public spreadsheetService: SpreadsheetService) {}

  ngOnInit(): void {
    this.columnStatus = this.getColumnStatus(this.col);
  }

  ngOnChanges(): void {
    this.columnStatus = this.getColumnStatus(this.col);
  }

  public getColumnStatus(column: string): RowStatus {
    let columnLowerCase = column
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f] /g, '');

    columnLowerCase = columnLowerCase === 'produto' ? 'product' : columnLowerCase;
    columnLowerCase = columnLowerCase === 'campanha' ? 'campaign' : columnLowerCase;
    columnLowerCase = columnLowerCase === 'midia' ? 'media' : columnLowerCase;
    columnLowerCase = columnLowerCase === 'rede' ? 'network' : columnLowerCase;
    columnLowerCase = columnLowerCase === 'veiculo' ? 'vehicle' : columnLowerCase;
    columnLowerCase = columnLowerCase === 'pacote' ? 'package' : columnLowerCase;
    columnLowerCase =
      columnLowerCase === 'tipocompra' || columnLowerCase === 'tipo de compra'
        ? 'purchaseType'
        : columnLowerCase;

    let status = RowStatus.None;

    if (this.bannerInformation?.[columnLowerCase] >= 0) {
      if (this.bannerInformation[columnLowerCase] === 0) {
        status = RowStatus.Corrected;
      } else {
        status = RowStatus.Error;
      }
    }
    return status;
  }

  public showModal(index: number): void {
    const orderColumns =
      this.spreadsheetService.fileType === FinancialFileTypes.Planned ||
      this.spreadsheetService.fileType === FinancialFileTypes.MediaHub
        ? OrderColumnsFinancialFilePlanned
        : OrderColumnsFinancialFileExecuted;

    this.spreadsheetService.isVisibleModalProductCampaign.next(false);
    this.spreadsheetService.isVisibleModalMediaNetworkVehicle.next(false);
    this.spreadsheetService.isVisibleModalPackage.next(false);
    this.spreadsheetService.isVisibleModalPurchaseType.next(false);

    if (index === orderColumns.Product || index === orderColumns.Campaign) {
      this.spreadsheetService.isVisibleModalProductCampaign.next(true);
    }

    if (
      index === orderColumns.Media ||
      index === orderColumns.Network ||
      index === orderColumns.Vehicle
    ) {
      this.spreadsheetService.isVisibleModalMediaNetworkVehicle.next(true);
    }

    if (index === orderColumns.Package) this.spreadsheetService.isVisibleModalPackage.next(true);

    if (index === orderColumns.PurchaseType)
      this.spreadsheetService.isVisibleModalPurchaseType.next(true);
  }
}
