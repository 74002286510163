<nz-table #basicTable [nzFrontPagination]="false" [nzScroll]="{ x: '400px' }" [nzData]="medias">
  <thead>
    <tr>
      <th nzWidth="calc(180px + 5vw)" class="column-header">{{ 'Media' | translate }}</th>
      <th nzWidth="calc(220px + 2vw)" class="column-header">{{ 'CreatedBy' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'CreatedAt' | translate }}</th>
      <th nzWidth="60px"></th>
    </tr>
  </thead>
  <tbody class="column-content">
    <tr *ngFor="let media of medias">
      <td class="column-content__media">{{ media.mediaName }}</td>
      <td>{{ media.createdBy }}</td>
      <td>{{ media.createdAt | date : 'dd/MM/yyyy' }}</td>
      <td>
        <div class="d-flex justify-content-end">
          <app-button type="link" (click)="handleDelete(media)">
            <span class="material-icons-outlined md-24">delete</span>
          </app-button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
