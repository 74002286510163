import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SpreadsheetModule } from '../../components/spreadsheet/spreadsheet.module';
import { InconsistenciesComponent } from './inconsistencies.component';
import { InconsistenciesBannerComponent } from './incosistencies-banner/incosistencies-banner.component';

@NgModule({
  declarations: [InconsistenciesComponent, InconsistenciesBannerComponent],
  exports: [InconsistenciesComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NzCheckboxModule,
    NzGridModule,
    NzIconModule,
    NzLayoutModule,
    NzSpinModule,
    RouterModule,
    SpreadsheetModule,
    TranslateModule
  ]
})
export class InconsistenciesModule {}
