import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { UserService } from '@core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GetAllUsersResponse, GroupModel, SquadModel, UserModel } from '@shared/models';
import { NzTableFilterList } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../../core/services/modal/modal.service';
import { CONSTANTS } from '../../../shared/utils/constants';
import { UserComponentService } from '../user.component.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() groups: GroupModel[] = [];

  @Input() squads: SquadModel[] = [];

  private readonly destroy$ = new Subject<void>();

  constants = CONSTANTS;

  data: GetAllUsersResponse = {
    users: [],
    page: 1,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 1
  };

  user: UserModel = {} as UserModel;

  users: UserModel[] = [];

  isLoadingDelete = false;

  isSpinning = false;

  isVisibleEditModal = false;

  currentPageIndex = 1;

  pageSize = 10;

  isDescendingDirection = false;

  columnToOrder = 'Email';

  sortDirections = ['ascend', 'descend'];

  listOfFilters: NzTableFilterList = [];

  selectedFilters: string[] = [];

  searchValue = '';

  isVisibleSearch = false;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private userComponentService: UserComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.updateTableSubscribe();
    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      1,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
  }

  getData(
    columnToOrder?: string,
    isDescendingDirection?: boolean,
    page?: number,
    pageSize?: number,
    groups?: string[],
    search?: string
  ): void {
    this.isSpinning = true;
    this.userService
      .getAll(columnToOrder, isDescendingDirection, page, pageSize, groups, search)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.data = result;
          this.users = result.users;
          this.isSpinning = false;
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  onPageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      pageIndex,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      1,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
  }

  public onChangeOrder(order: string | null, columnToOrder: string): void {
    if (order == 'ascend') {
      this.isDescendingDirection = false;
    } else {
      this.isDescendingDirection = true;
    }
    this.columnToOrder = columnToOrder;

    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      1,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
  }

  public changeFilters(filters: string[]): void {
    this.selectedFilters = filters;

    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      1,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
  }

  updateTableSubscribe(): void {
    this.userComponentService.updateGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm)
          this.getData(
            this.columnToOrder,
            this.isDescendingDirection,
            1,
            this.pageSize,
            this.selectedFilters,
            this.searchValue
          );
      });
  }

  onDelete(itemId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.userService
        .delete(itemId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('User deleted successfully');
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(item: UserModel): void {
    this.user = item;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(item.id);
        } catch (error) {}
        this.getData(
          this.columnToOrder,
          this.isDescendingDirection,
          1,
          this.pageSize,
          this.selectedFilters,
          this.searchValue
        );
        return true;
      }
    });
  }

  handleEdit(item: UserModel): void {
    this.user = item;
    this.isVisibleEditModal = true;
  }

  handleCancel(): void {
    this.isVisibleEditModal = false;
  }

  setListFilters(): void {
    const squadGroup = this.groups.find(x => x.name == CONSTANTS.GROUP_SQUAD);

    const squadGroups = this.squads.map(x => ({
      text: `Squad - ${x.squadName}`,
      value: `${squadGroup?.id}---${x.id}`
    }));

    const otherGroups = this.groups
      .filter(x => x.name != CONSTANTS.GROUP_SQUAD)
      .map(x => ({
        text: this.translate.instant(x.name),
        value: x.id
      }));

    this.listOfFilters = [...otherGroups, ...squadGroups];
  }

  onSearch(): void {
    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      1,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
    this.isVisibleSearch = false;
  }

  onResetSearch(): void {
    this.searchValue = '';
    this.getData(
      this.columnToOrder,
      this.isDescendingDirection,
      1,
      this.pageSize,
      this.selectedFilters,
      this.searchValue
    );
    this.isVisibleSearch = false;
  }

  ngOnChanges(): void {
    this.setListFilters();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
