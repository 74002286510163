import { Injectable } from '@angular/core';
import { FinancialFileModel } from '@models/financial-file';
import { Subject } from 'rxjs';

@Injectable()
export class FilesComponentService {
  public loading = new Subject<boolean>();

  public plannedFiles = new Subject<FinancialFileModel[]>();
}
