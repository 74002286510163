import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { InconsistencyService } from '@core/services/inconsistency/inconsistency.service';
import { MessageService } from '@core/services/message/message.service';
import { PackageService } from '@core/services/package/package.service';
import {
  InconsistenciesGroupsModel,
  InconsistencyGroupErrorModel
} from '@models/inconsistency/inconsistency.model';
import {
  InconsistencyErrorStatus,
  InconsistencyGroup
} from '@shared/enums/inconsistency-status.enum';
import { Subject, takeUntil } from 'rxjs';
import { PackageModel } from '../../../shared/models';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'app-form-package',
  templateUrl: './form-package.component.html',
  styleUrls: ['./form-package.component.scss']
})
export class FormPackageComponent implements OnDestroy {
  @Input() validationFileId = '';

  @Input() isVisible = false;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _destroying$ = new Subject<void>();

  public packageInconsistencies: InconsistencyGroupErrorModel[] = [];

  public isSpinning = true;

  public disabledSaveBtn = true;

  public groupInconsistencies?: InconsistenciesGroupsModel;

  public packages: PackageModel[] = [];

  public isSavedUpdates = false;

  public isSavedError = false;

  private autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private packageService: PackageService,
    private inconsistencyService: InconsistencyService,
    private spreadsheetService: SpreadsheetService,
    private message: MessageService
  ) {}

  public afterOpen(): void {
    this.getPackages();
  }

  private getInconsistenciesGroup(): void {
    if (!this.validationFileId) return;

    this.inconsistencyService
      .getByIdGroup(this.validationFileId, InconsistencyGroup.Package)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: response => {
          this.groupInconsistencies = response;

          const packageError = response.inconsistencies[0].errors;

          if (packageError.length > 0) {
            this.packageInconsistencies = this.filterPendingErrors(packageError);
          }
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  public getPackages(): void {
    this.isSpinning = true;
    this.packageService.getPackages().subscribe({
      next: data => {
        this.packages = data;
        this.getInconsistenciesGroup();
      },
      error: () => {
        this.isSpinning = false;
      }
    });
  }

  public ngValueChangePackage(): void {
    const isSomeCorrect = Object.values(this.packageInconsistencies).some(
      inconsistency => inconsistency.package.id
    );

    this.disabledSaveBtn = !isSomeCorrect;
  }

  private filterPendingErrors(
    errors: InconsistencyGroupErrorModel[]
  ): InconsistencyGroupErrorModel[] {
    return errors.filter(
      inconsistency => inconsistency.status === InconsistencyErrorStatus.Pending
    );
  }

  private setIdAndCorrect(): void {
    this.packageInconsistencies.forEach(item => {
      if (item.package.id) {
        const packageFound = this.packages.find(packageItem => packageItem.id === item.package.id);

        if (packageFound) {
          item.package.correct = packageFound.description;
          item.status = InconsistencyErrorStatus.Corrected;
        }
      } else {
        item.package.correct = '';
        item.status = InconsistencyErrorStatus.Pending;
      }
    });
  }

  public save(): void {
    if (!this.groupInconsistencies) return;

    this.isSpinning = true;
    this.setIdAndCorrect();

    this.inconsistencyService
      .saveInconsistencyGroup(
        this.validationFileId,
        this.groupInconsistencies,
        InconsistencyGroup.Package
      )
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: result => {
          if (result) {
            this.isSavedUpdates = true;
            this.spreadsheetService.updateInconsistenciesSubject.next();
            this.autoModalTimer = setTimeout(() => {
              this.close();
            }, 5000);
          } else {
            this.isSavedError = true;
          }
        },
        error: () => {
          this.isSpinning = false;
          this.isSavedError = true;
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  public close(): void {
    this.isSpinning = true;
    this.isSavedUpdates = false;
    this.isVisible = false;
    this.disabledSaveBtn = true;
    this.isSavedError = false;
    this.groupInconsistencies = undefined;
    this.packageInconsistencies = [];
    this.packages = [];
    this.isVisibleModalChange.emit(this.isVisible);
    clearTimeout(this.autoModalTimer);
  }

  public back(): void {
    this.isSavedError = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
