import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CampaignService } from '@core/services/campaign/campaign.service';
import { MessageService } from '@core/services/message/message.service';
import { ProductService } from '@core/services/product/product.service';
import { MediaModel } from '@models/media';
import { NetworkModel } from '@models/network';
import { ProductModel } from '@models/product';
import { TranslateService } from '@ngx-translate/core';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject, takeUntil } from 'rxjs';
import { CampaignComponentService } from '../campaign-component.service';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss']
})
export class CampaignFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  medias: MediaModel[] = [];

  products: ProductModel[] = [];

  networks: NetworkModel[] = [];

  disabledSaveBtn = true;

  hasMedia = true;

  isSpinning = false;

  autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private message: MessageService,
    private campaignService: CampaignService,
    private campaignComponentService: CampaignComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.validationForm = this.formBuilder.group({
      product: [null, [Validators.required]],
      campaignName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
  }

  getData(): void {
    this.campaignComponentService.isFormLoading.next(true);
    this.productService
      .getProducts()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: products => {
          this.products = products;
          this.campaignComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.campaignComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  cleanForm(): void {
    this.validationForm.controls['product'].setValue('');
    this.validationForm.controls['product'].reset();
    this.validationForm.controls['campaignName'].setValue('');
    this.validationForm.controls['campaignName'].reset();
  }

  save(): void {
    this.campaignComponentService.isFormLoading.next(true);

    const productId = this.validationForm.get('product')?.value;
    const campaignName = this.validationForm.get('campaignName')?.value;

    this.campaignService
      .saveCampaign(productId, campaignName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['campaignName'].reset();
          this.getData();
          this.campaignComponentService.updateCampaignGrid.next(true);
          this.campaignComponentService.isFormLoading.next(false);
          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.campaignComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
