import { AppNgZorroAntdModule } from './app-ng-zorro-antd.module';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from '@components/components.module';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ErrorInterceptor } from '@core/middlewares/interceptors/error.interceptor';
import { CoreModule } from '@core/core.module';
import { oktaConfig, clarityConfig } from '@env';
import { AuthInterceptor } from '@core/middlewares/interceptors/auth.interceptor';
import { FormsModule } from '@angular/forms';
import { PagesModule } from '@pages/pages.module';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NgxClarityModule } from 'ngx-clarity';

registerLocaleData(localePt);

const oktaAuth = new OktaAuth({
  clientId: oktaConfig.clientId,
  issuer: `https://${oktaConfig.domain}/${oktaConfig.suffixUrl}`,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'email', 'groups', 'profile']
});

@NgModule({
  declarations: [AppComponent],

  imports: [
    NgbModule,
    AppNgZorroAntdModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OktaAuthModule,
    FormsModule,
    CoreModule,
    SharedModule,
    ComponentsModule,
    PagesModule,
    NgxClarityModule.forRoot({
      enabled: clarityConfig.enabled === 'true',
      projectId: clarityConfig.projectId
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true,
      progressBar: true
    }),
    AppRoutingModule
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: ErrorHandler, useClass: ErrorInterceptor },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: NZ_I18N, useValue: pt_BR }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
