import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaService } from '@core/services/media/media.service';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { MediaModel } from '@models/media';
import { Subject, takeUntil } from 'rxjs';
import { MediaComponentService } from '../media-component.service';

@Component({
  selector: 'app-media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.scss']
})
export class MediaGridComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  medias: MediaModel[] = [];

  media: MediaModel = {} as MediaModel;

  isLoadingDelete = false;

  constructor(
    private mediaService: MediaService,
    private mediaComponentService: MediaComponentService,
    private message: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateTable();
  }

  getData(): void {
    this.mediaComponentService.isTableLoading.next(true);
    this.mediaService
      .getOrderedMedias('CreatedAt', true, 5)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (Medias: MediaModel[]) => {
          this.medias = Medias;
          this.mediaComponentService.isTableLoading.next(false);
        },
        error: error => {
          this.mediaComponentService.isTableLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.mediaComponentService.updateMediasGrid
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(mediaId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.mediaService
        .deleteMedia(mediaId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Media deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(media: MediaModel): void {
    this.media = media;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(media.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
