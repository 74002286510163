<app-alert type="danger">
  <div class="scrollError">
    <p class="mb-1" *ngIf="(dmiError.columnsNotFound | objLength) > 0">
      {{ 'Missing_columns' | translate }}
      <b *ngFor="let column of dmiError.columnsNotFound; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + (column | translate)
      }}</b
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>

    <p class="mb-1" *ngIf="(dmiError.emptyCells?.squad | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'Squad' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.squad; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.emptyCells?.product | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'Product' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.product; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.emptyCells?.campaign | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'Campaign' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.campaign; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.emptyCells?.bottler | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'Bottler' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.bottler; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.emptyCells?.bottlingValue | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'BottlingValue' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.bottlingValue; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.emptyCells?.year | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'Year' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.year; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.emptyCells?.package | objLength) > 0">
      {{ 'Empty_cells_in_column' | translate }} <b>{{ 'Package' | translate }}</b
      >:
      <span *ngFor="let rowNumber of dmiError.emptyCells?.package; index as indexOf">{{
        (indexOf === 0 ? '' : ', ') + rowNumber
      }}</span
      >.
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>

    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.squad | objLength) > 0">
      {{ 'O' | translate }} <b>{{ 'Squad' | translate }}</b> {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.squad; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.product | objLength) > 0">
      {{ 'O' | translate }} <b>{{ 'Product' | translate }}</b> {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.product; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.campaign | objLength) > 0">
      {{ 'A' | translate }} <b>{{ 'Campaign' | translate }}</b> {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.campaign; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.bottler | objLength) > 0">
      {{ 'A' | translate }} <b>{{ 'Bottler' | translate }}</b> {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.bottler; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.bottlingValue | objLength) > 0">
      {{ 'O' | translate }} <b>{{ 'BottlingValue' | translate }}</b>
      {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.bottlingValue; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.year | objLength) > 0">
      {{ 'O' | translate }} <b>{{ 'Year' | translate }}</b> {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.year; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
    <p class="mb-1" *ngIf="(dmiError.cellsNotFound?.package | objLength) > 0">
      {{ 'O' | translate }} <b>{{ 'Package' | translate }}</b> {{ 'of_the_cells' | translate }}
      <span *ngFor="let rowNumber of dmiError.cellsNotFound?.package; index as indexOf">{{
        rowNumber + ', '
      }}</span>
      {{ 'is_not_registered_check_the_spelling' | translate }}
    </p>
  </div>
</app-alert>
