import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExecutedLineService } from '@core/services/executedLine/executedLine.service';
import { SelectModel } from '@models/select';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filter-update',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterUpdateComponent implements OnInit, OnDestroy, OnChanges {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  @Input() products: SelectModel[] = [];

  productsFilter: SelectModel[] = [];

  @Input() campaigns: SelectModel[] = [];

  campaignsFilter: SelectModel[] = [];

  @Input() medias: SelectModel[] = [];

  mediasFilter: SelectModel[] = [];

  @Input() vehicles: SelectModel[] = [];

  vehiclesFilter: SelectModel[] = [];

  atLeastOneValueSelected = false;

  valuesAlreadyObtained = false;

  constructor(
    private formBuilder: FormBuilder,
    private executedLineService: ExecutedLineService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      product: [null],
      campaign: [null],
      media: [null],
      vehicle: [null],
      nro_pi: [null]
    });

    this.validationForm.valueChanges.subscribe(validationForm => {
      this.atLeastOneValueSelected =
        validationForm.product ||
        validationForm.campaign ||
        validationForm.media ||
        validationForm.vehicle ||
        validationForm.nro_pi;
    });
  }

  ngOnChanges(): void {
    if (
      !this.valuesAlreadyObtained &&
      (this.products.length != 0 ||
        this.campaigns.length != 0 ||
        this.medias.length != 0 ||
        this.vehicles.length != 0)
    ) {
      this.productsFilter = this.products;
      this.mediasFilter = this.medias;
      this.campaignsFilter = this.campaigns;
      this.vehiclesFilter = this.vehicles;

      this.valuesAlreadyObtained = true;
    }
  }

  onProductChange(productId: string): void {
    if (productId) {
      this.filterCampaignsByProductId(productId);
      this.validationForm.get('campaign')?.reset();
    } else {
      this.campaignsFilter = this.campaigns;
    }
  }

  onMediaChange(mediaId: string): void {
    if (mediaId) {
      this.filterVehiclesByMediaId(mediaId);
      this.validationForm.get('vehicle')?.reset();
    } else {
      this.vehiclesFilter = this.vehicles;
    }
  }

  filterCampaignsByProductId(productId: string): void {
    this.campaignsFilter = this.campaigns.filter(campaign => campaign.productId === productId);
  }

  filterVehiclesByMediaId(mediaId: string): void {
    this.vehiclesFilter = this.vehicles.filter(vehicle => vehicle.mediaId === mediaId);
  }

  cleanForm(): void {
    this.validationForm.reset();
    this.executedLineService.updateTable.next(this.validationForm);
  }

  filter(): void {
    this.executedLineService.updateTable.next(this.validationForm);
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
