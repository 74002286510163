import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExecutedLineService } from '@core/services/executedLine/executedLine.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from '../../../core/services/message/message.service';

@Component({
  selector: 'app-modal-update',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalUpdateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isVisible = false;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() financialFileId = '';

  @Input() lines: number[] = [];

  @Input() column = '';

  @Input() validationFormFilter!: FormGroup;

  public isSpinningModal = false;

  private readonly destroy$ = new Subject<void>();

  public validationFormUpdate!: FormGroup;

  public linesString = '';

  constructor(
    private formBuilder: FormBuilder,
    private executedLineService: ExecutedLineService,
    public translate: TranslateService,
    public message: MessageService,

    public cdr: ChangeDetectorRef
  ) {}

  cleanForm(): void {
    this.validationFormUpdate.reset();
  }

  apply(): void {
    this.isSpinningModal = true;

    this.executedLineService
      .update(this.financialFileId, this.column, this.validationFormUpdate.value.value, this.lines)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSpinningModal = false;
          this.cleanForm();
          this.onClose();
          this.message.showSuccess('Changes_successfully_applied');
          this.executedLineService.updateTable.next(this.validationFormFilter);
        },
        error: error => {
          this.isSpinningModal = false;
          this.message.showErrorByStatus(error.status);
          this.cdr.detectChanges();
        }
      });
  }

  public onClose(): void {
    this.isVisible = false;
    this.isVisibleModalChange.emit(this.isVisible);
  }

  ngOnChanges(): void {
    this.linesString = this.lines.map(e => e + 1).join(', ');
  }

  ngOnInit(): void {
    this.validationFormUpdate = this.formBuilder.group({
      value: [null, [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
