import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApprovedRulesService } from '@core/services/approvedRule/approvedRule.service';
import { InconsistencyGroupsService } from '@core/services/inconsistencyGroups/inconsistencyGroups.service';
import { MediaService } from '@core/services/media/media.service';
import { MessageService } from '@core/services/message/message.service';
import { PackageService } from '@core/services/package/package.service';
import { ProductService } from '@core/services/product/product.service';
import { PurchaseTypeService } from '@core/services/purchase-type/purchase-type.service';
import { CampaignModel } from '@models/campaign';
import { ApprovedRuleModel } from '@models/fromToRules/fromToRules.model';
import { InconsistencyGroups } from '@models/inconsistencyGroups';
import { MediaModel } from '@models/media';
import { NetworkModel } from '@models/network';
import { PackageModel } from '@models/package';
import { ProductModel } from '@models/product';
import { PurchaseTypeModel } from '@models/purchase-type';
import { VehicleModel } from '@models/vehicle';
import { MediaComponentService } from '@pages/registrations/media/media-component.service';
import { PackageComponentService } from '@pages/registrations/package/package-component.service';
import { ProductComponentService } from '@pages/registrations/product/product-component.service';
import { PurchaseTypeComponentService } from '@pages/registrations/purchase-type/purchase-type-component.service';
import { InconsistencyGroup } from '@shared/enums/inconsistency-status.enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-modal-new-from-to',
  templateUrl: './modal-new-from-to.component.html',
  styleUrls: ['./modal-new-from-to.component.scss']
})
export class ModalNewFromToComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validateForm!: FormGroup;

  @Input() isVisible = false;

  isSpinning = false;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  typeSelectedName!: string;

  typeSelectedId!: number;

  inconsistencyGroups: InconsistencyGroups[] = [];

  InconsistencyGroup = InconsistencyGroup;

  listFromToType: InconsistencyGroups[] = [];

  products: ProductModel[] = [];

  campaigns: CampaignModel[] = [];

  packages: PackageModel[] = [];

  purchaseTypes: PurchaseTypeModel[] = [];

  medias: MediaModel[] = [];

  correctPackageValue!: PackageModel;

  correctProductValue!: ProductModel;

  correctCampaignValue!: CampaignModel;

  correctMediaValue!: MediaModel;

  correctNetworkValue!: NetworkModel;

  correctVehicleValue!: VehicleModel;

  correctPurchaseTypeValue!: PurchaseTypeModel;

  productCampaigns!: CampaignModel[];

  mediaNetworks!: NetworkModel[];

  networkVehicles!: VehicleModel[];

  fieldsFormToValidate!: string[];

  @Output() ruleAdded_UpdateTable: EventEmitter<boolean> = new EventEmitter<boolean>();

  addedAtLeastOneRule = false;

  ruleWasEmitted = false;

  showSuccessMessage = false;

  showErrorMessage = false;

  constructor(
    private fb: FormBuilder,
    private fromToTypeService: InconsistencyGroupsService,
    private productService: ProductService,
    private productComponentService: ProductComponentService,
    private packageService: PackageService,
    private packageComponentService: PackageComponentService,
    private purchaseTypeComponentService: PurchaseTypeComponentService,
    private purchaseTypeService: PurchaseTypeService,
    private mediaService: MediaService,
    private mediaComponentService: MediaComponentService,
    private approvedRulesService: ApprovedRulesService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      inconsistencyGroup: [null, [Validators.required]],

      // Package
      packageId: [null],
      wrongPackage: [null],
      correctPackage: [null],

      // Product/Campaign
      productId: [null],
      wrongProduct: [null],
      correctProduct: [null],

      campaignId: [null],
      wrongCampaign: [null],
      correctCampaign: [null],

      // Media/Network/Vehicle
      mediaId: [null],
      wrongMedia: [null],
      correctMedia: [null],

      networkId: [null],
      wrongNetwork: [null],
      correctNetwork: [null],

      vehicleId: [null],
      wrongVehicle: [null],
      correctVehicle: [null],

      // Purchase Type
      purchaseTypeId: [null],
      wrongPurchaseType: [null],
      correctPurchaseType: [null]
    });
  }

  ngAfterViewInit(): void {
    this.getTypes();
  }

  getTypes(): void {
    this.isSpinning = true;
    this.fromToTypeService.get().subscribe({
      next: typeList => {
        this.isSpinning = false;
        this.listFromToType = typeList;
      },
      error: error => {
        this.isSpinning = false;
        this.message.showErrorByStatus(error.status);
        this.showErrorMessage = true;
      }
    });
  }

  setFormValidation(inconsistencyGroup: InconsistencyGroup): void {
    switch (inconsistencyGroup) {
      case InconsistencyGroup.Package:
        this.fieldsFormToValidate = ['wrongPackage', 'correctPackage'];

        break;
      case InconsistencyGroup.ProductCampaign:
        this.fieldsFormToValidate = [
          'wrongProduct',
          'correctProduct',
          'wrongCampaign',
          'correctCampaign'
        ];
        break;
      case InconsistencyGroup.MediaNetworkVehicle:
        this.fieldsFormToValidate = [
          'wrongMedia',
          'correctMedia',
          'wrongNetwork',
          'correctNetwork',
          'wrongVehicle',
          'correctVehicle'
        ];
        break;
      case InconsistencyGroup.PurchaseType:
        this.fieldsFormToValidate = ['wrongPurchaseType', 'correctPurchaseType'];
        break;
    }

    for (const field of Object.keys(this.validateForm.controls)) {
      const control = this.validateForm.get(field);
      if (control) {
        if (this.fieldsFormToValidate.includes(field)) {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    }
  }

  async getFormData(inconsistencyGroup: InconsistencyGroup): Promise<void> {
    switch (inconsistencyGroup) {
      case InconsistencyGroup.Package:
        if (this.packages.length === 0) {
          this.isSpinning = true;
          await this.getPackageData();
        }
        break;
      case InconsistencyGroup.ProductCampaign:
        if (this.products.length === 0) {
          this.isSpinning = true;
          await this.getProductData();
        }
        break;
      case InconsistencyGroup.MediaNetworkVehicle:
        if (this.medias.length === 0) {
          this.isSpinning = true;
          await this.getMediaData();
        }
        break;
      case InconsistencyGroup.PurchaseType:
        if (this.purchaseTypes.length === 0) {
          this.isSpinning = true;
          await this.getPurchaseTypeData();
        }
        break;
    }
    this.isSpinning = false;
  }

  getPackageData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.packageComponentService.loading.next(true);

      this.packageService
        .getPackages()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (packages: PackageModel[]) => {
            this.packages = packages;
            this.packageComponentService.loading.next(false);
            resolve();
          },
          error: error => {
            this.isSpinning = false;
            this.packageComponentService.loading.next(false);
            this.message.showErrorByStatus(error.status);
            reject();
          }
        });
    });
  }

  getProductData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.productComponentService.loading.next(true);
      this.productService
        .getProducts()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (products: ProductModel[]) => {
            this.products = products;
            this.productComponentService.loading.next(false);
            resolve();
          },
          error: error => {
            this.isSpinning = false;
            this.productComponentService.loading.next(false);
            this.message.showErrorByStatus(error.status);
            reject();
          }
        });
    });
  }

  getMediaData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.mediaComponentService.loading.next(true);

      this.mediaService
        .getMedias()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (Medias: MediaModel[]) => {
            this.medias = Medias;
            this.mediaComponentService.loading.next(false);
            resolve();
          },
          error: error => {
            this.isSpinning = false;
            this.mediaComponentService.loading.next(false);
            this.message.showErrorByStatus(error.status);
            reject();
          }
        });
    });
  }

  getPurchaseTypeData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.purchaseTypeComponentService.loading.next(true);

      this.purchaseTypeService
        .getPurchaseTypes()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (PurchaseTypes: PurchaseTypeModel[]) => {
            this.purchaseTypes = PurchaseTypes;
            this.purchaseTypeComponentService.loading.next(false);
            resolve();
          },
          error: error => {
            this.isSpinning = false;
            this.purchaseTypeComponentService.loading.next(false);
            this.message.showErrorByStatus(error.status);
            reject();
          }
        });
    });
  }

  onProductChange(value: ProductModel): void {
    this.filterProductById(value?.id);
    this.validateForm.get('campaignId')?.reset();
    this.validateForm.get('correctCampaign')?.reset();
  }

  onMediaChange(value: MediaModel): void {
    this.filterMediaById(value?.id);
    this.validateForm.get('networkId')?.reset();
    this.validateForm.get('correctNetwork')?.reset();
    this.validateForm.get('vehicleId')?.reset();
    this.validateForm.get('correctVehicle')?.reset();
  }

  onNetworkChange(value: NetworkModel): void {
    this.filterNetworkById(value?.id);
    this.validateForm.get('vehicleId')?.reset();
    this.validateForm.get('correctVehicle')?.reset();
  }

  filterProductById(value: string): void {
    this.productCampaigns = this.products.find(prod => prod.id === value)?.campaigns ?? [];
  }

  filterMediaById(value: string): void {
    this.mediaNetworks = this.medias.find(media => media.id === value)?.networks ?? [];
  }

  filterNetworkById(value: string): void {
    this.networkVehicles = this.mediaNetworks.find(network => network.id === value)?.vehicles ?? [];
  }

  submitForm(inconsistencyGroup: InconsistencyGroup): void {
    this.validateInconsistencyForm(inconsistencyGroup);
    const fromToRulesToAdd: ApprovedRuleModel = this.validateForm.value;
    this.isSpinning = true;
    this.approvedRulesService
      .addRule(fromToRulesToAdd)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSpinning = false;
          this.showSuccessMessage = true;
          this.addedAtLeastOneRule = true;
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
          this.showErrorMessage = true;
        }
      });
  }

  validateInconsistencyForm(inconsistencyGroup: InconsistencyGroup): void {
    switch (inconsistencyGroup) {
      case InconsistencyGroup.Package:
        this.validateFormPackage();
        break;
      case InconsistencyGroup.ProductCampaign:
        this.validateFormProductCampaign();
        break;
      case InconsistencyGroup.MediaNetworkVehicle:
        this.validateFormMediaNetworkVehicle();
        break;
      case InconsistencyGroup.PurchaseType:
        this.validateFormPurchaseType();
        break;
    }
  }

  validateFormPackage(): void {
    this.correctPackageValue = this.validateForm.get('correctPackage')?.value;

    this.validateForm.patchValue({
      packageId: this.correctPackageValue?.id,
      correctPackage: this.correctPackageValue?.description
    });
  }

  validateFormProductCampaign(): void {
    this.correctProductValue = this.validateForm.get('correctProduct')?.value;
    this.correctCampaignValue = this.validateForm.get('correctCampaign')?.value;

    this.validateForm.patchValue({
      productId: this.correctProductValue?.id,
      correctProduct: this.correctProductValue?.productName,
      campaignId: this.correctCampaignValue?.id,
      correctCampaign: this.correctCampaignValue?.description
    });
  }

  validateFormMediaNetworkVehicle(): void {
    this.correctMediaValue = this.validateForm.get('correctMedia')?.value;
    this.correctNetworkValue = this.validateForm.get('correctNetwork')?.value;
    this.correctVehicleValue = this.validateForm.get('correctVehicle')?.value;

    this.validateForm.patchValue({
      mediaId: this.correctMediaValue?.id,
      correctMedia: this.correctMediaValue?.mediaName,
      networkId: this.correctNetworkValue?.id,
      correctNetwork: this.correctNetworkValue?.networkName,
      vehicleId: this.correctVehicleValue?.id,
      correctVehicle: this.correctVehicleValue?.vehicleName
    });
  }

  validateFormPurchaseType(): void {
    this.correctPurchaseTypeValue = this.validateForm.get('correctPurchaseType')?.value;

    this.validateForm.patchValue({
      purchaseTypeId: this.correctPurchaseTypeValue?.id,
      correctPurchaseType: this.correctPurchaseTypeValue?.name
    });
  }

  onAddMore(): void {
    this.resetFieldsExceptType();
    this.showSuccessMessage = false;
  }

  onTryAgain(): void {
    this.showErrorMessage = false;
    this.restoreFieldsUserSelected();
  }

  restoreFieldsUserSelected(): void {
    switch (this.typeSelectedId) {
      case InconsistencyGroup.Package:
        this.validateForm.controls['correctPackage'].setValue(this.correctPackageValue);
        break;

      case InconsistencyGroup.ProductCampaign:
        this.validateForm.controls['correctProduct'].setValue(this.correctProductValue);
        this.filterProductById(this.correctProductValue?.id);
        this.validateForm.controls['correctCampaign'].setValue(this.correctCampaignValue);
        break;

      case InconsistencyGroup.MediaNetworkVehicle:
        this.validateForm.controls['correctMedia'].setValue(this.correctMediaValue);
        this.filterMediaById(this.correctMediaValue?.id);
        this.validateForm.controls['correctNetwork'].setValue(this.correctNetworkValue);
        this.filterNetworkById(this.correctNetworkValue?.id);
        this.validateForm.controls['correctVehicle'].setValue(this.correctVehicleValue);
        break;

      case InconsistencyGroup.PurchaseType:
        this.validateForm.controls['correctPurchaseType'].setValue(this.correctPurchaseTypeValue);
        break;
    }
  }

  onCancel(): void {
    this.isVisible = false;
    this.isVisibleModalChange.emit(this.isVisible);
    if (this.addedAtLeastOneRule) {
      this.ruleAdded_UpdateTable.emit(this.addedAtLeastOneRule);
    }
    this.validateForm.reset();
    this.typeSelectedId = 0;
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
    if (this.addedAtLeastOneRule) {
      this.addedAtLeastOneRule = false;
      setTimeout(() => {
        this.ruleAdded_UpdateTable.emit(this.addedAtLeastOneRule);
      }, 100);
    }
    this.isSpinning = false;
  }

  onTypeChange(id: number): void {
    for (const subtype of this.listFromToType) {
      if (subtype.id === id) {
        this.typeSelectedId = id;
        this.typeSelectedName = subtype.name;
        break;
      }
      continue;
    }

    if (id) {
      this.getFormData(id);
      this.resetFieldsExceptType();
    }
  }

  resetFieldsExceptType(): void {
    const fieldsToReset = [
      'packageId',
      'wrongPackage',
      'correctPackage',

      'productId',
      'wrongProduct',
      'correctProduct',

      'campaignId',
      'wrongCampaign',
      'correctCampaign',

      'mediaId',
      'wrongMedia',
      'correctMedia',

      'networkId',
      'wrongNetwork',
      'correctNetwork',

      'vehicleId',
      'wrongVehicle',
      'correctVehicle',

      'purchaseTypeId',
      'wrongPurchaseType',
      'correctPurchaseType'
    ];
    fieldsToReset.forEach(fieldName => {
      this.validateForm.get(fieldName)?.reset();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
