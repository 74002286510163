import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaginationComponent {
  @Input() pageIndex = 1;

  @Input() pageSize: number | null = 10;

  @Input() total = 0;

  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];

  @Input() showSizeChanger = false;

  @Input() showTotal = false;

  @Output() pageIndexChange = new EventEmitter<number>();

  @Output() pageSizeChange = new EventEmitter<number>();

  onPageIndexChange(pageIndex: number): void {
    this.pageIndexChange.emit(pageIndex);
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSizeChange.emit(pageSize);
  }
}
