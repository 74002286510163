<ng-container *ngIf="(isAuthenticated$ | async) === false; else signout">
  <div class="col-left">
    <div class="branding">
      <span class="text-uppercase">the</span>
      <img class="mx-2" alt="" src="assets/img/logo_coca_cola.png" data-preload="true" />
      <span class="text-uppercase">company</span>
    </div>
    <div class="slogan mt-4 bold700">Crafting brands and choices people love</div>
    <div class="my-4">
      <app-button class="w-100" type="primary" (click)="signIn()">Sign In</app-button>
    </div>
  </div>
  <div class="col-right bg-img d-none d-lg-block" id="bg"></div>
</ng-container>

<ng-template #signout>
  <nz-layout class="h-100">
    <nz-header>
      <a class="navbar-brand" routerLink="files"
        >THE<img
          src="./assets/img/logo_coca_cola.png"
          alt=""
          width="100"
          class="d-inline-block align-text-middle mx-1"
        />COMPANY</a
      >
      <div class="float-end">
        <app-ui-user-info></app-ui-user-info>
      </div>
    </nz-header>
    <nz-layout>
      <nz-sider
        nzCollapsible
        [(nzCollapsed)]="isCollapsed"
        nzBreakpoint="lg"
        [nzWidth]="232"
        [nzCollapsedWidth]="83"
        nzTheme="light"
        class="left-menu"
      >
        <ul nz-menu nzMode="inline" class="cm-side-menu box-shadow-inner-top">
          <li nz-menu-item [routerLinkActive]="'ant-menu-item-selected'" routerLink="files">
            <span class="cm-menu-icon material-icons-outlined md-20 align-middle">home</span>
            <span class="cm-menu-label" [hidden]="isCollapsed">{{ 'Home_page' | translate }}</span>
          </li>
          <li
            *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
            nz-menu-item
            [routerLinkActive]="'ant-menu-item-selected'"
            routerLink="dashboard"
          >
            <span class="cm-menu-icon material-icons-outlined md-20 align-middle">dashboard</span>
            <span class="cm-menu-label" [hidden]="isCollapsed">{{ 'Dashboard' | translate }}</span>
          </li>
          <li
            *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
            nz-menu-item
            [routerLinkActive]="'ant-menu-item-selected'"
            routerLink="report"
          >
            <span class="cm-menu-icon material-icons-outlined md-20 align-middle">analytics</span>
            <span class="cm-menu-label" [hidden]="isCollapsed">{{ 'Report' | translate }}</span>
          </li>
          <li
            *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
            nz-menu-item
            [routerLinkActive]="'ant-menu-item-selected'"
            routerLink="rules"
          >
            <span class="cm-menu-icon material-icons-outlined md-20 align-middle">list_alt</span>
            <span class="cm-menu-label" [hidden]="isCollapsed">{{
              'Rules_from_to' | translate
            }}</span>
          </li>
          <li
            *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
            nz-submenu
            [nzTitle]="registration"
          >
            <ng-template #registration>
              <span class="cm-menu-icon material-icons-outlined md-20 align-middle"
                >border_color</span
              >
              <span class="cm-menu-label" [hidden]="isCollapsed">{{
                'Registration' | translate
              }}</span>
            </ng-template>
            <ul>
              <li
                *ngFor="let item of registrationItems"
                nz-menu-item
                [routerLinkActive]="['active']"
                [routerLink]="item.routerLink"
              >
                {{ item.label | translate }}
              </li>
            </ul>
          </li>
          <li *ngIf="userGroup === userGroups.admin" nz-submenu [nzTitle]="settings">
            <ng-template #settings>
              <span class="cm-menu-icon material-icons-outlined md-20 align-middle">settings</span>
              <span class="cm-menu-label" [hidden]="isCollapsed">{{ 'Settings' | translate }}</span>
            </ng-template>
            <ul>
              <li
                *ngFor="let item of settingsItems"
                nz-menu-item
                [routerLinkActive]="['active']"
                [routerLink]="item.routerLink"
              >
                {{ item.label | translate }}
              </li>
            </ul>
          </li>
        </ul>
      </nz-sider>
      <nz-layout class="content">
        <nz-content class="box-shadow-inner-top p-3 h-100 overflow-auto">
          <router-outlet></router-outlet>
        </nz-content>
      </nz-layout>
    </nz-layout>
  </nz-layout>
</ng-template>
