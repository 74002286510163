<form nz-form [formGroup]="validationForm" class="formVehicle">
  <nz-row [nzGutter]="24">
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Media' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
          <nz-select
            nzShowSearch
            nzAllowClear
            formControlName="media"
            (ngModelChange)="changeMedia($event)"
            required
          >
            <nz-option
              *ngFor="let media of medias"
              [nzValue]="media.id"
              [nzLabel]="media.mediaName"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Network' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
          <nz-select
            nzShowSearch
            nzAllowClear
            formControlName="network"
            [nzDisabled]="hasMedia"
            required
          >
            <nz-option
              *ngFor="let network of networks"
              [nzValue]="network.id"
              [nzLabel]="network.networkName"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Vehicle_Name' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback [nzErrorTip]="nameError">
          <input nz-input formControlName="vehicleName" required />
          <ng-template #nameError let-control>
            <ng-container *ngIf="control.hasError('required')">{{
              'Required_field' | translate
            }}</ng-container>
            <ng-container *ngIf="control.hasError('invalid')">{{
              'Invalid_characters' | translate
            }}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <div class="d-flex justify-content-end my-2">
    <app-button class="me-2" type="link" (click)="cleanForm()">
      {{ 'Clean_form' | translate }}
    </app-button>
    <app-button type="primary" [disabled]="!validationForm?.valid" (click)="save()">
      {{ 'Save' | translate }}
    </app-button>
  </div>
</form>
