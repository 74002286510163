import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { NetworkService } from '@core/services/network/network.service';
import { NetworkCompleteModel } from '@models/network';
import { NetworkComponentService } from '@pages/registrations/network/network-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-network-data-view',
  templateUrl: './network-data-view.component.html',
  styleUrls: ['./network-data-view.component.scss']
})
export class NetworkDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private networksData: NetworkCompleteModel[] = [];

  loading = false;

  networks: NetworkCompleteModel[] = [];

  network: NetworkCompleteModel = {} as NetworkCompleteModel;

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private networkService: NetworkService,
    private message: MessageService,
    private networkComponentService: NetworkComponentService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.networks = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.networks = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): NetworkCompleteModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.networksData
      : this.networksData.filter(
          network =>
            StringHelper.includesNormalized(network.networkName, termNormalized) ||
            StringHelper.includesNormalized(network.media.mediaName, termNormalized) ||
            StringHelper.includesNormalized(network.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(network.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): NetworkCompleteModel[] {
    return direction === Ordination.Last
      ? this.networks.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.networks.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.networkService
      .getNetworks('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (networks: NetworkCompleteModel[]) => {
          this.networksData = networks;
          this.networks = this.search === '' ? networks : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.networkComponentService.updateNetworkGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(networkId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.networkService
        .deleteNetwork(networkId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Network deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(network: NetworkCompleteModel): void {
    this.network = network;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(network.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
