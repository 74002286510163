<div class="container-fluid">
  <nz-spin nzSize="large" [nzSpinning]="isSpinning">
    <div class="d-flex justify-content-between mb-3">
      <div nz-col class="cm-page-title mb-0">
        {{ 'Report' | translate }}
      </div>
      <app-button type="default" (click)="onNewReport()">
        {{ 'New_report' | translate }}
      </app-button>
    </div>

    <nz-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <p class="card-text">{{ 'Filter_consolidated_data' | translate }}</p>
        <div class="d-flex flex-grow-1 justify-content-end">
          <app-button
            type="primary"
            (click)="onExport()"
            [disabled]="!financialFile.id || shouldDisableExportButton()"
            [loading]="isDownloading"
          >
            {{ 'Export' | translate }}
          </app-button>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <nz-date-picker
          class="date-picker-files col col-3 mb-3"
          nzMode="month"
          nzFormat="MMMM yyyy"
          nzAllowClear="false"
          [nzDisabledDate]="disabledDate"
          [(ngModel)]="dateRef"
          (ngModelChange)="onChangeDateReference($event)"
        ></nz-date-picker>
      </div>
      <span>{{ 'Columns_on_report' | translate }}</span>
      <div class="columnsRow">
        <nz-checkbox-wrapper style="width: 100%" (nzOnChange)="getSelectedCheckboxes()">
          <div nz-row class="column-gap-parent">
            <div nz-col [nzXs]="{ span: 24 }" [nzXl]="{ span: 12 }" class="d-flex">
              <div nz-row class="column-gap-child">
                <div
                  nz-col
                  [nzXs]="{ span: 12 }"
                  [nzMd]="{ span: 6 }"
                  *ngFor="
                    let column of consolidatedColumns.slice(0, consolidatedColumns.length - 12)
                  "
                >
                  <label nz-checkbox [nzValue]="column.value" [(ngModel)]="column.checked">
                    {{ column.label | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div nz-col [nzXs]="{ span: 24 }" [nzXl]="{ span: 12 }" class="d-flex">
              <div nz-row class="column-gap-child">
                <div
                  nz-col
                  [nzXs]="{ span: 12 }"
                  [nzMd]="{ span: 6 }"
                  *ngFor="
                    let monthColumn of consolidatedColumns.slice(consolidatedColumns.length - 12)
                  "
                >
                  <label
                    nz-checkbox
                    [nzValue]="monthColumn.value"
                    [(ngModel)]="monthColumn.checked"
                  >
                    {{ monthColumn.label | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
      <div>
        <nz-row class="mb-3" nzGutter="16">
          <nz-col class="d-flex align-items-center" [nzXs]="{ span: 24 }" [nzLg]="{ span: 16 }">
            <span class="material-icons md-24 align-self-center me-2">filter_list</span>
            {{ 'Filter_on_column' | translate }}
          </nz-col>
        </nz-row>
        <form nz-form class="cm-report-form flex-grow-1 pe-2" (ngSubmit)="onExport()">
          <nz-row nzGutter="16" class="mb-3" *ngFor="let control of listOfControls; let i = index">
            <nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
              <nz-form-control [nzSpan]="24">
                <div class="d-flex justify-content-end">
                  <span
                    class="cm-item-remove-icon material-icons md-24 align-self-center mx-3"
                    (click)="onRemoveField($event, control)"
                    >close</span
                  >
                  <nz-select
                    name="column{{ i }}"
                    nzShowSearch
                    nzAllowClear
                    [(ngModel)]="control.column"
                    (ngModelChange)="onColumnChange($event, control)"
                    nzLimit="1"
                  >
                    <nz-option
                      *ngFor="let item of consolidatedColumns"
                      [nzValue]="item.value + '-' + item.isFiltrable"
                      [nzLabel]="item.label | translate"
                      [nzHide]="item.isHidden || !item.isFiltrable"
                    ></nz-option>
                  </nz-select>
                </div>
              </nz-form-control>
            </nz-col>
            <nz-col *ngIf="control.isFiltrable" [nzXs]="{ span: 24 }" [nzLg]="{ span: 16 }">
              <nz-form-control [nzSpan]="24">
                <nz-select
                  name="values{{ i }}"
                  nzShowSearch
                  nzAllowClear
                  nzMode="multiple"
                  [(ngModel)]="control.selectedValues"
                  (ngModelChange)="onValuesChange($event, control)"
                  [nzDisabled]="!control.column"
                >
                  <nz-option *ngIf="control.isLoadingValues" nzDisabled nzCustomContent>
                    <span nz-icon nzType="loading" class="loading-icon"></span>
                    {{ 'Loading' | translate }}
                  </nz-option>
                  <ng-container *ngFor="let item of control.values">
                    <nz-option
                      *ngIf="!control.isLoadingValues"
                      [nzValue]="item.value"
                      [nzLabel]="item.label | translate"
                    ></nz-option>
                  </ng-container>
                </nz-select>
              </nz-form-control>
            </nz-col>
          </nz-row>
        </form>
        <nz-row nzGutter="16">
          <nz-col [nzXs]="{ span: 24 }">
            <nz-form-control [nzSpan]="24">
              <div class="add-column-button-wrapper">
                <app-button
                  type="link-secondary"
                  (click)="onAddField($event)"
                  [disabled]="!financialFile.id || shouldDisableExportButton()"
                >
                  <span class="material-icons md-24 align-self-center me-2">add</span>
                  {{ 'Add_column' | translate }}
                </app-button>
              </div>
            </nz-form-control>
          </nz-col>
        </nz-row>
      </div>
    </nz-card>
  </nz-spin>
</div>
