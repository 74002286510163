import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ServicesModule } from '@core/services/services.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ComponentsModule } from '../components.module';
import { FormMediaNetworkVehicleComponent } from './form-media-network-vehicle/form-media-network-vehicle.component';
import { FormPackageComponent } from './form-package/form-package.component';
import { FormProductCampaignComponent } from './form-product-campaign/form-product-campaign.component';
import { FormPurchaseTypeComponent } from './form-purchase-type/form-purchase-type.component';
import { SpreadsheetColumnHeaderComponent } from './spreadsheet-column-header/spreadsheet-column-header.component';
import { SpreadsheetComponent } from './spreadsheet.component';
import { SpreadsheetService } from './spreadsheet.service';

@NgModule({
  providers: [SpreadsheetService],
  declarations: [
    SpreadsheetComponent,
    FormProductCampaignComponent,
    FormMediaNetworkVehicleComponent,
    FormPackageComponent,
    FormPurchaseTypeComponent,
    SpreadsheetColumnHeaderComponent
  ],
  exports: [SpreadsheetComponent],
  imports: [
    ComponentsModule,
    CommonModule,
    NzTableModule,
    NzModalModule,
    NzButtonModule,
    TranslateModule,
    NzSelectModule,
    NzInputModule,
    NzFormModule,
    NzSpinModule,
    NzResultModule,
    NzIconModule,
    FormsModule,
    ServicesModule
  ]
})
export class SpreadsheetModule {}
