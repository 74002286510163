<label class="total py-3">{{ 'Networks_Found' | translate : { total: networks.length } }}</label>
<nz-table
  #basicTable
  [nzFrontPagination]="false"
  [nzData]="networks"
  [nzLoading]="loading"
  [nzScroll]="{ x: '400px', y: 'calc(100vh - 415px)' }"
>
  <thead>
    <tr>
      <th nzWidth="calc(120px + 7vw)" class="column-header">{{ 'Network' | translate }}</th>
      <th nzWidth="calc(120px + 7vw)" class="column-header">{{ 'Media' | translate }}</th>
      <th nzWidth="calc(220px + 2vw)" class="column-header">{{ 'CreatedBy' | translate }}</th>
      <th nzWidth="130px" class="column-header">{{ 'CreatedAt' | translate }}</th>
      <th nzWidth="60px"></th>
    </tr>
  </thead>
  <tbody class="column-content">
    <tr *ngFor="let network of networks">
      <td class="column-content__vehicle">{{ network.networkName }}</td>
      <td class="column-content__media">{{ network.media.mediaName }}</td>
      <td>{{ network.createdBy }}</td>
      <td>{{ network.createdAt | date : 'dd/MM/yyyy' }}</td>
      <td>
        <div class="d-flex justify-content-end">
          <app-button type="link" (click)="handleDelete(network)">
            <span class="material-icons-outlined md-24">delete</span>
          </app-button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
