import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MediaService } from '@core/services/media/media.service';
import { MediaModel, NetworkModel, VehicleModel } from '@models/index';
import { Subject, takeUntil } from 'rxjs';
import { InconsistencyService } from '../../../core/services/inconsistency/inconsistency.service';
import { MessageService } from '../../../core/services/message/message.service';
import {
  InconsistencyErrorStatus,
  InconsistencyGroup
} from '../../../shared/enums/inconsistency-status.enum';
import { InconsistenciesGroupsModel } from '../../../shared/models/inconsistency';
import { InconsistencyGroupErrorModel } from '../../../shared/models/inconsistency/inconsistency.model';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'app-form-media-network-vehicle',
  templateUrl: './form-media-network-vehicle.component.html',
  styleUrls: ['./form-media-network-vehicle.component.scss']
})
export class FormMediaNetworkVehicleComponent implements OnDestroy {
  @Input() validationFileId = '';

  @Input() isVisible = false;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _destroying$ = new Subject<void>();

  public mediaNetworkVehicleInconsistencies: InconsistencyGroupErrorModel[] = [];

  public isSpinning = true;

  public disabledSaveBtn = true;

  public groupInconsistencies?: InconsistenciesGroupsModel;

  public medias: MediaModel[] = [];

  public isSavedUpdates = false;

  public isSavedError = false;

  public isLineIncomplete = false;

  public isShowingMsgError = false;

  public isSaving = false;

  private autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private mediaService: MediaService,
    private inconsistencyService: InconsistencyService,
    private spreadsheetService: SpreadsheetService,
    private message: MessageService
  ) {}

  public afterOpen(): void {
    this.getMedias();
  }

  private getInconsistenciesGroup(): void {
    if (!this.validationFileId) return;

    this.inconsistencyService
      .getByIdGroup(this.validationFileId, InconsistencyGroup.MediaNetworkVehicle)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: response => {
          this.groupInconsistencies = response;

          const mediaNetworkVehicleError = this.groupInconsistencies.inconsistencies[0].errors;

          if (mediaNetworkVehicleError.length > 0) {
            this.mediaNetworkVehicleInconsistencies =
              this.filterPendingErrors(mediaNetworkVehicleError);
          }

          this.mediaNetworkVehicleInconsistencies.forEach(error => {
            if (error.media.id) {
              error.media.tempId = error.media.id;
              error.networkList = this.filterNetworkByMedia(error.media.id);
            }

            if (error.network.id) {
              error.network.tempId = error.network.id;
              error.vehicleList = this.filterVehicleByNetwork(error.network.id);
            }
          });
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        },
        complete: () => {
          this.isSpinning = false;
        }
      });
  }

  private getMedias(): void {
    this.isSpinning = true;
    this.mediaService.getMedias().subscribe({
      next: data => {
        this.medias = data;
        this.getInconsistenciesGroup();
      },
      error: () => {
        this.isSpinning = false;
      }
    });
  }

  private checkEmpty(): void {
    const isSomeLineComplete = this.mediaNetworkVehicleInconsistencies.some(
      inconsistency =>
        inconsistency?.media?.id && inconsistency?.network?.id && inconsistency?.vehicle?.id
    );

    this.disabledSaveBtn = !isSomeLineComplete;

    const isSomeLineIncomplete = this.mediaNetworkVehicleInconsistencies.some(item => {
      const missingNetwork = item.media.id && !item.network.id && item.media.hasError;

      const missingVehicle =
        item.media.id && item.network.id && !item.vehicle.id && item.network.hasError;

      const removedMedia = !item.media.hasError && !item.media.id;

      const removedNetwork = !item.network.hasError && !item.network.id;

      const removedMediaOrNetwork = removedMedia || removedNetwork;

      return missingNetwork || missingVehicle || removedMediaOrNetwork;
    });

    this.isLineIncomplete = isSomeLineIncomplete;
  }

  public ngValueChangeMedia(mediaId: string, index: number): void {
    this.mediaNetworkVehicleInconsistencies[index].media.id = mediaId;
    this.mediaNetworkVehicleInconsistencies[index].network.id = null;
    this.mediaNetworkVehicleInconsistencies[index].vehicle.id = null;
    this.mediaNetworkVehicleInconsistencies[index].networkList = this.filterNetworkByMedia(mediaId);
    this.checkEmpty();
  }

  public ngValueChangeNetwork(networkId: string, index: number): void {
    this.mediaNetworkVehicleInconsistencies[index].network.id = networkId;
    this.mediaNetworkVehicleInconsistencies[index].vehicle.id = null;
    this.mediaNetworkVehicleInconsistencies[index].vehicleList =
      this.filterVehicleByNetwork(networkId);
    this.checkEmpty();
  }

  public ngValueChangeVehicle(vehicleId: string, index: number): void {
    this.mediaNetworkVehicleInconsistencies[index].vehicle.id = vehicleId;
    this.checkEmpty();
  }

  private filterNetworkByMedia(mediaId: string | null): NetworkModel[] {
    const media = this.medias.find(mediaItem => mediaItem.id === mediaId);

    if (media?.networks) {
      return media.networks;
    }
    return [];
  }

  private filterVehicleByNetwork(networkId: string | null): VehicleModel[] {
    const media = this.medias.find(mediaItem =>
      mediaItem.networks.some(networkItem => networkItem.id === networkId)
    );

    const network = media?.networks.find(networkItem => networkItem.id === networkId);

    if (network?.vehicles) {
      return network.vehicles;
    }
    return [];
  }

  private filterPendingErrors(
    errors: InconsistencyGroupErrorModel[]
  ): InconsistencyGroupErrorModel[] {
    return errors.filter(
      inconsistency => inconsistency.status === InconsistencyErrorStatus.Pending
    );
  }

  private setIdAndCorrect(): void {
    this.mediaNetworkVehicleInconsistencies.forEach(inconsistency => {
      const isAllCorrected =
        inconsistency.media.id && inconsistency.network.id && inconsistency.vehicle.id;

      if (isAllCorrected) {
        const media = this.medias.find(mediaItem => mediaItem.id === inconsistency.media.id);

        const network = media?.networks.find(
          networkItem => networkItem.id === inconsistency.network.id
        );

        const vehicle = network?.vehicles?.find(
          vehicleItem => vehicleItem.id === inconsistency.vehicle.id
        );

        const isAllFound = media && network && vehicle;

        if (isAllFound) {
          inconsistency.media.correct = media.mediaName;
          inconsistency.network.correct = network.networkName;
          inconsistency.vehicle.correct = vehicle.vehicleName;

          inconsistency.status = InconsistencyErrorStatus.Corrected;
        }
      } else {
        inconsistency.media.correct = '';
        inconsistency.network.correct = '';
        inconsistency.vehicle.correct = '';
        if (inconsistency.media.tempId) inconsistency.media.id = inconsistency.media.tempId;
        if (inconsistency.network.tempId) inconsistency.network.id = inconsistency.network.tempId;

        inconsistency.status = InconsistencyErrorStatus.Pending;
      }
    });
  }

  public save(): void {
    if (!this.groupInconsistencies) return;

    this.isSaving = true;
    this.checkEmpty();

    if (!this.isLineIncomplete) {
      this.isSpinning = true;
      this.setIdAndCorrect();

      this.inconsistencyService
        .saveInconsistencyGroup(
          this.validationFileId,
          this.groupInconsistencies,
          InconsistencyGroup.MediaNetworkVehicle
        )
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: result => {
            if (result) {
              this.isSavedUpdates = true;
              this.spreadsheetService.updateInconsistenciesSubject.next();
              this.autoModalTimer = setTimeout(() => {
                this.close();
              }, 5000);
            } else {
              this.isSavedError = true;
            }
          },
          error: () => {
            this.isSpinning = false;
            this.isSavedError = true;
          },
          complete: () => {
            this.isSpinning = false;
          }
        });
    }
  }

  public close(): void {
    this.isSpinning = true;
    this.isSavedUpdates = false;
    this.isSaving = false;
    this.isVisible = false;
    this.disabledSaveBtn = true;
    this.isSavedError = false;
    this.groupInconsistencies = undefined;
    this.mediaNetworkVehicleInconsistencies = [];
    this.medias = [];
    this.isLineIncomplete = false;
    this.isVisibleModalChange.emit(this.isVisible);
    clearTimeout(this.autoModalTimer);
  }

  public back(): void {
    this.isSavedError = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
