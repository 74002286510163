<div>
  <div class="d-flex flex-wrap justify-content-end mb-2">
    <app-button
      *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
      class="mb-2"
      type="primary"
      (click)="generateConsolidateGeneral($event)"
      [loading]="fileGeneralConsolidated.isLoadingConsolidated"
      [disabled]="!(fileGeneralConsolidated.status === ConsolidatedStatus.InQueue)"
    >
      <span class="material-icons-outlined md-24 me-2">difference</span>
      {{ 'Generate_consolidate_general' | translate }}
    </app-button>
    <app-button
      *ngIf="
        userGroup === userGroups.admin ||
        userGroup === userGroups.analyst ||
        userGroup === userGroups.customer
      "
      class="mb-2 ms-2"
      (click)="showComparativeSquad(fileGeneralConsolidated)"
      type="primary"
      [disabled]="
        !(fileGeneralConsolidated.status === ConsolidatedStatus.Generated && lastDmiImportDate)
      "
    >
      <span class="material-icons-outlined md-24 me-2">text_snippet</span>
      {{ 'Show_comparative' | translate }}
    </app-button>
    <app-button
      class="ms-2 mb-2"
      (click)="downloadFile(fileGeneralConsolidated)"
      [loading]="isDownloading"
      type="default"
      [disabled]="fileGeneralConsolidated.status !== ConsolidatedStatus.Generated"
    >
      <span class="material-icons-outlined md-24">file_download</span>
    </app-button>
  </div>
  <div *ngFor="let file of filesList">
    <app-consolidated-card
      [file]="file"
      [userGroup]="userGroup"
      [lastDmiImportDate]="lastDmiImportDate"
      [dateRef]="dateRef"
    ></app-consolidated-card>
  </div>
</div>
