import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FinancialFileTypes } from '../../shared/enums';
import { getContainerFinancialFileTypes } from '../../shared/utils/utils';

@Component({
  selector: 'app-header-calendar',
  templateUrl: './header-calendar.component.html',
  styleUrls: ['./header-calendar.component.scss']
})
export class HeaderCalendarComponent implements OnChanges {
  @Input() dateRefText = '';

  @Input() isBackArrow = false;

  @Input() queryParams = {};

  @Input() fileType? = FinancialFileTypes.Unknown;

  @Input() squadName? = '';

  public fileTypeText = '';

  constructor(public translate: TranslateService) {}

  ngOnChanges(): void {
    if (this.fileType) {
      this.fileTypeText = getContainerFinancialFileTypes(this.fileType);
    }
  }
}
