<app-alert type="danger">
  <div class="scrollError">
    <div *ngIf="fileError.duplicatedLinesMessage">
      <p class="mb-1">{{ fileError.duplicatedLinesMessage | translate }}:</p>
      <div *ngFor="let duplicateLine of fileError.duplicatedLines; let lineIndex = index">
        <b>{{ duplicateLine.column }}</b>
        <div>
          <b class="text-capitalize">{{ 'lines' | translate }}:</b>
          <span *ngFor="let line of duplicateLine.lines; let lineIndex = index">
            {{ line }}{{ lineIndex < (duplicateLine.lines.length || 0) - 1 ? ', ' : '' }}
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="fileError.requiredCellsMessage">
      <p class="mb-1">{{ fileError.requiredCellsMessage | translate }}:</p>
      <div *ngFor="let duplicateLine of fileError.requiredCells; let lineIndex = index">
        <b>{{ duplicateLine.column }}</b>
        <div>
          <b class="text-capitalize">{{ 'lines' | translate }}:</b>
          <span *ngFor="let line of duplicateLine.lines; let lineIndex = index">
            {{ line }}{{ lineIndex < (duplicateLine.lines.length || 0) - 1 ? ', ' : '' }}
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="fileError.invalidCellsMessage">
      <p class="mb-1">{{ fileError.invalidCellsMessage | translate }}:</p>
      <div *ngFor="let duplicateLine of fileError.invalidCells; let lineIndex = index">
        <b>{{ duplicateLine.column }}</b>
        <div>
          <b class="text-capitalize">{{ 'lines' | translate }}:</b>
          <span *ngFor="let line of duplicateLine.lines; let lineIndex = index">
            {{ line }}{{ lineIndex < (duplicateLine.lines.length || 0) - 1 ? ', ' : '' }}
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="fileError.requiredColumnsMessage">
      <p class="mb-1">{{ fileError.requiredColumnsMessage | translate }}:</p>
      <b *ngFor="let requiredColumn of fileError.requiredColumns; let lineIndex = index">
        {{ requiredColumn
        }}{{ lineIndex < (fileError.requiredColumns?.length || 0) - 1 ? ', ' : '' }}
      </b>
    </div>
    <p class="mt-1">
      {{ 'after_making_the_adjustments_make_a_new_upload' | translate }}
    </p>
  </div>
</app-alert>
