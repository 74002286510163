import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { UserProfileService } from '../../core/services/userProfile/userProfile';
import { UserProfileModel } from '../../shared/models';
import { getInitialFromName } from '../../shared/utils/utils';

@Component({
  selector: 'app-ui-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent implements OnInit, OnDestroy {
  public userInfo$!: Observable<{ name: string; email: string }>;

  userProfile!: UserProfileModel;

  nameInitial = '';

  private readonly _destroying$ = new Subject<void>();

  public isVisibleProfileSettingsDrawer = false;

  constructor(
    private _oktaAuthStateService: OktaAuthStateService,
    private userProfileService: UserProfileService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {}

  public profileSettings(): void {
    this.isVisibleProfileSettingsDrawer = true;
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  private setInitialName(name: string): void {
    this.nameInitial = getInitialFromName(name);
  }

  private getUserFromDatabase(): void {
    this.userProfileService.getByUser().subscribe({
      next: userProfile => {
        this.userProfile = userProfile;
        if (this.userProfile.profile?.firstName && this.userProfile.profile?.lastName) {
          this.setInitialName(
            `${this.userProfile.profile.firstName} ${this.userProfile.profile.lastName}`
          );
        } else {
          this.getUserFromOkta();
        }
      }
    });
  }

  private getUserFromOkta(): void {
    this.userInfo$ = this._oktaAuthStateService.authState$.pipe(
      takeUntil(this._destroying$),
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => {
        const name = authState.idToken?.claims?.name ?? '';
        const email = authState.idToken?.claims?.email ?? '';
        this.setInitialName(name);

        return {
          name,
          email
        };
      })
    );
  }

  ngOnInit(): void {
    this.getUserFromOkta();
    this.getUserFromDatabase();
    this.userProfileService.updateUserHeader.subscribe({
      next: () => {
        this.getUserFromDatabase();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
