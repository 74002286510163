import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PackageComponentService {
  public updatePackagesGrid = new Subject<boolean>();

  public loading = new Subject<boolean>();

  public isFormLoading = new Subject<boolean>();

  public isTableLoading = new Subject<boolean>();
}
