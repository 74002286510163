<nz-modal
  nzWidth="1280px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="close()"
  [(nzVisible)]="isVisible"
  (nzAfterOpen)="afterOpen()"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-title">{{ 'Error_correction' | translate }}</div>
  </ng-container>

  <ng-container *nzModalContent>
    <div class="cm-modal-body" *ngIf="!isSavedUpdates">
      <nz-spin nzSize="large" [nzSpinning]="isSpinning">
        <div class="cm-modal-subtitle mb-3">{{ 'MediaNetworkVehicle' | translate }}</div>
        <div class="row mb-1">
          <div class="col">
            <div class="fw-bold">{{ 'From' | translate }}</div>
            <div>{{ 'MediaNetworkVehicle' | translate }}</div>
          </div>
          <div class="col">
            <div class="fw-bold">{{ 'To' | translate }}</div>
            <div>{{ 'Media' | translate }}</div>
          </div>
          <div class="col">
            <div class="fw-bold">&nbsp;</div>
            <div>{{ 'Network' | translate }}</div>
          </div>
          <div class="col">
            <div class="fw-bold">&nbsp;</div>
            <div>{{ 'Vehicle' | translate }}</div>
          </div>
        </div>
        <form class="cm-form-scroll">
          <div
            class="row"
            *ngFor="let item of mediaNetworkVehicleInconsistencies; let indexOf = index"
          >
            <div class="col col-3 mb-2">
              <input
                nz-input
                name="media-network-vehicle{{ indexOf }}"
                [value]="
                  item.media.original +
                  ' / ' +
                  item.network.original +
                  ' / ' +
                  item.vehicle.original
                "
                [title]="
                  item.media.original +
                  ' / ' +
                  item.network.original +
                  ' / ' +
                  item.vehicle.original
                "
                [disabled]="true"
                class="disabled-input-color"
              />
            </div>
            <div class="col col-3 mb-2">
              <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="{{ 'Select_media' | translate }}"
                name="media{{ indexOf }}"
                [(ngModel)]="item.media.id"
                (ngModelChange)="ngValueChangeMedia($event, indexOf)"
                [ngClass]="isSaving && !item.media.id && !item.media.hasError ? 'error-border' : ''"
              >
                <nz-option
                  *ngFor="let media of medias"
                  [nzValue]="media.id"
                  [nzLabel]="media.mediaName"
                >
                </nz-option>
              </nz-select>
            </div>
            <div class="col col-3 mb-2">
              <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="{{ 'Select_network' | translate }}"
                name="network{{ indexOf }}"
                [(ngModel)]="item.network.id"
                (ngModelChange)="ngValueChangeNetwork($event, indexOf)"
                [nzDisabled]="!item.media.id"
                [ngClass]="
                  isSaving &&
                  ((item.media.id && !item.network.id && item.media.hasError) ||
                    (!item.network.id && !item.network.hasError))
                    ? 'error-border'
                    : ''
                "
              >
                <nz-option
                  *ngFor="let network of item.networkList"
                  [nzValue]="network.id"
                  [nzLabel]="network.networkName"
                >
                </nz-option>
              </nz-select>
            </div>
            <div class="col col-3 mb-2">
              <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="{{ 'Select_vehicle' | translate }}"
                name="vehicle{{ indexOf }}"
                [(ngModel)]="item.vehicle.id"
                (ngModelChange)="ngValueChangeVehicle($event, indexOf)"
                [nzDisabled]="!item.network.id"
                [ngClass]="
                  isSaving &&
                  item.media.id &&
                  item.network.id &&
                  !item.vehicle.id &&
                  item.network.hasError
                    ? 'error-border'
                    : ''
                "
              >
                <nz-option
                  *ngFor="let vehicle of item.vehicleList"
                  [nzValue]="vehicle.id"
                  [nzLabel]="vehicle.vehicleName"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
        </form>
      </nz-spin>
    </div>
    <div
      *ngIf="isSavedUpdates"
      class="d-flex flex-column justify-content-center align-items-center p-5"
    >
      <span class="modal-icon success material-icons align-middle">done</span>
      <p class="modal-text success mt-2">{{ 'Saved_updates' | translate }}</p>
    </div>
    <div
      *ngIf="isSavedError"
      class="d-flex flex-column justify-content-center align-items-center modal-error-message"
    >
      <span class="modal-icon error material-icons-outlined align-middle">cancel</span>
      <p class="modal-text error mt-3 mb-4">{{ 'Saved_error' | translate }}</p>

      <app-button class="d-flex" type="link" (click)="back()">
        <span class="modal-text error material-icons md-18">west</span>
        <span class="modal-text error ms-2">
          <u>{{ 'Back' | translate }}</u>
        </span>
      </app-button>
    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div *ngIf="!isSavedUpdates" class="d-flex justify-content-end">
      <div class="msg-error" *ngIf="isLineIncomplete && isSaving">
        <span
          nz-icon
          nzType="info-circle"
          nzTheme="fill"
          class="d-flex me-2 align-self-center material-icons md-20"
        ></span>
        {{ 'Required_fields' | translate }}
      </div>
      <app-button class="me-2" *ngIf="!isSpinning" type="link" (click)="close()">
        {{ 'Cancel' | translate }}
      </app-button>
      <app-button *ngIf="!isSpinning" type="primary" [disabled]="disabledSaveBtn" (click)="save()">
        {{ 'Save_changes' | translate }}
      </app-button>
    </div>
  </ng-container>
</nz-modal>
