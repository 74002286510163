import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '@core/services/message/message.service';
import { PurchaseTypeService } from '@core/services/purchase-type/purchase-type.service';
import { PurchaseTypeCompleteModel } from '@models/purchase-type';
import { TranslateService } from '@ngx-translate/core';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject, takeUntil } from 'rxjs';
import { PurchaseTypeComponentService } from '../purchase-type-component.service';

@Component({
  selector: 'app-purchase-type-form',
  templateUrl: './purchase-type-form.component.html',
  styleUrls: ['./purchase-type-form.component.scss']
})
export class PurchaseTypeFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  purchaseTypes: PurchaseTypeCompleteModel[] = [];

  disabledSaveBtn = true;

  isSpinning = false;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    private purchaseTypeService: PurchaseTypeService,
    private purchaseTypeComponentService: PurchaseTypeComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      purchaseTypeName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
  }

  cleanForm(): void {
    this.validationForm.controls['purchaseTypeName'].setValue('');
    this.validationForm.controls['purchaseTypeName'].reset();
  }

  save(): void {
    this.purchaseTypeComponentService.isFormLoading.next(true);

    const purchaseTypeName = this.validationForm.get('purchaseTypeName')?.value;
    this.purchaseTypeService
      .savePurchaseType(purchaseTypeName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['purchaseTypeName'].reset();
          this.purchaseTypeComponentService.updatePurchaseTypesGrid.next(true);
          this.message.showSuccess(this.translate.instant('Registration_saved'));
          this.purchaseTypeComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.purchaseTypeComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
