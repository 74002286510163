import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PurchaseTypeComponentService } from './purchase-type-component.service';

@Component({
  selector: 'app-purchase-type',
  templateUrl: './purchase-type.component.html',
  styleUrls: ['./purchase-type.component.scss']
})
export class PurchaseTypeComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  isSpinningForm!: boolean;

  isSpinningTable!: boolean;

  constructor(
    private purchaseTypeComponentService: PurchaseTypeComponentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.purchaseTypeComponentService.isFormLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((formIsLoading: boolean) => {
        this.isSpinningForm = formIsLoading;
      });

    this.purchaseTypeComponentService.isTableLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((tableIsLoading: boolean) => {
        this.isSpinningTable = tableIsLoading;
      });
  }

  navigate(): void {
    this.router.navigateByUrl('registrations/list?category=purchaseType');
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
