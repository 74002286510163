import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalService?: NzModalRef;

  constructor(private translate: TranslateService, private nzModalService: NzModalService) {}

  public handleOk(): void {
    this.modalService?.destroy();
  }

  public showError(text: string): void {
    this.translate.get([text]).subscribe(translations => {
      this.modalService = this.nzModalService.confirm({
        nzTitle: translations[text],
        nzCancelDisabled: true,
        nzClosable: false,
        nzIconType: 'exclamation-circle',
        nzWrapClassName: 'error-modal',
        nzOnOk: this.handleOk.bind(this)
      });
    });
  }

  public showErrorByStatus(status: number): void {
    this.showError(`api_status.${status}`);
  }

  public confirm(options: {
    title: string;
    content?: string;
    okText?: string;
    cancelText?: string;
    okLoading?: boolean;
    cancelLoading?: boolean;
    iconType?: string;
    className?: string;
    onOk?: () => void;
    onCancel?: () => void;
  }): NzModalRef | undefined {
    this.translate.get([options.title, options.content || '']).subscribe(translations => {
      this.modalService = this.nzModalService.confirm({
        nzClosable: false,
        nzIconType: options.iconType || 'exclamation-circle',
        nzWrapClassName: options.className || 'confirm-modal',
        nzTitle: translations[options.title],
        nzContent: translations[options.content || ''],
        nzOkText: options.okText
          ? this.translate.instant(options.okText)
          : this.translate.instant('Yes'),
        nzCancelText: options.cancelText
          ? this.translate.instant(options.cancelText)
          : this.translate.instant('No'),
        nzOkLoading: options.okLoading,
        nzCancelLoading: options.cancelLoading,
        nzOnOk: options.onOk,
        nzOnCancel: options?.onCancel || this.handleOk.bind(this)
      });
    });

    return this.modalService;
  }
}
