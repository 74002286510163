import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { SpreadsheetModule } from '@components/spreadsheet/spreadsheet.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CampaignComponentService } from './campaign/campaign-component.service';
import { CampaignFormComponent } from './campaign/campaign-form/campaign-form.component';
import { CampaignGridComponent } from './campaign/campaign-grid/campaign-grid.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CommitmentModule } from './commitment/commitment.module';
import { ListComponent } from './list/list.component';
import { MediaComponentService } from './media/media-component.service';
import { MediaFormComponent } from './media/media-form/media-form.component';
import { MediaGridComponent } from './media/media-grid/media-grid.component';
import { MediaComponent } from './media/media.component';
import { NetworkComponentService } from './network/network-component.service';
import { NetworkFormComponent } from './network/network-form/network-form.component';
import { NetworkGridComponent } from './network/network-grid/network-grid.component';
import { NetworkComponent } from './network/network.component';
import { PackageComponentService } from './package/package-component.service';
import { PackageFormComponent } from './package/package-form/package-form.component';
import { PackageGridComponent } from './package/package-grid/package-grid.component';
import { PackageComponent } from './package/package.component';
import { ProductComponentService } from './product/product-component.service';
import { ProductFormComponent } from './product/product-form/product-form.component';
import { ProductGridComponent } from './product/product-grid/product-grid.component';
import { ProductComponent } from './product/product.component';
import { PurchaseTypeComponentService } from './purchase-type/purchase-type-component.service';
import { PurchaseTypeFormComponent } from './purchase-type/purchase-type-form/purchase-type-form.component';
import { PurchaseTypeGridComponent } from './purchase-type/purchase-type-grid/purchase-type-grid.component';
import { PurchaseTypeComponent } from './purchase-type/purchase-type.component';
import { VehicleComponentService } from './vehicle/vehicle-component.service';
import { VehicleFormComponent } from './vehicle/vehicle-form/vehicle-form.component';
import { VehicleGridComponent } from './vehicle/vehicle-grid/vehicle-grid.component';
import { VehicleComponent } from './vehicle/vehicle.component';

@NgModule({
  declarations: [
    VehicleComponent,
    VehicleFormComponent,
    VehicleGridComponent,
    PackageComponent,
    PackageGridComponent,
    PackageFormComponent,
    ListComponent,
    ProductComponent,
    ProductFormComponent,
    ProductGridComponent,
    CampaignComponent,
    CampaignFormComponent,
    CampaignGridComponent,
    NetworkComponent,
    NetworkFormComponent,
    NetworkGridComponent,
    MediaComponent,
    MediaFormComponent,
    MediaGridComponent,
    PurchaseTypeComponent,
    PurchaseTypeFormComponent,
    PurchaseTypeGridComponent
  ],
  providers: [
    VehicleComponentService,
    PackageComponentService,
    CampaignComponentService,
    NetworkComponentService,
    CampaignComponentService,
    ProductComponentService,
    PackageComponentService,
    CampaignComponentService,
    MediaComponentService,
    PurchaseTypeComponentService
  ],
  imports: [
    CommitmentModule,
    CommonModule,
    SharedModule,
    ComponentsModule,
    SpreadsheetModule,
    RouterModule,
    FormsModule,
    NzLayoutModule,
    NzIconModule,
    NzModalModule,
    NzGridModule,
    NzRadioModule,
    NzCheckboxModule,
    NzSpinModule,
    NzCardModule,
    NzDividerModule,
    NzTableModule,
    NzFormModule,
    ReactiveFormsModule,
    TranslateModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzEmptyModule,
    CurrencyMaskModule
  ]
})
export class RegistrationsModule {}
