import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@core/services/message/message.service';
import { SystemSettingsService } from '@core/services/systemSettings/systemSettings.service';
import { SystemSettingModel } from '@models/systemSetting';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private METABASE_SITE_URL: string | undefined;

  private METABASE_SECRET_KEY: Uint8Array | undefined;

  private METABASE_DASHBOARD_ID: number | undefined;

  public isSpinning = false;

  public iframeUrl: SafeResourceUrl | undefined;

  systemSettings: SystemSettingModel[] = [];

  private METABASE_DASHBOARDS_KEYS = ["METABASE_DASHBOARD_ID", "METABASE_DASHBOARD_COMMITMENT_ID"]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public message: MessageService,
    public sanitizer: DomSanitizer,
    private systemSettingService: SystemSettingsService,
    private dashboardService: DashboardService,
  ) {
  }

  private showModalFromRoute(): void {
    const { message } = this.route.snapshot.data;

    if (message === 'not_found') {
      this.message.showErrorByStatus(404);
      this.router.navigate(['/']);
    }
  }

  private prepareEmbedDashboard(): void {

    if (!this.METABASE_DASHBOARD_ID || !this.METABASE_SECRET_KEY || !this.METABASE_SITE_URL) {
      return;
    }

    this.dashboardService.generateMetabaseUrl(this.METABASE_DASHBOARD_ID, this.METABASE_SECRET_KEY, this.METABASE_SITE_URL)
      .then((url) => {
      this.iframeUrl = url
    })
  }

  public getAllData(): void {
    this.isSpinning = true;
    this.systemSettingService
      .getAll()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: async systemSettings => {
          this.isSpinning = false;
          this.systemSettings = systemSettings;
          this.fillConfigurationsVariables();
          this.showModalFromRoute();
          this.prepareEmbedDashboard();
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  findDashboardId(number: number): number {
    const ObjectConstant = CONSTANTS as { [key: string]: string | string[] }

    const key = this.METABASE_DASHBOARDS_KEYS[number];

    const dashboardSettingsData = this.systemSettings.find(setting => setting.key === ObjectConstant[key])

    const dashboardId = Number.parseInt(dashboardSettingsData?.value || "-1");

    return dashboardId;
  }

  fillConfigurationsVariables(): void {
    this.METABASE_SECRET_KEY = new TextEncoder().encode(
      this.systemSettings.find(setting => setting.key === CONSTANTS.METABASE_EMBEDDING_SECRET_KEY)
        ?.value
    );
    this.METABASE_SITE_URL = this.systemSettings.find(
      setting => setting.key === CONSTANTS.METABASE_SITE_URL
    )?.value;

    this.METABASE_DASHBOARD_ID = this.findDashboardId(0)

    if (this.METABASE_SITE_URL && !/^https?:\/\//i.test(this.METABASE_SITE_URL)) {
      this.message.showErrorByStatus(404);
      this.METABASE_SITE_URL = undefined;
      this.router.navigate(['/']);
    }
  }

  public changeTab(event: number | undefined): void {
    if (event && event > 0) {

      this.METABASE_DASHBOARD_ID = this.findDashboardId(event)
    } else {
      this.METABASE_DASHBOARD_ID = this.findDashboardId(0)
    }

    this.prepareEmbedDashboard()
  }

  public ngOnInit(): void {
    this.getAllData();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
