import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { Observable } from 'rxjs';
import { PurchaseTypeCompleteModel, PurchaseTypeModel } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class PurchaseTypeService {
  constructor(private http: HttpClient) {}

  public getPurchaseTypes(): Observable<PurchaseTypeCompleteModel[]> {
    return this.http.get<PurchaseTypeCompleteModel[]>(`${baseWebApiURL}/v1/PurchaseTypes`);
  }

  public getLastPurchaseTypes(
    columnToOrder: string,
    descendingDirection: boolean,
    length?: number
  ): Observable<PurchaseTypeCompleteModel[]> {
    const lengthParam = length ? `&length=${length}` : '';

    const route = `${baseWebApiURL}/v1/PurchaseTypes/list?columnToOrder=${columnToOrder}&descendingDirection=${descendingDirection}${lengthParam}`;

    return this.http.get<PurchaseTypeCompleteModel[]>(route);
  }

  public savePurchaseType(
    purchaseTypeName: string,
    vehicleId?: number
  ): Observable<PurchaseTypeModel> {
    const vehicleIdParam = vehicleId ? `&vehicleId=${vehicleId}` : '';
    return this.http.post<PurchaseTypeModel>(
      `${baseWebApiURL}/v1/PurchaseTypes?purchaseTypeName=${purchaseTypeName}${vehicleIdParam}`,
      null
    );
  }

  public deletePurchaseType(purchaseTypeId: string): Observable<PurchaseTypeModel> {
    return this.http.delete<PurchaseTypeModel>(
      `${baseWebApiURL}/v1/PurchaseTypes/${purchaseTypeId}`
    );
  }
}
