<nz-drawer
  [nzClosable]="true"
  [nzMaskClosable]="false"
  [nzVisible]="isVisible"
  [nzFooter]="footer"
  nzPlacement="right"
  nzTitle="{{ 'Profile_Settings' | translate }}"
  (nzOnClose)="onClose()"
>
  <ng-container *nzDrawerContent>
    <nz-spin nzSize="large" [nzSpinning]="isLoadingData">
      <div>
        <form nz-form [formGroup]="validationForm">
          <div>
            <div class="d-flex justify-content-center mb-2">
              <nz-avatar
                [nzSize]="102"
                [nzText]="nameInitial | uppercase"
                class="cm-user-avatar"
              ></nz-avatar>
            </div>
            <div class="mb-2">
              <nz-form-label>{{ 'FirstName' | translate }}</nz-form-label>
              <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="firstNameError">
                  <input formControlName="firstName" nz-input />
                  <ng-template #firstNameError let-control>
                    <ng-container *ngIf="control.hasError('minlength')">{{
                      'Min_length'
                        | translate : { value: control.getError('minlength').requiredLength }
                    }}</ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">{{
                      'Max_length'
                        | translate : { value: control.getError('maxlength').requiredLength }
                    }}</ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="mb-2">
              <nz-form-label>{{ 'LastName' | translate }}</nz-form-label>
              <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="lastNameError">
                  <input formControlName="lastName" nz-input />
                  <ng-template #lastNameError let-control>
                    <ng-container *ngIf="control.hasError('minlength')">{{
                      'Min_length'
                        | translate : { value: control.getError('minlength').requiredLength }
                    }}</ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">{{
                      'Max_length'
                        | translate : { value: control.getError('maxlength').requiredLength }
                    }}</ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="mb-2">
              <nz-form-label nzRequired>{{ 'Profile' | translate }}</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input formControlName="profile" nz-input [disabled]="true" />
              </nz-form-control>
            </div>
            <div class="mb-2">
              <nz-form-label>{{ 'Role' | translate }}</nz-form-label>
              <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="roleError">
                  <input formControlName="role" nz-input />
                  <ng-template #roleError let-control>
                    <ng-container *ngIf="control.hasError('minlength')">{{
                      'Min_length'
                        | translate : { value: control.getError('minlength').requiredLength }
                    }}</ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">{{
                      'Max_length'
                        | translate : { value: control.getError('maxlength').requiredLength }
                    }}</ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </nz-spin>
  </ng-container>
  <ng-template #footer>
    <div class="d-flex justify-content-end">
      <app-button
        type="primary"
        [disabled]="!validationForm.valid || isLoadingData"
        (click)="onSave()"
      >
        {{ 'Save' | translate }}
      </app-button>
    </div>
  </ng-template>
</nz-drawer>
