import { Injectable } from '@angular/core';
import { userGroups } from '@env';
import { Subject } from 'rxjs';

@Injectable()
export class UserInfoService {
  public userGroup: Subject<string> = new Subject<string>();

  public getUserGroup(): string {
    const oktaInfoStorage = localStorage.getItem('okta-token-storage');
    if (oktaInfoStorage !== null) {
      const oktaTokenStorage = JSON.parse(oktaInfoStorage);

      const groupValues = Object.values(userGroups);

      const groupsCM = groupValues.filter((word: string) => !word.includes('Everyone'));
      const userClaimsGroups = oktaTokenStorage.accessToken?.claims['groups-centralDeMedia'];

      // Get the first group that matches with the user groups from Okta and the groups from the environment
      const group =
        groupsCM.find((groupCM: string) => userClaimsGroups?.includes(groupCM)) || userGroups.all;

      this.userGroup.next(group);
      return group;
    } else {
      this.userGroup.next('');
      return '';
    }
  }
}
