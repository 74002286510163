<a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
  <div class="user-info lh-base">
    <div>
      <nz-avatar
        [nzSize]="44"
        [nzText]="nameInitial | uppercase"
        class="cm-user-avatar"
      ></nz-avatar>
    </div>
    <div class="ms-2">
      <div class="d-flex align-items-center" *ngIf="userInfo$ | async as userInfo">
        <div>
          <div>
            <b>{{
              userProfile?.profile?.firstName && userProfile?.profile?.lastName
                ? userProfile.profile.firstName + ' ' + userProfile.profile.lastName
                : userInfo.name
            }}</b>
          </div>
          <div>{{ userProfile?.profile?.role ? userProfile.profile.role : userInfo.email }}</div>
        </div>
        <span class="material-icons md-24 align-self-center ms-2">expand_more</span>
      </div>
    </div>
  </div>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="profileSettings()">{{ 'Profile_Settings' | translate }}</li>
    <li nz-menu-item (click)="signOut()">{{ 'SignOut' | translate }}</li>
  </ul>
</nz-dropdown-menu>
<app-profile-settings
  [nameInitial]="nameInitial"
  [isVisible]="isVisibleProfileSettingsDrawer"
  (isVisibleDrawerChange)="isVisibleProfileSettingsDrawer = $event"
>
</app-profile-settings>
