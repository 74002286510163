import { Injectable } from '@angular/core';
import { FinancialFileTypes } from '@shared/enums';
import { Subject } from 'rxjs';

@Injectable()
export class SpreadsheetService {
  public updateInconsistenciesSubject = new Subject<void>();

  public isVisibleModalProductCampaign = new Subject<boolean>();

  public isVisibleModalMediaNetworkVehicle = new Subject<boolean>();

  public isVisibleModalPackage = new Subject<boolean>();

  public isVisibleModalPurchaseType = new Subject<boolean>();

  public fileType = FinancialFileTypes.Unknown;
}
