<div class="container-fluid">
  <app-header-calendar
    [isBackArrow]="true"
    [dateRefText]="dateRefText"
    [queryParams]="{
      active_tab: redirectIndex,
      ref_date: dateRef.toISOString().substring(0, 10)
    }"
    [fileType]="fileType"
    [squadName]="dataResult.fileData.squadName"
  ></app-header-calendar>

  <nz-spin nzSize="large" [nzSpinning]="isSpinning">
    <app-inconsistencies-banner
      *ngIf="!isSpinning || hasInconsistencies"
      [dateRef]="dateRef"
      [validationFileId]="validationFileId"
      [fileType]="fileType"
      [bannerInformation]="bannerInformation"
      [checkFilter]="isOnlyInconsistencies"
      (checkFilterChange)="onCheckFilterChange($event)"
    >
    </app-inconsistencies-banner>

    <app-ui-spreadsheet
      *ngIf="hasInconsistencies || !isOnlyInconsistencies"
      [validationFileId]="validationFileId"
      [fileType]="fileType"
      [bannerInformation]="bannerInformation"
      [columns]="columns"
      [rows]="rows"
      [squadId]="dataResult.fileData.squadId"
    >
    </app-ui-spreadsheet>
  </nz-spin>

  <div class="d-flex flex-row justify-content-center p-4">
    <app-pagination
      *ngIf="hasInconsistencies || !isOnlyInconsistencies"
      [pageIndex]="currentPageIndex"
      [pageSize]="pageSize"
      [total]="totalRecords"
      [showSizeChanger]="true"
      [showTotal]="true"
      (pageIndexChange)="onPageIndexChange($event)"
      (pageSizeChange)="onPageSizeChange($event)"
    ></app-pagination>
  </div>
</div>
