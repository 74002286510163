<div
  class="consolidated-card"
  [ngClass]="{
    approved: file.status === ConsolidatedStatus.Approved,
    rejected: file.status === ConsolidatedStatus.Rejected
  }"
>
  <nz-spin [nzSpinning]="loading">
    <nz-card [nzBordered]="false">
      <div class="d-flex w-100 justify-content-between">
        <div class="d-flex flex-column flex-grow-1 ustify-content-between">
          <div class="mb-2">
            <span class="font-size-16 squadTitle">
              <span>{{ 'Squad' | translate }}:</span
              ><b class="font-size-16"> {{ file.squad?.squadName }}</b>
            </span>
          </div>
          <div class="d-flex flex-wrap justify-content-between">
            <span class="font-size-16 mb-2">
              <span>{{ 'Month' | translate }}:</span>
              <b class="font-size-16 text-capitalize"> {{ this.displayDate }}</b>
            </span>
            <div
              class="d-flex align-items-center processing-consolidated-general mb-2"
              *ngIf="ConsolidatedStatus.Waiting === file.status && userGroup !== userGroups.squad"
            >
              <span class="material-icons md-24 align-self-center me-2">info</span>
              <b>{{ 'Consolidated_waiting_file' | translate }}</b>
            </div>
          </div>
          <div>
            <nz-radio-group
              nzTooltipPlacement="left"
              nz-tooltip
              *ngIf="userGroup === userGroups.admin || userGroup === userGroups.analyst"
              class="d-flex flex-column flex-grow-1 m-0"
              [(ngModel)]="file.status"
              (ngModelChange)="ngChangeStatus($event)"
              [disabled]="
                (file.status !== ConsolidatedStatus.Generated &&
                  file.status !== ConsolidatedStatus.Rejected &&
                  file.status !== ConsolidatedStatus.Approved) ||
                loading
              "
              nzTooltipTitle="{{
                file.status !== ConsolidatedStatus.Generated &&
                file.status !== ConsolidatedStatus.Rejected &&
                file.status !== ConsolidatedStatus.Approved
                  ? ('Consolidated_not_generated' | translate)
                  : null
              }}"
            >
              <span nz-radio [nzValue]="ConsolidatedStatus.Approved" class="mb-2">
                {{ 'Approved' | translate }}
              </span>
              <span nz-radio [nzValue]="ConsolidatedStatus.Rejected">
                {{ 'Reproved' | translate }}
              </span>
            </nz-radio-group>
            <div
              *ngIf="
                userGroup === userGroups.squad &&
                (file.status === ConsolidatedStatus.Approved ||
                  file.status === ConsolidatedStatus.Rejected)
              "
              class="d-flex align-items-center"
            >
              <span
                *ngIf="file.status === ConsolidatedStatus.Approved"
                class="approved-icon material-icons-outlined md-24 align-self-center me-2"
                >check_circle</span
              >
              <span
                *ngIf="file.status === ConsolidatedStatus.Rejected"
                class="rejected-icon material-icons md-24 align-self-center me-2"
                >highlight_off</span
              >
              <b>{{ 'Consolidated' | translate }}</b>
              <b class="text-lowercase ms-1">{{
                (file.status === ConsolidatedStatus.Approved ? 'Approved' : 'Reproved') | translate
              }}</b>
            </div>
            <div
              class="d-flex align-items-center processing-consolidated-general mb-2"
              *ngIf="ConsolidatedStatus.Waiting === file.status && userGroup === userGroups.squad"
            >
              <span class="material-icons md-24 align-self-center me-2">info</span>
              <b>{{ 'Consolidated_waiting_file' | translate }}</b>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-grow-1 justify-content-between">
          <div class="d-flex flex-wrap justify-content-end">
            <app-button
              class="mb-4"
              (click)="downloadFile(file)"
              type="default"
              [loading]="isDownloading"
              [disabled]="
                file.status !== ConsolidatedStatus.Generated &&
                file.status !== ConsolidatedStatus.Rejected &&
                file.status !== ConsolidatedStatus.Approved
              "
            >
              <span class="material-icons-outlined md-24">file_download</span>
            </app-button>
          </div>
          <div class="d-flex flex-wrap justify-content-end">
            <app-button
              nzTooltipPlacement="top"
              nz-tooltip
              class="mb-2"
              (click)="generateConsolidateSquad(file)"
              [loading]="file.isLoadingConsolidated"
              type="primary"
              [disabled]="ConsolidatedStatus.InQueue !== file.status"
              nzTooltipTitle="{{
                file.status === ConsolidatedStatus.Generated ||
                file.status === ConsolidatedStatus.Approved ||
                file.status === ConsolidatedStatus.Rejected
                  ? ('Consolidated_has_already_been_generated' | translate)
                  : file.status === ConsolidatedStatus.Waiting
                  ? ('Waiting_upload_files_or_correction' | translate)
                  : null
              }}"
            >
              <span class="material-icons-outlined md-24 me-2" *ngIf="!file.isLoadingConsolidated"
                >difference</span
              >
              {{ 'Generate_consolidate' | translate }}
            </app-button>
            <app-button
              nzTooltipPlacement="top"
              nz-tooltip
              class="ms-2 mb-2"
              (click)="showComparativeSquad(file)"
              type="primary"
              [disabled]="!lastDmiImportDate || file.status != ConsolidatedStatus.Approved"
              nzTooltipTitle="{{
                file.status === ConsolidatedStatus.Rejected ||
                file.status === ConsolidatedStatus.Generated
                  ? ('Consolidated_not_approved_approve_it_or_ask_to_analyst_approve' | translate)
                  : file.status == ConsolidatedStatus.Waiting ||
                    file.status == ConsolidatedStatus.InQueue ||
                    !lastDmiImportDate
                  ? ('Consolidated_not_generated_or_dmi_not_uploaded' | translate)
                  : null
              }}"
            >
              <span class="material-icons-outlined md-24 me-2">text_snippet</span>
              {{ 'Show_comparative' | translate }}
            </app-button>
          </div>
        </div>
      </div>
    </nz-card>
  </nz-spin>
</div>
