import { ValidationFileModel } from '@models/validation-file';
import { ConsolidatedStatus, FinancialFileTypes } from '@shared/enums';
import { SquadModel } from '@shared/models/squad';

export class FinancialFileResponse {
  public lastDmiImportDate: Date | null = null;

  public financialFiles: FinancialFileModel[] = [];
}

export class FinancialFileModel {
  public id = '';

  public pathFile = '';

  public fileType = FinancialFileTypes.Unknown; // trocar para FinancialFileTypes

  public referenceMonth = -1;

  public referenceYear = -1;

  public squadId = '';

  public squad: SquadModel | null = null;

  public status = ConsolidatedStatus.None;

  public createdBy = '';

  public updatedBy = '';

  public createdAt = new Date();

  public updatedAt = new Date();

  public validationFile: ValidationFileModel | null = null;

  public fileName = '';

  public isLoadingConsolidated = false;
}
