<div class="container-fluid">
  <nz-spin nzSize="large" [nzSpinning]="isSpinning">
    <div nz-col class="cm-page-title">
      {{ 'Dashboard' | translate }}
    </div>
    <nz-tabset (nzSelectChange)="changeTab($event.index)">
      <nz-tab nz-tab-link [routerLink]="['.']" nzTitle="Consolidado">
        <iframe-container [iframeUrl]="iframeUrl"></iframe-container>
      </nz-tab>
    
      <nz-tab nz-tab-link nzTitle="Compromisso">
        <iframe-container [iframeUrl]="iframeUrl"></iframe-container>
      </nz-tab>
    </nz-tabset>
  </nz-spin>
</div>
