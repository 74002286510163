import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { userGroups } from '@env';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { UserInfoService } from '@pages/user-info.service';
import { Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { calculateReferenceDate } from './shared/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public registrationItems = [
    { label: 'Product', routerLink: '/registrations/product' },
    { label: 'Campaign', routerLink: '/registrations/campaign' },
    { label: 'Media', routerLink: '/registrations/media' },
    { label: 'Network', routerLink: '/registrations/network' },
    { label: 'Vehicle', routerLink: '/registrations/vehicle' },
    { label: 'Package', routerLink: '/registrations/package' },
    { label: 'PurchaseType', routerLink: '/registrations/purchaseType' },
    { label: 'Commitment', routerLink: '/registrations/commitment' },
    { label: 'View records', routerLink: '/registrations/list' }
  ];

  public settingsItems = [
    { label: 'Metabase', routerLink: '/settings/metabase' },
    { label: 'Users', routerLink: '/settings/users' }
  ];

  public isCollapsed = true;

  public isAuthenticated$!: Observable<boolean>;

  private today = calculateReferenceDate(new Date());

  public dateRef = new Date(this.today.getFullYear(), this.today.getMonth(), 1);

  public userGroup = '';

  public userGroups = userGroups;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    public userInfoService: UserInfoService
  ) {}

  ngOnInit(): void {
    if (window.location.pathname !== '/login/callback') {
      this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
        filter((s: AuthState) => !!s),
        map((s: AuthState) => s.isAuthenticated ?? false)
      );
    }

    this.userInfoService.userGroup.pipe(takeUntil(this._destroying$)).subscribe((group: string) => {
      this.userGroup = group;
    });

    this.userInfoService.getUserGroup();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public async signIn(): Promise<void> {
    await this._oktaAuth.signInWithRedirect({
      originalUri: `/files`
    });
  }
}
