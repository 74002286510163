import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { UserProfileModel, UserProfileUpdateModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constants = CONSTANTS;

  constructor(private http: HttpClient) {}

  public updateUserHeader = new Subject<void>();

  public getByUser(): Observable<UserProfileModel> {
    const route = `${baseWebApiURL}/v1/UserProfile`;
    return this.http.get<UserProfileModel>(route);
  }

  public update(user: UserProfileUpdateModel): Observable<UserProfileModel> {
    return this.http.put<UserProfileModel>(`${baseWebApiURL}/v1/UserProfile`, user);
  }
}
