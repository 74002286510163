import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ApprovedRulesService } from '@core/services/approvedRule/approvedRule.service';
import { InconsistencyGroupsService } from '@core/services/inconsistencyGroups/inconsistencyGroups.service';
import { MessageService } from '@core/services/message/message.service';
import { FromToRules, SimplifiedRule } from '@models/fromToRules';
import { InconsistencyGroups } from '@models/inconsistencyGroups';
import { TranslateService } from '@ngx-translate/core';
import { InconsistencyGroup } from '@shared/enums/inconsistency-status.enum';
import { NzTableFilterList } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../../core/services/modal/modal.service';

const PAGE_SIZE = 10;

@Component({
  selector: 'app-rules-grid',
  templateUrl: './rules-grid.component.html',
  styleUrls: ['./rules-grid.component.scss']
})
export class RulesGridComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  sortDirections = ['ascend', 'descend', null];

  listOfFilters: NzTableFilterList = [];

  selectedFilters: number[] = [];

  fromToRules!: FromToRules;

  inconsistencyGroups: InconsistencyGroups[] = [];

  public InconsistencyGroup = InconsistencyGroup;

  currentPageIndex = 1;

  listOfCurrentPageRules: SimplifiedRule[] = [];

  descendingOrder = true;

  isSpinning = false;

  @Input() updateTable!: boolean;

  @Output() sendSpinningData = new EventEmitter();

  checked = false;

  indeterminate = false;

  selectedCheckboxesIds = new Set<string>();

  listOfEnabledData: SimplifiedRule[] = [];

  @Output() deleteButtonIsEnabled = new EventEmitter();

  dataSelectedToDelete: InconsistencyGroups[] = [];

  @Output() rulesDataToDelete = new EventEmitter();

  constructor(
    private approvedRulesService: ApprovedRulesService,
    private inconsistencyGroupsService: InconsistencyGroupsService,
    private message: MessageService,
    public translate: TranslateService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData(this.selectedFilters, this.descendingOrder, this.currentPageIndex, PAGE_SIZE);
    this.getFilterData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['updateTable'] && this.updateTable === true) {
      this.getData(this.selectedFilters, this.descendingOrder, this.currentPageIndex, PAGE_SIZE);
    }
    this.updateTable = false;
  }

  getFilterData(): void {
    this.inconsistencyGroupsService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (inconsistencyGroups: InconsistencyGroups[]) => {
          this.listOfFilters = inconsistencyGroups.map(inconsistencyGroup => ({
            text: this.translate.instant(inconsistencyGroup.name),
            value: inconsistencyGroup.id
          }));
        },
        error: error => {
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  getData(filter: number[], descendingOrder: boolean, page: number, pageSize: number): void {
    this.isSpinning = true;
    this.sendSpinning();
    this.approvedRulesService
      .get(filter, descendingOrder, page, pageSize)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (rules: FromToRules) => {
          this.fromToRules = rules;
          this.listOfCurrentPageRules = rules.rules;
          this.isSpinning = false;
          this.sendSpinning();
          this.refreshCheckedStatus(this.listOfCurrentPageRules, this.selectedCheckboxesIds);
        },
        error: error => {
          this.isSpinning = false;
          this.sendSpinning();
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  sendSpinning(): void {
    this.sendSpinningData.emit(this.isSpinning);
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.selectedCheckboxesIds.add(id);
    } else {
      this.selectedCheckboxesIds.delete(id);
    }
  }

  refreshCheckedStatus(dataList: SimplifiedRule[], selectedCheckboxesIds: Set<string>): void {
    this.listOfEnabledData = dataList.filter(({ id }) => selectedCheckboxesIds.has(id));
    this.checked = this.listOfEnabledData.length === dataList.length;
    this.indeterminate = this.listOfEnabledData.length > 0 && !this.checked;

    this.deleteButtonIsEnabled.emit(this.selectedCheckboxesIds.size > 0);
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus(this.listOfCurrentPageRules, this.selectedCheckboxesIds);
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageRules.forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus(this.listOfCurrentPageRules, this.selectedCheckboxesIds);
  }

  onPageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
    this.getData(this.selectedFilters, this.descendingOrder, this.currentPageIndex, PAGE_SIZE);
  }

  public changeOrder(order: string | null): void {
    if (order == 'ascend') this.descendingOrder = false;
    else this.descendingOrder = true;

    this.getData(this.selectedFilters, this.descendingOrder, this.currentPageIndex, PAGE_SIZE);
  }

  public changeFilters(filters: number[] | null): void {
    if (filters?.length) {
      this.selectedFilters = filters;
      this.getData(this.selectedFilters, this.descendingOrder, this.currentPageIndex, PAGE_SIZE);
    } else {
      this.selectedFilters = [];
      this.getData(this.selectedFilters, this.descendingOrder, this.currentPageIndex, PAGE_SIZE);
    }
  }

  deleteRules(rulesIdsToDelete: string[]): void {
    this.isSpinning = true;
    this.sendSpinning();

    this.approvedRulesService
      .removeRules(rulesIdsToDelete)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.selectedCheckboxesIds.clear();
          this.refreshCheckedStatus(this.listOfCurrentPageRules, this.selectedCheckboxesIds);
          this.getData(
            this.selectedFilters,
            this.descendingOrder,
            this.currentPageIndex,
            PAGE_SIZE
          );
          if (rulesIdsToDelete.length > 1) {
            this.message.showSuccess('Rules_disabled');
          } else {
            this.message.showSuccess('Rule_disabled');
          }
        },
        error: error => {
          this.isSpinning = false;
          this.sendSpinning();
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  showExclusionModal(ruleId: string): void {
    this.modalService.confirm({
      title: 'confirm_rule_exclusion',
      content: 'rule_exclusion_explanation',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => this.deleteRules([ruleId])
    });
  }

  showMultipleExclusionModal(rulesDataToDelete: SimplifiedRule[]): void {
    this.modalService.confirm({
      title: 'confirm_multiple_rule_exclusion',
      content: 'multiple_rule_exclusion_explanation',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        rulesDataToDelete = this.listOfCurrentPageRules.filter(data =>
          this.selectedCheckboxesIds.has(data.id)
        );
        const rulesIdsToDelete = [];

        for (const rule of rulesDataToDelete) {
          rulesIdsToDelete.push(rule.id);
        }
        this.deleteRules(rulesIdsToDelete);
        this.rulesDataToDelete.emit(rulesIdsToDelete);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
