<div class="container-fluid">
  <nz-spin nzSize="large" [nzSpinning]="isSpinningForm || isSpinningTable">
    <div nz-col class="cm-page-title">
      {{ 'Registration' | translate }}
    </div>

    <div nz-col class="cm-page-subtitle">
      {{ 'Package' | translate }}
    </div>

    <nz-card>
      <app-package-form></app-package-form>
      <p class="card-text">
        {{ 'Last_packages' | translate }}
      </p>
      <app-package-grid></app-package-grid>
    </nz-card>
    <app-button class="redirect-button mt-2" type="link" (click)="navigate()">
      {{ 'See_all_packages' | translate }}
    </app-button>
  </nz-spin>
</div>
