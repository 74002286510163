export enum OrderColumnsFinancialFilePlanned {
  Product = 2,
  Media = 3,
  Network = 4,
  Vehicle = 5,
  Campaign = 8,
  PurchaseType = 9,
  Package = 10
}

export enum OrderColumnsFinancialFileExecuted {
  Product = 2,
  Campaign = 3,
  Package = 4,
  PurchaseType = 5,
  Media = 7,
  Network = 8,
  Vehicle = 13
}
