import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { VehicleService } from '@core/services/vehicle/vehicle.service';
import { VehicleCompleteModel } from '@models/vehicle';
import { VehicleComponentService } from '@pages/registrations/vehicle/vehicle-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-vehicle-data-view',
  templateUrl: './vehicle-data-view.component.html',
  styleUrls: ['./vehicle-data-view.component.scss']
})
export class VehicleDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private vehiclesData: VehicleCompleteModel[] = [];

  loading = false;

  vehicle: VehicleCompleteModel = {} as VehicleCompleteModel;

  vehicles: VehicleCompleteModel[] = [];

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private vehicleService: VehicleService,
    private message: MessageService,
    private vehicleComponentService: VehicleComponentService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.vehicles = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.vehicles = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): VehicleCompleteModel[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.vehiclesData
      : this.vehiclesData.filter(
          vehicle =>
            StringHelper.includesNormalized(vehicle.vehicleName, termNormalized) ||
            StringHelper.includesNormalized(vehicle.network.networkName, termNormalized) ||
            StringHelper.includesNormalized(vehicle.network.media.mediaName, termNormalized) ||
            StringHelper.includesNormalized(vehicle.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(vehicle.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): VehicleCompleteModel[] {
    return direction === Ordination.Last
      ? this.vehicles.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.vehicles.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.vehicleService
      .listVehicles('CreatedAt', this.ordination === Ordination.Last, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (vehicles: VehicleCompleteModel[]) => {
          this.vehiclesData = vehicles;
          this.vehicles = this.search === '' ? vehicles : this.filterRegistration(this.search);
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.vehicleComponentService.updateVehiclesGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(vehicleId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.vehicleService
        .deleteVehicle(vehicleId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Vehicle deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(vehicle: VehicleCompleteModel): void {
    this.vehicle = vehicle;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(vehicle.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
