import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MediaComponentService } from './media-component.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  isSpinningForm!: boolean;

  isSpinningTable!: boolean;

  constructor(private mediaComponentService: MediaComponentService, private router: Router) {}

  ngOnInit(): void {
    this.mediaComponentService.isFormLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((formIsLoading: boolean) => {
        this.isSpinningForm = formIsLoading;
      });

    this.mediaComponentService.isTableLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((tableIsLoading: boolean) => {
        this.isSpinningTable = tableIsLoading;
      });
  }

  navigate(): void {
    this.router.navigateByUrl('registrations/list?category=media');
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
