import { Component, Input } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "iframe-container",
    templateUrl: "./iframe-container.component.html",
    styleUrls: ["./iframe-container.component.scss"]
})
export class IframeContainer {
    @Input() public iframeUrl: SafeResourceUrl | undefined;
} 