<nz-spin nzSize="large" [nzSpinning]="isSpinning && products.length === 0">
  <div class="container-fluid">
    <app-header-calendar
      [isBackArrow]="true"
      [dateRefText]="dateRefText"
      [queryParams]="{
        active_tab: 1,
        ref_date: dateRef.toISOString().substring(0, 10)
      }"
      [fileType]="financialFile.fileType"
      [squadName]="financialFile.squad?.squadName"
    ></app-header-calendar>

    <app-filter-update
      [products]="products"
      [campaigns]="campaigns"
      [medias]="medias"
      [vehicles]="vehicles"
    ></app-filter-update>

    <nz-spin nzSize="large" [nzSpinning]="isSpinning && products.length > 0">
      <div (window:resize)="onResize($event)">
        <nz-table
          #fixedTable
          [nzSize]="'middle'"
          [nzFrontPagination]="false"
          [nzData]="executedLines"
          [nzScroll]="{ x: 'calc(3500px + 100vw)' }"
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th
                nzLeft
                nzWidth="calc(40px + 0.5vw)"
                class="ps-3"
                [nzChecked]="executedLines.length > 0 && checkboxSet.size == executedLines.length"
                (nzCheckedChange)="onCheckAllChange($event)"
              ></th>
              <th nzWidth="calc(140px + 0.5vw)" class="mx-1">
                <app-button
                  type="primary"
                  (click)="onClickBudget()"
                  [disabled]="checkboxSet.size == 0"
                >
                  <span class="material-icons-outlined md-24 me-2">add</span>
                  {{ 'Budget' | translate }}
                </app-button>
              </th>
              <th>{{ 'InsertionDate' | translate }}</th>
              <th>{{ 'AdvertisingDate' | translate }}</th>
              <th>{{ 'Product' | translate }}</th>
              <th>{{ 'Campaign' | translate }}</th>
              <th>{{ 'Media' | translate }}</th>
              <th>{{ 'Network' | translate }}</th>
              <th>{{ 'Vehicle' | translate }}</th>
              <th>{{ 'Package' | translate }}</th>
              <th>{{ 'PurchaseType' | translate }}</th>
              <th nzWidth="calc(200px + 2vw)">{{ 'NroPi' | translate }}</th>
              <th>{{ 'TableValue' | translate }}</th>
              <th>{{ 'TradeValue' | translate }}</th>
              <th>{{ 'DescAverage' | translate }}</th>
              <th>{{ 'Grp' | translate }}</th>
              <th>{{ 'Trp' | translate }}</th>
              <th>{{ 'AgencyCommission' | translate }}</th>
              <th>{{ 'HearingsCycle' | translate }}</th>
              <th>{{ 'Impacts' | translate }}</th>
              <th>{{ 'Sec' | translate }}</th>
              <th>{{ 'TotInser' | translate }}</th>
              <th>{{ 'Universe' | translate }}</th>
              <th>{{ 'Client' | translate }}</th>
              <th nzWidth="calc(160px + 2vw)">{{ 'CnpjVehicle' | translate }}</th>
              <th>{{ 'DayPart' | translate }}</th>
              <th>{{ 'Format' | translate }}</th>
              <th>{{ 'Gender' | translate }}</th>
              <th>{{ 'Market' | translate }}</th>
              <th nzWidth="calc(80px + 5vw)">{{ 'MediaType' | translate }}</th>
              <th>{{ 'MneProgram' | translate }}</th>
              <th nzWidth="calc(175px + 2vw)">{{ 'NroAp' | translate }}</th>
              <th nzWidth="calc(125px + 5vw)">{{ 'PartTitle' | translate }}</th>
              <th nzWidth="calc(200px + 8vw)">{{ 'PoControl' | translate }}</th>
              <th>{{ 'ProgJove' | translate }}</th>
              <th>{{ 'Program' | translate }}</th>
              <th>{{ 'TargetPri' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <td
                nzLeft
                class="expand-td ps-3"
                [nzChecked]="checkboxSet.has(data.lineNumber)"
                (nzCheckedChange)="onCheckboxChange(data.lineNumber, $event)"
              ></td>
              <td class="mx-1">{{ data.budget }}</td>
              <td>{{ data.insertionDate | date : 'dd/MM/yyyy' }}</td>
              <td>{{ data.advertisingDate | date : 'dd/MM/yyyy' }}</td>
              <td>{{ data.productName }}</td>
              <td>{{ data.campaignName }}</td>
              <td>{{ data.mediaName }}</td>
              <td>{{ data.networkName }}</td>
              <td>{{ data.vehicleName }}</td>
              <td>{{ data.packageName }}</td>
              <td>{{ data.purchaseTypeName }}</td>
              <td>{{ data.nroPi }}</td>
              <td>{{ data.tableValue }}</td>
              <td>{{ data.tradeValue }}</td>
              <td>{{ data.descAverage }}</td>
              <td>{{ data.grp }}</td>
              <td>{{ data.trp }}</td>
              <td>{{ data.agencyCommission }}</td>
              <td>{{ data.hearingsCycle }}</td>
              <td>{{ data.impacts }}</td>
              <td>{{ data.sec }}</td>
              <td>{{ data.totInser }}</td>
              <td>{{ data.universe }}</td>
              <td>{{ data.client }}</td>
              <td>{{ data.cnpjVehicle }}</td>
              <td>{{ data.dayPart }}</td>
              <td>{{ data.format }}</td>
              <td>{{ data.gender }}</td>
              <td>{{ data.market }}</td>
              <td>{{ data.mediaType }}</td>
              <td>{{ data.mneProgram }}</td>
              <td>{{ data.nroAp }}</td>
              <td>{{ data.partTitle }}</td>
              <td>{{ data.poControl }}</td>
              <td>{{ data.progJove }}</td>
              <td>{{ data.program }}</td>
              <td>{{ data.targetPri }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div class="d-flex flex-row justify-content-center p-4">
        <app-pagination
          [pageIndex]="currentPageIndex"
          [pageSize]="pageSize"
          [total]="totalRecords"
          [showSizeChanger]="true"
          [showTotal]="true"
          (pageIndexChange)="onPageIndexChange($event)"
          (pageSizeChange)="onPageSizeChange($event)"
        ></app-pagination>
      </div>
    </nz-spin>
  </div>

  <app-modal-update
    [isVisible]="isVisibleModalUpdate"
    (isVisibleModalChange)="isVisibleModalUpdate = $event"
    [financialFileId]="financialFileId"
    [column]="selectedColumn"
    [lines]="selectedLines"
    [validationFormFilter]="validationFormFilter"
  >
  </app-modal-update>
</nz-spin>
