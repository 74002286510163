import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { GenerateCustomConsolidatedRequest } from '@shared/models';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomConsolidatedService {
  constructor(public configService: ConfigService, private http: HttpClient) {}

  public exportReport(
    financialFileId: string,
    body: GenerateCustomConsolidatedRequest
  ): Observable<Blob | object> {
    return this.http.post(
      `${baseWebApiURL}/v1/CustomConsolidated/report?financialFileId=${financialFileId}`,
      body,
      { responseType: 'blob' }
    );
  }
}
