<form nz-form [formGroup]="validationForm" class="formMedia">
  <nz-row [nzGutter]="24">
    <nz-col class="mb-2" [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
      <nz-form-label class="p-0" nzRequired>{{ 'Media_Name' | translate }}</nz-form-label>
      <nz-form-item>
        <nz-form-control nzHasFeedback [nzErrorTip]="nameError">
          <input nz-input formControlName="mediaName" required />
          <ng-template #nameError let-control>
            <ng-container *ngIf="control.hasError('required')">{{
              'Required_field' | translate
            }}</ng-container>
            <ng-container *ngIf="control.hasError('invalid')">{{
              'Invalid_characters' | translate
            }}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <div class="d-flex justify-content-end my-2">
    <app-button class="me-2" type="link" (click)="cleanForm()">
      {{ 'Clean_form' | translate }}
    </app-button>
    <app-button type="primary" [disabled]="!validationForm?.valid" (click)="save()">
      {{ 'Save' | translate }}
    </app-button>
  </div>
</form>
