<div class="d-flex align-items-center mb-3">
  <app-button
    *ngIf="isBackArrow"
    type="link"
    routerLink="/files"
    [queryParams]="queryParams"
    class="p-0 align-self-center btn-back me-2"
  >
    <span class="px-2 material-icons-outlined md-24">chevron_left</span>
  </app-button>
  <div class="d-flex flex-grow-1 justify-content-between align-items-center">
    <div class="d-flex">
      <div *ngIf="fileTypeText" class="d-inline-flex fw-bold text-capitalize me-1">
        {{ fileTypeText | translate }}
      </div>
      <div *ngIf="dateRefText" class="d-inline-flex fw-bold">{{ dateRefText }}</div>
    </div>
    <div *ngIf="squadName" class="d-flex squad-title">Squad: {{ squadName }}</div>
  </div>
</div>
