import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseWebApiURL } from '@env';
import { GroupModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constants = CONSTANTS;

  constructor(private http: HttpClient) {}

  public getAll(): Observable<GroupModel[]> {
    const route = `${baseWebApiURL}/v1/Group`;

    return this.http.get<GroupModel[]>(route);
  }
}
