import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupService } from '@core/services/group/group.service';
import { MessageService } from '@core/services/message/message.service';
import { SquadService } from '@core/services/squad/squad.service';
import { UserService } from '@core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupModel, SquadModel, UserCreateUpdateModel } from '@shared/models';
import { CONSTANTS } from '@shared/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { UserComponentService } from '../user.component.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  @Input() groups: GroupModel[] = [];

  @Input() squads: SquadModel[] = [];

  private readonly destroy$ = new Subject<void>();

  constants = CONSTANTS;

  validationForm!: FormGroup;

  isSpinningVerify = false;

  isSpinningAssociate = false;

  isLoadingData = false;

  isShowGroups = false;

  constructor(
    private formBuilder: FormBuilder,
    private message: MessageService,
    public translate: TranslateService,
    public userService: UserService,
    public groupService: GroupService,
    public squadService: SquadService,
    private userComponentService: UserComponentService
  ) {}

  onVerify(): void {
    this.isSpinningVerify = true;
    const email = this.validationForm.controls['email'].value;

    this.userService
      .getByEmailOkta(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isShowGroups = true;
          this.isSpinningVerify = false;
        },
        error: error => {
          this.isSpinningVerify = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  onAssociate(): void {
    this.isSpinningAssociate = true;
    const email = this.validationForm.controls['email'].value;
    const groupId = this.validationForm.controls['groupId'].value;
    const squadId = this.validationForm.controls['squadId'].value;

    const user: UserCreateUpdateModel = {
      email: email,
      groupId: groupId,
      squadId: squadId
    };

    this.userService
      .create(user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.cleanForm();
          this.isSpinningAssociate = false;
          this.userComponentService.updateGrid.next(true);
          this.message.showSuccess('User associated successfully!');
        },
        error: error => {
          this.isSpinningAssociate = false;
          this.userComponentService.updateGrid.next(true);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  cleanForm(): void {
    this.validationForm.controls['email'].reset();
    this.validationForm.controls['groupId'].reset();
    this.validationForm.controls['squadId'].reset();
  }

  ngChangeEmail(): void {
    this.isShowGroups = false;
    this.validationForm.controls['groupId'].reset();
    this.validationForm.controls['squadId'].reset();
  }

  ngChangeGroup(groupId: string): void {
    this.validationForm.controls['squadId'].reset();

    const squadGroup = this.groups.find(x => x.name == CONSTANTS.GROUP_SQUAD);

    if (groupId == squadGroup?.id) {
      this.validationForm.controls['squadId'].setValidators([Validators.required]);
      this.validationForm.controls['squadId'].updateValueAndValidity();
    } else {
      this.validationForm.controls['squadId'].clearValidators();
      this.validationForm.controls['squadId'].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      groupId: ['', [Validators.required]],
      squadId: ['']
    });
  }
}
