import { Component, ViewChild } from '@angular/core';
import { RulesGridComponent } from './rules-grid/rules-grid.component';
import { SimplifiedRule } from '@models/fromToRules';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent {
  isSpinning!: boolean;

  updateTable!: boolean;

  public isVisibleModalFromTo = false;

  rulesIdsToDelete!: SimplifiedRule[];

  multipleExclusionModal!: void;

  isButtonEnabled = false;

  @ViewChild(RulesGridComponent) rulesGridComponent!: RulesGridComponent;

  showFromToModal(): void {
    this.isVisibleModalFromTo = true;
  }

  shouldUpdateTable(update: boolean): void {
    this.updateTable = update;
  }

  getSpinningValue(data: boolean): void {
    this.isSpinning = data;
  }

  showMultipleExclusionModal(data: SimplifiedRule[]): void {
    this.multipleExclusionModal = this.rulesGridComponent.showMultipleExclusionModal(data);
  }

  checkButtonEnabled(data: boolean): boolean {
    return (this.isButtonEnabled = data);
  }
}
