import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MediaService } from '@core/services/media/media.service';
import { MessageService } from '@core/services/message/message.service';
import { NetworkService } from '@core/services/network/network.service';
import { MediaModel } from '@models/media';
import { NetworkModel } from '@models/network';
import { TranslateService } from '@ngx-translate/core';
import { CustomStringValidators } from '@shared/validators/custom-string.validators';
import { Subject, takeUntil } from 'rxjs';
import { NetworkComponentService } from '../network-component.service';

@Component({
  selector: 'app-network-form',
  templateUrl: './network-form.component.html',
  styleUrls: ['./network-form.component.scss']
})
export class NetworkFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  medias: MediaModel[] = [];

  networks: NetworkModel[] = [];

  disabledSaveBtn = true;

  hasMedia = true;

  isSpinning = false;

  autoModalTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
    private message: MessageService,
    private networkService: NetworkService,
    private networkComponentService: NetworkComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.validationForm = this.formBuilder.group({
      media: [null, [Validators.required]],
      networkName: ['', [Validators.required, CustomStringValidators.validateString]]
    });
  }

  getData(): void {
    this.networkComponentService.isFormLoading.next(true);
    this.mediaService
      .getAllMedias()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: medias => {
          this.medias = medias;
          this.networkComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.networkComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  cleanForm(): void {
    this.validationForm.controls['media'].setValue('');
    this.validationForm.controls['media'].reset();
    this.validationForm.controls['networkName'].setValue('');
    this.validationForm.controls['networkName'].reset();
  }

  save(): void {
    this.networkComponentService.isFormLoading.next(true);

    const mediaId = this.validationForm.get('media')?.value;
    const networkName = this.validationForm.get('networkName')?.value;

    this.networkService
      .saveNetwork(mediaId, networkName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.validationForm.controls['networkName'].reset();

          this.getData();

          this.networkComponentService.updateNetworkGrid.next(true);

          this.networkComponentService.isFormLoading.next(false);

          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.networkComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
