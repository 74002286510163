import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PackageComponentService } from './package-component.service';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  isSpinningForm!: boolean;

  isSpinningTable!: boolean;

  constructor(private packageComponentService: PackageComponentService, private router: Router) {}

  ngOnInit(): void {
    this.packageComponentService.isFormLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((formIsLoading: boolean) => {
        this.isSpinningForm = formIsLoading;
      });

    this.packageComponentService.isTableLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((tableIsLoading: boolean) => {
        this.isSpinningTable = tableIsLoading;
      });
  }

  navigate(): void {
    this.router.navigateByUrl('registrations/list?category=package');
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
