import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from '@core/services/message/message.service';
import { ModalService } from '@core/services/modal/modal.service';
import { ProductService } from '@core/services/product/product.service';
import { ProductModel } from '@models/product';
import { ProductComponentService } from '@pages/registrations/product/product-component.service';
import { Ordination } from '@shared/enums';
import { DateHelper, StringHelper } from '@shared/utils/utils';
import { Subject, takeUntil } from 'rxjs';

interface ProductListDto {
  id: string;
  productName: string;
  squads: string;
  createdBy: string;
  createdAt: Date;
}

@Component({
  selector: 'app-product-data-view',
  templateUrl: './product-data-view.component.html',
  styleUrls: ['./product-data-view.component.scss']
})
export class ProductDataViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private productsData: ProductListDto[] = [];

  loading = false;

  products: ProductListDto[] = [];

  product: ProductListDto = {} as ProductListDto;

  isLoadingDelete = false;

  @Input() search = '';

  @Input() ordination: Ordination = Ordination.Last;

  constructor(
    private productService: ProductService,
    private message: MessageService,
    private productComponentService: ProductComponentService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { search, ordination } = changes;

    if (search && search.previousValue !== search.currentValue) {
      const filtered = this.filterRegistration(search.currentValue);

      this.products = filtered;
    }

    if (ordination && ordination.previousValue !== ordination.currentValue) {
      this.products = this.sortRegistration(ordination.currentValue);
    }
  }

  filterRegistration(term: string): ProductListDto[] {
    const termNormalized = StringHelper.toNormalize(term);

    return termNormalized === ''
      ? this.productsData
      : this.productsData.filter(
          product =>
            StringHelper.includesNormalized(product.productName, termNormalized) ||
            StringHelper.includesNormalized(product.squads, termNormalized) ||
            StringHelper.includesNormalized(product.createdBy, termNormalized) ||
            StringHelper.includesNormalized(
              DateHelper.toDateString(product.createdAt),
              termNormalized
            )
        );
  }

  sortRegistration(direction: Ordination): ProductListDto[] {
    return direction === Ordination.Last
      ? this.products.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : this.products.sort(
          (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
  }

  getData(): void {
    this.loading = true;

    this.productService
      .listProducts('CreatedAt', true, 0)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (products: ProductModel[]) => {
          const productListDto: ProductListDto[] = products.map<ProductListDto>(product => ({
            id: product.id,
            productName: product.productName,
            squads: product.productSquad
              .map(productSquad => productSquad.squad.squadName)
              .join(', '),
            createdAt: product.createdAt,
            createdBy: product.createdBy
          }));

          this.products = productListDto;
          this.productsData = productListDto;
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateTable(): void {
    this.productComponentService.updateProductGrid
      .pipe(takeUntil(this._destroying$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  onDelete(productId: string): Promise<void> {
    this.isLoadingDelete = true;

    return new Promise((resolve, reject) => {
      this.productService
        .deleteProduct(productId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: () => {
            this.isLoadingDelete = false;
            this.message.showSuccess('Product deleted successfully');
            this.getData();
            resolve();
          },
          error: error => {
            this.isLoadingDelete = false;
            this.message.showErrorByStatus(error.status);
            reject(false);
          }
        });
    });
  }

  handleDelete(product: ProductListDto): void {
    this.product = product;
    this.modalService.confirm({
      title: 'Are you sure delete?',
      onOk: async () => {
        try {
          await this.onDelete(product.id);
          return true;
        } catch (err) {
          return false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
